import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { 
    AutocompleteField, NumericTextField, SearchComponent, CheckboxField, TextFieldComponent, DateField
} from './../../../components/SharedComponents/Fields';
import { 
    SectionHeader
} from './../../../components/SharedComponents/GeneralComponents';
import { 
    fetchInstitution, fetchInterests, fetchFilterInterest
 } from './../../../utils/axios';
import { 
    affiliationPurpose, paymentMethodsLeads, PLANNED_SAVINGS_PERIODICITY, 
    paymentFrequency, settlementDisbursement, economicActivity, primaryIncomeMonthlyActivity, 
    secondaryIncomeMonthlyActivity, approximateAmountOfTransactions, settlementWays, 
    bankAccountTypes, liquidablePaymentTypes, economicSectors
} from '../../../utils/index';
import QuotationSelectInput from '../../QuotationFiles/QuotationSelectInput';
import { errorHandling } from './../../../components/SharedComponents/CustomHooks';

export default function FinancialInfoForm({
    rFields, fields, onChange, errorFields, options, 
    section, getFiltersOptions, handleSelectValue, token,
    setLoading, handleSimpleDialog, institution, disabled
}){
    const [maxShare, setMaxShare] = useState([]);
    const [disabledFields, setDisabledFields] = useState({});
    const [institutionInfo, setInstitutionInfo] = useState(null);
    const [dueDiligence, setDueDiligence] = useState(false);
    const contributionAmount = useRef(null);
    const maxNumber = ({ value }) => {
        if(maxShare.includes(Number(value)) || value === '' || null) return true;
        return false; 
    }
    // // this is to ge the object to put it on 
    // const getCurrentInstitution = async () => {
    //     let data = fields.institution || null;
    //     if(fields.institutionUniqueid) {
    //         data = await fetchInstitution(token, fields.institutionUniqueid).then(response =>  response.data);
    //     }
    //     if(data && typeof data === 'object') {
    //         const info = {
    //             fullName: `${data.name} | ${data.employerCode}`,
    //             name: data.name,
    //             employerCode: data.employerCode,
    //             paymentFrequency: paymentFrequency.filter(pf => data.paymentFrequency.includes(pf.value)),
    //             paymentDays: data.paymentDays || [],
    //             value: data.uniqueId,
    //         }
    //         setInstitutionInfo({...info});
    //     }
    // }
    const getContributionAmount = async () => {
        if(typeof contributionAmount.current !== 'number' && !contributionAmount.current) {
            const data = await fetchInterests({token, calculatorType: "contributions"}).then(response =>  response.data?.results || response.data);
            contributionAmount.current = data[0]?.contributionAmount;
        }
        const contrVal = (Number(fields.inputAmount) || 0) * contributionAmount.current;
        onChange('any', 'contributionInitialDeposit', contrVal);
    }

    const getCertificateRate = () => {
        if (fields.certificateAmount > 5000 && fields.certificateTerm > 1) {
            const selectedSettlement = fields.settlementWays?.value || fields.settlementWays;
            const data = {
                token,
                calculator_type: "financial_certificates",
                amount: fields.certificateAmount,
                time_limit: fields.certificateTerm,
            }
            const endpoint = fetchFilterInterest(data);
            setLoading(true);
            endpoint.then(response => {
                const rateRes = response.data?.results || response.data;
                const rate = selectedSettlement !== "capitalizable" ? rateRes.otherWayInterestRate : rateRes.capitalizedInterestRate;
                onChange('any', 'certificateRate', rate);
                setLoading(false);
            }).catch(errors => {
                setLoading(false);
                errorHandling(errors, handleSimpleDialog);
            });
        }
    }
    useEffect(() => {
        let max = 0;
        if(fields.typeOfInstallments?.value === 'biweekly' || fields.typeOfInstallments === 'biweekly') {
            max = [2,10];
        }
        if(fields.typeOfInstallments?.value === 'monthly' || fields.typeOfInstallments === 'monthly') {
            max = [1,5];
        }
        const number = fields.numberOfInstallments > max ? null : fields.numberOfInstallments;
        onChange('check', 'numberOfInstallments', null, number);
        setMaxShare(max);
    }, [fields.typeOfInstallments]);

    useEffect(() => {
        // getCurrentInstitution();
        if(!fields.institution) {
            onChange('array', 'paymentFrequency', null);
            // onChange('array', 'paymentDay', []);
        }
    }, [fields.institution])

    useEffect(() => {
        onChange('any', 'savingsAccount', '');
        onChange('any', 'transferType', null);
    }, [fields.settlementWays])

    useEffect(() => {
        const wayToPay = fields.wayToPay?.value || fields.wayToPay;
        let disabled = {};
        if(wayToPay) {
            switch(wayToPay) {
                case 'office':
                case 'transfer_or_deposit':
                    disabled = {
                        typeOfInstallments: true,
                        numberOfInstallments: true,
                    };
                    Object.keys(disabled).forEach(key =>  onChange('any', key, null));
                    break;
                case 'payroll_deduction':
                    disabled = {
                        typeOfInstallments: false,
                        numberOfInstallments: false,
                    }
                    break;
                default:
                    break;
            }
            setDisabledFields({...disabledFields, ...disabled});
        }
    }, [fields.wayToPay]);

    useEffect(() => {
        getContributionAmount();
    }, [fields.inputAmount]);

    useEffect(() => {
        const saving = ( ( Number(fields.initialDeposit) || 0) + ( Number(fields.contributionInitialDeposit) || 0));
        onChange('any', 'initialAccountSaving', saving);
    }, [fields.initialDeposit, fields.contributionInitialDeposit]);

    useEffect(() => {
        getCertificateRate();
    }, [
        fields.certificateAmount, 
        fields.certificateTerm, 
        fields.settlementWays
    ]);

    return(
        <Grid container spacing={3} className="form-wrapper-fields-container">

            <SectionHeader 
                title={"Propósito inicial de afiliación"}
            />
            <AutocompleteField
                xs={6}
                fieldLabel= {`Propósito de afiliación ${rFields.includes("accountPurpose") ? "(*)" : ""}`}
                fieldID="leads-field-accountPurpose"
                fieldInnerLabel="Seleccione un propósito"
                value={
                    handleSelectValue(fields.accountPurpose, affiliationPurpose, 'value') || []
                }
                handleChange={onChange.bind(this, 'autocomplete', 'accountPurpose')}
                options={getFiltersOptions(affiliationPurpose)?.filter(ap => ap.value !== "financial_certificate")}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.accountPurpose)}
                helperText={errorFields[section]?.accountPurpose ? errorFields[section]?.accountPurpose : null}
                multiple={true}
                limitTags={1}
                disabled={disabled}
            />
            {/* <NumericTextField
                xs={6}
                fullWidth
                fieldLabel= {`Monto ahorro ${rFields.includes("amountToSave") ? "(*)" : ""}`}
                fieldID="leads-field-amountToSave"
                placeholder="Ej: $50,000"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={fields.amountToSave || ''}
                onChange={onChange.bind(this, 'numeric', 'amountToSave')}
                error={Boolean(errorFields[section]?.amountToSave)}
                helperText={errorFields[section]?.amountToSave ? errorFields[section]?.amountToSave : null}
            /> */}
            <SectionHeader 
                title={"Configuración de depósitos iniciales"}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Forma de pago ${rFields.includes("wayToPay") ? "(*)" : ""}`}
                fieldID="leads-field-wayToPay"
                fieldInnerLabel="Seleccione una forma de pago"
                value={
                    handleSelectValue(fields.wayToPay, paymentMethodsLeads, 'value') || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'wayToPay')}
                options={getFiltersOptions(paymentMethodsLeads)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.wayToPay)}
                helperText={errorFields[section]?.wayToPay ? errorFields[section]?.wayToPay : null}
                multiple={false}
                disabled={disabled}
            />
            {/* <SearchComponent 
                xs={4}
                containerClass="field-container"
                fieldLabel="Empresa"
                fieldID="leads-field-enterprises"
                fieldInnerLabel="Seleccione una empresa"
                handleChange={
                    e => onChange('array', 'institution', e)
                }
                savedValue={institutionInfo || null}
                optionType={"institutions"}
                limitTags={1}
            /> */}

            {/* PAYMENT FREQUENCY AND PREFERRED DAYS SELECTION */}
            {/* <AutocompleteField
                xs={4}
                fieldLabel= {`Frecuencia de pago ${rFields.includes("paymentFrequency") ? "(*)" : ""}`}
                fieldID="leads-field-paymentFrequency"
                fieldInnerLabel="Seleccione una frecuencia"
                value={
                    // fields.paymentFrequency || null
                    handleSelectValue(fields.paymentFrequency, paymentFrequency, 'value') || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'paymentFrequency')}
                options={getFiltersOptions(
                    paymentFrequency.filter(pf => fields.paymentFrecuency?.includes(pf.value)) || paymentFrequency
                )}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.paymentFrequency)}
                helperText={errorFields[section]?.paymentFrequency ? errorFields[section]?.paymentFrequency : null}
                multiple={false}
                disabled={!fields.institution}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Días de pago preferenciales ${rFields.includes("paymentDay") ? "(*)" : ""}`}
                fieldID="leads-field-paymentDay"
                fieldInnerLabel="Seleccione los días"
                value={
                    fields.paymentDay || []
                    // handleSelectValue(fields.paymentDay, paymentDay, 'value') || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'paymentDay')}
                options={getFiltersOptions(fields.paymentDays || [])}
                getOptionLabel={(option) => option.toString()}
                // getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.paymentDay)}
                helperText={errorFields[section]?.paymentDay ? errorFields[section]?.paymentDay : null}
                multiple={true}
                disabled={!fields.institution}
            /> */}

            <AutocompleteField
                xs={4}
                fieldLabel= {`Frecuencia de pago cuotas ${rFields.includes("typeOfInstallments") ? "(*)" : ""}`}
                fieldID="leads-field-typeOfInstallments"
                fieldInnerLabel="Seleccione una opción"
                value={
                    // fields.typeOfInstallments || null
                    handleSelectValue(fields.typeOfInstallments, PLANNED_SAVINGS_PERIODICITY, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'typeOfInstallments')}
                options={getFiltersOptions(PLANNED_SAVINGS_PERIODICITY)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.typeOfInstallments)}
                disabled={disabledFields.typeOfInstallments || disabled}
                helperText={errorFields[section]?.typeOfInstallments ? errorFields[section]?.typeOfInstallments : null}
                multiple={false}
            />
            <NumericTextField
                xs={4}
                fullWidth
                fieldLabel= {`Número de cuotas ${rFields.includes("numberOfInstallments") ? "(*)" : ""}`}
                fieldID="leads-field-numberOfInstallments"
                placeholder={
                    (fields.typeOfInstallments === "monthly" || fields.typeOfInstallments?.value === "monthly") ? 
                        "1 o 5" 
                    : 
                        "2 o 10"
                }
                value={fields.numberOfInstallments || null}
                decimalScale={0}
                onChange={onChange.bind(this, 'numeric', 'numberOfInstallments')}
                isAllowed={maxNumber}
                disabled={
                    !fields.typeOfInstallments || disabledFields.numberOfInstallments || disabled
                }
                error={Boolean(errorFields[section]?.numberOfInstallments)}
                helperText={errorFields[section]?.numberOfInstallments ? errorFields[section]?.numberOfInstallments : null}
            />
            <Grid container item direction='row' style={{padding: '1rem'}}>
                <Typography
                    variant="body1"
                    className="section-header"
                    style={{fontWeight: 'bold', color:'#383838', fontSize:'.9rem'}}
                >
                    {"CONFIGURACIÓN DE AHORRO A LA VISTA"}
                </Typography>
                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Depósito inicial ${rFields.includes("initialDeposit") ? "(*)" : ""}`}
                        fieldID="leads-field-initialDeposit"
                        placeholder="Ej: 1,500.00"
                        value={fields.initialDeposit || ''}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        // isAllowed={({ value }) => value >= 100}
                        onChange={onChange.bind(this, 'numeric', 'initialDeposit')}
                        error={Boolean(errorFields[section]?.initialDeposit)}
                        helperText={errorFields[section]?.initialDeposit ? errorFields[section]?.initialDeposit : null}
                        disabled={disabled}
                    />

                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Cuota de ahorro ${rFields.includes("amountToSave") ? "(*)" : ""}`}
                        fieldID="leads-field-sightSavingFee"
                        placeholder="Ej: 3,200.50"
                        value={fields.amountToSave || ''}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        onChange={onChange.bind(this, 'numeric', 'amountToSave')}
                        error={Boolean(errorFields[section]?.amountToSave)}
                        disabled={disabled}
                        helperText={errorFields[section]?.amountToSave ? errorFields[section]?.amountToSave : null}
                    />
                </Grid>
            </Grid>

            <Grid container item direction='row' style={{padding: '1rem'}}>
                <Typography
                    variant="body1"
                    className="section-header"
                    style={{fontWeight: 'bold', color:'#383838', fontSize:'.9rem'}}
                >
                    {"CONFIGURACIÓN DE APORTACIONES"}
                </Typography>
                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Cuotas de Participación ${rFields.includes("inputAmount") ? "(*)" : ""}`}
                        fieldID="leads-field-inputAmount"
                        placeholder="Ej: 6"
                        value={fields.inputAmount || ''}
                        decimalScale={0}
                        onChange={onChange.bind(this, 'numeric', 'inputAmount')}
                        error={Boolean(errorFields[section]?.inputAmount)}
                        helperText={errorFields[section]?.inputAmount ? errorFields[section]?.inputAmount : null}
                        disabled={disabled}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Depósito inicial ${rFields.includes("contributionInitialDeposit") ? "(*)" : ""}`}
                        fieldID="leads-field-contributionInitialDeposit"
                        placeholder="0.00"
                        value={fields.contributionInitialDeposit || ''}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        disabled={true}
                    />
                </Grid>
            </Grid>

            <Grid container item direction='row' style={{padding: '1rem'}}>
                <Typography
                    variant="body1"
                    className="section-header"
                    style={{fontWeight: 'bold', color:'#383838', fontSize:'.9rem'}}
                >
                    {"CUOTA INICIAL DE AHORRO"}
                </Typography>
                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Ahorro inicial ${rFields.includes("initialAccountSaving") ? "(*)" : ""}`}
                        fieldID="leads-field-initialAccountSaving"
                        placeholder="0.00"
                        value={fields.initialAccountSaving || ''}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        disabled={true}
                    />
                </Grid>
            </Grid>
                
            {/* <SectionHeader 
                title={"Configuración de solicitud de productos"}
            /> */}
            {/* <Grid container item direction='row' style={{padding: '1rem'}}>
                <Typography
                    variant="body1"
                    className="section-header"
                    style={{fontWeight: 'bold', color:'#383838', fontSize:'.9rem'}}
                >
                    {"COTIZACIÓN"}
                </Typography>
                <QuotationSelectInput
                    // client={props.ticket.customer}
                    selectedQuotationsId={Array.isArray(fields.quotations) ? fields.quotations : fields.quotations ? [fields.quotations] : []}
                    addQuotation={id => onChange('any', 'quotations', id) }
                    removeQuotation={() => onChange('any', 'quotations', null)}
                />
            </Grid> */}
            {/* <NumericTextField
                xs={4}
                fullWidth
                fieldLabel= {`Cantidad Dependientes ${rFields.includes("dependentQuantity") ? "(*)" : ""}`}
                placeholder="Ej: 3"
                fieldID="leads-field-dependentQuantity"
                decimalScale={0}
                value={fields.dependentQuantity || ''}
                onChange={onChange.bind(this, 'numeric', 'dependentQuantity')}
                error={Boolean(errorFields[section]?.dependentQuantity)}
                helperText={errorFields[section]?.dependentQuantity ? errorFields[section]?.dependentQuantity : null}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Tipo de vivienda ${rFields.includes("houseInfo") ? "(*)" : ""}`}
                fieldID="leads-field-houseInfo"
                fieldInnerLabel="Seleccione tipo de vivienda"
                value={
                    // fields.houseInfo || null
                    handleSelectValue(fields.houseInfo, dwellingTypes, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'houseInfo')}
                options={getFiltersOptions(dwellingTypes)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.houseInfo)}
                helperText={errorFields[section]?.houseInfo ? errorFields[section]?.houseInfo : null}
                multiple={false}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Nivel de edudación ${rFields.includes("educationLevel") ? "(*)" : ""}`}
                fieldID="leads-field-educationLevel"
                fieldInnerLabel="Seleccione un nivel"
                value={
                    // fields.educationLevel || null
                    handleSelectValue(fields.educationLevel, educationLevel, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'educationLevel')}
                options={getFiltersOptions(educationLevel)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.educationLevel)}
                helperText={errorFields[section]?.educationLevel ? errorFields[section]?.educationLevel : null}
                multiple={false}
            /> */}
            {/* <AutocompleteField
                xs={4}
                fieldLabel= {`Formas liquidación desembolsos ${rFields.includes("disbursementWays") ? "(*)" : ""}`}
                fieldID="leads-field-disbursementWays"
                fieldInnerLabel="Seleccione una forma"
                value={
                    // fields.disbursementWays || null
                    handleSelectValue(fields.disbursementWays, settlementDisbursement, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'disbursementWays')}
                options={getFiltersOptions(settlementDisbursement)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.disbursementWays)}
                helperText={errorFields[section]?.disbursementWays ? errorFields[section]?.disbursementWays : null}
                multiple={false}
            /> */}
            {/* <CheckboxField
                xs={8}
                label= {`LA SOLICITUD ES PARA CONSOLIDAR DEUDAS ${rFields.includes("consolidateDebt") ? "(*)" : ""}`}
                alignItems="flex-end"
                check={fields.consolidateDebt || false}
                handleChange={onChange.bind(this, 'check', 'consolidateDebt')}
                color="primary"
                textColor="#43A047"
            /> */}


            {/* <Grid container item direction='row' style={{padding: '1rem'}}>
                <Typography
                    variant="body1"
                    className="section-header"
                    style={{fontWeight: 'bold', color:'#383838', fontSize:'.9rem'}}
                >
                    {"¿EL SOCIO CONTRATARÁ UNA CUENTA O PLAN DE AHORRO?"}
                </Typography>
                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <SectionHeader 
                        title={"FUNCIONAL"}
                        variant={"body2"}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Cuota de ahorro ${rFields.includes("savingFee") ? "(*)" : ""}`}
                        placeholder="Ej: 10,000.00"
                        fieldID="leads-field-savingFee"
                        // decimalScale={0}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={fields.savingFee || ''}
                        onChange={onChange.bind(this, 'numeric', 'savingFee')}
                        error={Boolean(errorFields[section]?.savingFee)}
                        helperText={errorFields[section]?.savingFee ? errorFields[section]?.savingFee : null}
                    />
                </Grid>

                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <SectionHeader 
                        title={"INFANTIL"}
                        variant={"body2"}
                    />
                    <TextFieldComponent
                        xs={6}
                        fullWidth
                        fieldLabel= {`Nombre del niño ${rFields.includes("childName") ? "(*)" : ""}`}
                        fieldID="leads-field-childName"
                        placeholder="Ej: Santiago Perez"
                        value={fields.childName || ''}
                        onChange={onChange.bind(this, 'text', 'childName')}
                        error={Boolean(errorFields[section]?.childName)}
                        helperText={errorFields[section]?.childName ? errorFields[section]?.childName : null}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Edad del niño ${rFields.includes("childAge") ? "(*)" : ""}`}
                        placeholder="Ej: 10"
                        fieldID="leads-field-childAge"
                        decimalScale={0}
                        value={fields.childAge || ''}
                        onChange={onChange.bind(this, 'numeric', 'childAge')}
                        error={Boolean(errorFields[section]?.childAge)}
                        helperText={errorFields[section]?.childAge ? errorFields[section]?.childAge : null}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Número de teléfono ${rFields.includes("childPhoneNumber") ? "(*)" : ""}`}
                        placeholder="Ej: (809)000-0001"
                        fieldID="leads-field-childPhoneNumber"
                        decimalScale={0}
                        format={"+1(###) ###-####"}
                        value={fields.childPhoneNumber || ''}
                        onChange={e => onChange('any', 'childPhoneNumber', e.formattedValue)}
                        error={Boolean(errorFields[section]?.childPhoneNumber)}
                        helperText={errorFields[section]?.childPhoneNumber ? errorFields[section]?.childPhoneNumber : null}
                    />
                    <TextFieldComponent 
                        xs={6}
                        fullWidth
                        fieldLabel= {`Correo electrónico personal ${rFields.includes("childEmail") ? "(*)" : ""}`}
                        fieldID="leads-field-childEmail"
                        placeholder="Ej: dumbledore@gmail.com"
                        value={fields.childEmail || ''}
                        onChange={onChange.bind(this, 'text', 'childEmail')}
                        error={Boolean(errorFields[section]?.childEmail)}
                        helperText={errorFields[section]?.childEmail ? errorFields[section]?.childEmail : null}
                    />
                </Grid>

                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <SectionHeader 
                        title={"AHORRO PLANIFICADO"}
                        variant={"body2"}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Cuota ${rFields.includes("plannedSavingFee") ? "(*)" : ""}`}
                        placeholder="Ej: 7,000.00"
                        fieldID="leads-field-plannedSavingFee"
                        // decimalScale={0}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={fields.plannedSavingFee || ''}
                        onChange={onChange.bind(this, 'numeric', 'plannedSavingFee')}
                        error={Boolean(errorFields[section]?.plannedSavingFee)}
                        helperText={errorFields[section]?.plannedSavingFee ? errorFields[section]?.plannedSavingFee : null}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Meta ${rFields.includes("plannedSavingGoal") ? "(*)" : ""}`}
                        placeholder="Ej: 84,000.00"
                        fieldID="leads-field-plannedSavingGoal"
                        // decimalScale={0}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={fields.plannedSavingGoal || ''}
                        onChange={onChange.bind(this, 'numeric', 'plannedSavingGoal')}
                        error={Boolean(errorFields[section]?.plannedSavingGoal)}
                        helperText={errorFields[section]?.plannedSavingGoal ? errorFields[section]?.plannedSavingGoal : null}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Plazo en meses ${rFields.includes("plannedSavingMonthlyTerm") ? "(*)" : ""}`}
                        placeholder="Ej: 5"
                        fieldID="leads-field-plannedSavingMonthlyTerm"
                        decimalScale={0}
                        value={fields.plannedSavingMonthlyTerm || ''}
                        onChange={onChange.bind(this, 'numeric', 'plannedSavingMonthlyTerm')}
                        error={Boolean(errorFields[section]?.plannedSavingMonthlyTerm)}
                        helperText={errorFields[section]?.plannedSavingMonthlyTerm ? errorFields[section]?.plannedSavingMonthlyTerm : null}
                    />
                    <AutocompleteField
                        xs={6}
                        fieldLabel= {`Periodicidad ${rFields.includes("plannedSavingPeriodicity") ? "(*)" : ""}`}
                        fieldID="leads-field-plannedSavingPeriodicity"
                        fieldInnerLabel="Seleccione una opción"
                        value={
                            // fields.plannedSavingPeriodicity || null
                            handleSelectValue(fields.plannedSavingPeriodicity, PLANNED_SAVINGS_PERIODICITY, 'value')
                            || null
                        }
                        handleChange={onChange.bind(this, 'autocomplete', 'plannedSavingPeriodicity')}
                        options={getFiltersOptions(PLANNED_SAVINGS_PERIODICITY)}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        error={Boolean(errorFields[section]?.plannedSavingPeriodicity)}
                        helperText={errorFields[section]?.plannedSavingPeriodicity ? errorFields[section]?.plannedSavingPeriodicity : null}
                        multiple={false}
                    />
                </Grid>
                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <SectionHeader 
                        title={"COOPSAN"}
                        variant={"body2"}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Cuota ${rFields.includes("coopsanFee") ? "(*)" : ""}`}
                        placeholder="Ej: 7,000.00"
                        fieldID="leads-field-coopsanFee"
                        // decimalScale={0}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={fields.coopsanFee || ''}
                        onChange={onChange.bind(this, 'numeric', 'coopsanFee')}
                        error={Boolean(errorFields[section]?.coopsanFee)}
                        helperText={errorFields[section]?.coopsanFee ? errorFields[section]?.coopsanFee : null}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Meta ${rFields.includes("coopsanGoal") ? "(*)" : ""}`}
                        placeholder="Ej: 84,000.00"
                        fieldID="leads-field-coopsanGoal"
                        // decimalScale={0}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={fields.coopsanGoal || ''}
                        onChange={onChange.bind(this, 'numeric', 'coopsanGoal')}
                        error={Boolean(errorFields[section]?.coopsanGoal)}
                        helperText={errorFields[section]?.coopsanGoal ? errorFields[section]?.coopsanGoal : null}
                    />
                    <NumericTextField
                        xs={6}
                        fullWidth
                        fieldLabel= {`Plazo en meses ${rFields.includes("coopsanMonthlyTerm") ? "(*)" : ""}`}
                        placeholder="Ej: 5"
                        fieldID="leads-field-coopsanMonthlyTerm"
                        decimalScale={0}
                        value={fields.coopsanMonthlyTerm || ''}
                        onChange={onChange.bind(this, 'numeric', 'coopsanMonthlyTerm')}
                        error={Boolean(errorFields[section]?.coopsanMonthlyTerm)}
                        helperText={errorFields[section]?.coopsanMonthlyTerm ? errorFields[section]?.coopsanMonthlyTerm : null}
                    />
                    <AutocompleteField
                        xs={6}
                        fieldLabel= {`Periodicidad ${rFields.includes("coopsanPeriodicity") ? "(*)" : ""}`}
                        fieldID="leads-field-coopsanPeriodicity"
                        fieldInnerLabel="Seleccione una opción"
                        value={
                            // fields.coopsanPeriodicity || null
                            handleSelectValue(fields.coopsanPeriodicity, PLANNED_SAVINGS_PERIODICITY, 'value')
                            || null
                        }
                        handleChange={onChange.bind(this, 'autocomplete', 'coopsanPeriodicity')}
                        options={getFiltersOptions(PLANNED_SAVINGS_PERIODICITY)}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        error={Boolean(errorFields[section]?.coopsanPeriodicity)}
                        helperText={errorFields[section]?.coopsanPeriodicity ? errorFields[section]?.coopsanPeriodicity : null}
                        multiple={false}
                    />
                </Grid>
            </Grid> */}

            {
                institution?.state !== "affiliate" &&
                <>
                    <CheckboxField
                        xs={12}
                        label= {`¿Desea realizar una Debida Diligencia Simple?`}
                        alignItems="flex-end"
                        check={dueDiligence || false}
                        handleChange={() => setDueDiligence(ps => !ps)}
                        color="primary"
                        textColor="#43A047"
                        disabled={disabled}
                    />
                    {
                        dueDiligence &&
                        <Grid container item direction='row' style={{padding: '1rem'}}>
                            <Typography
                                variant="body1"
                                className="section-header"
                                style={{fontWeight: 'bold', color:'#383838', fontSize:'.9rem'}}
                            >
                                {"PREGUNTAS DE CUMPLIMIENTO"}
                            </Typography>

                            <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                                <SectionHeader 
                                    title={"Segunda nacionalidad"}
                                    variant={"body2"}
                                />
                                <CheckboxField
                                    xs={12}
                                    label= {`Tiene otra nacionalidad ${rFields.includes("hasDebtorAnotherCitizenship") ? "(*)" : ""}`}
                                    alignItems="flex-end"
                                    check={fields.hasDebtorAnotherCitizenship || false}
                                    handleChange={onChange.bind(this, 'check', 'hasDebtorAnotherCitizenship')}
                                    color="primary"
                                    textColor="#43A047"
                                    disabled={disabled}
                                />
                                {
                                    fields.hasDebtorAnotherCitizenship &&
                                    <>
                                        <TextFieldComponent
                                            xs={6}
                                            fullWidth
                                            fieldLabel= {`Segunda Nacionalidad ${rFields.includes("secondCitizenshipCountry") ? "(*)" : ""}`}
                                            fieldID="leads-field-secondCitizenshipCountry"
                                            placeholder="Ej: Venezolano"
                                            value={fields.secondCitizenshipCountry || ''}
                                            onChange={onChange.bind(this, 'text', 'secondCitizenshipCountry')}
                                            error={Boolean(errorFields[section]?.secondCitizenshipCountry)}
                                            helperText={errorFields[section]?.secondCitizenshipCountry ? errorFields[section]?.secondCitizenshipCountry : null}
                                            disabled={disabled}
                                        />
                                        <TextFieldComponent
                                            xs={6}
                                            fullWidth
                                            fieldLabel= {`ID Segunda Nacionalidad ${rFields.includes("secondCitizenshipIdentificationNumber") ? "(*)" : ""}`}
                                            fieldID="leads-field-secondCitizenshipIdentificationNumber"
                                            placeholder="Ej: RT0939019394"
                                            value={fields.secondCitizenshipIdentificationNumber || ''}
                                            onChange={onChange.bind(this, 'text', 'secondCitizenshipIdentificationNumber')}
                                            error={Boolean(errorFields[section]?.secondCitizenshipIdentificationNumber)}
                                            helperText={errorFields[section]?.secondCitizenshipIdentificationNumber ? errorFields[section]?.secondCitizenshipIdentificationNumber : null}
                                            disabled={disabled}
                                        />
                                        {/* <Divider style={{margin:'1rem 0'}}/> */}
                                    </>
                                }
                                <SectionHeader 
                                    title={"Actividades económicas"}
                                    variant={"body2"}
                                />
                                <AutocompleteField
                                    xs={6}
                                    fieldLabel= {`Actividad Económica ${rFields.includes("economicActivityInfo") ? "(*)" : ""}`}
                                    fieldID="leads-field-economicActivityInfo"
                                    fieldInnerLabel="Seleccione una actividad"
                                    value={
                                        // fields.economicActivityInfo || null
                                        handleSelectValue(fields.economicActivityInfo, economicActivity, 'value')
                                        || null
                                    }
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    handleChange={onChange.bind(this, 'autocomplete', 'economicActivityInfo')}
                                    options={getFiltersOptions(economicActivity)}
                                    error={Boolean(errorFields[section]?.economicActivityInfo)}
                                    helperText={errorFields[section]?.economicActivityInfo ? errorFields[section]?.economicActivityInfo : null}
                                    multiple={false}
                                    disabled={disabled}
                                />
                                <AutocompleteField
                                    xs={6}
                                    fieldLabel= {`Ingresos Actividad Económica ${rFields.includes("primaryIncomeMonthlyActivity") ? "(*)" : ""}`}
                                    fieldID="leads-field-primaryIncomeMonthlyActivity"
                                    fieldInnerLabel="Seleccione una opción"
                                    value={
                                        // fields.primaryIncomeMonthlyActivity || null
                                        handleSelectValue(fields.primaryIncomeMonthlyActivity, primaryIncomeMonthlyActivity, 'value')
                                        || null
                                    }
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    handleChange={onChange.bind(this, 'autocomplete', 'primaryIncomeMonthlyActivity')}
                                    options={getFiltersOptions(primaryIncomeMonthlyActivity)}
                                    error={Boolean(errorFields[section]?.primaryIncomeMonthlyActivity)}
                                    helperText={errorFields[section]?.primaryIncomeMonthlyActivity ? errorFields[section]?.primaryIncomeMonthlyActivity : null}
                                    multiple={false}
                                    disabled={disabled}
                                />

                                {/* {
                                    fields.secondaryIncome && <Divider style={{margin:'1rem 0'}}/>
                                } */}

                                <CheckboxField
                                    xs={12}
                                    label= {`Tiene ingreso secundario ${rFields.includes("secondaryIncome") ? "(*)" : ""}`}
                                    alignItems="flex-end"
                                    check={fields.secondaryIncome || false}
                                    handleChange={onChange.bind(this, 'check', 'secondaryIncome')}
                                    color="primary"
                                    textColor="#43A047"
                                    disabled={disabled}
                                />

                                {
                                    fields.secondaryIncome &&
                                    <>
                                        <AutocompleteField
                                            xs={6}
                                            fieldLabel= {`Actividad Económica Secundaria ${rFields.includes("secondaryIncomeActivity") ? "(*)" : ""}`}
                                            fieldID="leads-field-secondaryIncomeActivity"
                                            fieldInnerLabel="Seleccione una actividad"
                                            value={
                                                // fields.secondaryIncomeActivity || null
                                                handleSelectValue(fields.secondaryIncomeActivity, economicSectors, 'value')
                                                || null
                                            }
                                            handleChange={onChange.bind(this, 'autocomplete', 'secondaryIncomeActivity')}
                                            options={getFiltersOptions(economicSectors)}
                                            getOptionLabel={(option) => option.value}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            error={Boolean(errorFields[section]?.secondaryIncomeActivity)}
                                            helperText={errorFields[section]?.secondaryIncomeActivity ? errorFields[section]?.secondaryIncomeActivity : null}
                                            multiple={false}
                                            disabled={disabled}
                                        />
                                        <AutocompleteField
                                            xs={6}
                                            fieldLabel= {`Ingresos Actividad Económica Secundaria ${rFields.includes("secondaryIncomeMonthlyActivity") ? "(*)" : ""}`}
                                            fieldID="leads-field-secondaryIncomeMonthlyActivity"
                                            fieldInnerLabel="Seleccione una opción"
                                            value={
                                                // fields.secondaryIncomeMonthlyActivity || null
                                                handleSelectValue(fields.secondaryIncomeMonthlyActivity, secondaryIncomeMonthlyActivity, 'value')
                                                || null
                                            }
                                            handleChange={onChange.bind(this, 'autocomplete', 'secondaryIncomeMonthlyActivity')}
                                            options={getFiltersOptions(secondaryIncomeMonthlyActivity)}
                                            getOptionLabel={(option) => option.label}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            error={Boolean(errorFields[section]?.secondaryIncomeMonthlyActivity)}
                                            helperText={errorFields[section]?.secondaryIncomeMonthlyActivity ? errorFields[section]?.secondaryIncomeMonthlyActivity : null}
                                            multiple={false}
                                            disabled={disabled}
                                        />
                                        {/* <Divider style={{margin:'1rem 0'}}/> */}
                                    </>
                                }
                                <SectionHeader 
                                    title={"Información general de afiliación"}
                                    variant={"body2"}
                                />
                                <AutocompleteField
                                    xs={12}
                                    fieldLabel= {`Monto Transacciones Mensuales que realizará en la Cooperativa ${rFields.includes("approximateAmountOfTransactionsOnTheCooperative") ? "(*)" : ""}`}
                                    fieldID="leads-field-approximateAmountOfTransactionsOnTheCooperative"
                                    fieldInnerLabel="Seleccione una opción"
                                    value={
                                        // fields.approximateAmountOfTransactionsOnTheCooperative || null
                                        handleSelectValue(fields.approximateAmountOfTransactionsOnTheCooperative, approximateAmountOfTransactions, 'value')
                                        || null
                                    }
                                    handleChange={onChange.bind(this, 'autocomplete', 'approximateAmountOfTransactionsOnTheCooperative')}
                                    options={getFiltersOptions(approximateAmountOfTransactions)}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    error={Boolean(errorFields[section]?.approximateAmountOfTransactionsOnTheCooperative)}
                                    helperText={errorFields[section]?.approximateAmountOfTransactionsOnTheCooperative ? errorFields[section]?.approximateAmountOfTransactionsOnTheCooperative : null}
                                    multiple={false}
                                    disabled={disabled}
                                />

                                <CheckboxField
                                    xs={12}
                                    label= {
                                        `¿Es usted, o alguien relacionado, a una persona políticamente expuesta y/o figura pública? 
                                        ${rFields.includes("hasDebtorPossessedPublicPower") ? "(*)" : ""}`
                                    }
                                    alignItems="flex-end"
                                    check={fields.hasDebtorPossessedPublicPower || false}
                                    handleChange={onChange.bind(this, 'check', 'hasDebtorPossessedPublicPower')}
                                    color="primary"
                                    textColor="#43A047"
                                    disabled={disabled}
                                />
                                {
                                    fields.hasDebtorPossessedPublicPower &&
                                    <>
                                        <TextFieldComponent 
                                            xs={6}
                                            fullWidth
                                            fieldLabel={`Cargo, rango o posición ${rFields.includes("pepMemberPosition") ? "(*)" : ""}`}
                                            fieldID="pep-rank-position"
                                            placeholder="Ej: Almirante de Flota"
                                            value={fields.pepMemberPosition || ''}
                                            onChange={onChange.bind(this, 'text', 'pepMemberPosition')}
                                            error={Boolean(errorFields[section]?.pepMemberPosition)}
                                            helperText={errorFields[section]?.pepMemberPosition ? errorFields[section]?.pepMemberPosition : null}
                                            disabled={disabled}
                                        />
                                        <DateField 
                                            xs={6}
                                            fullWidth
                                            fieldLabel={`Fecha de ocupación cargo, rango o posición ${rFields.includes("pepMemberEntryDate") ? "(*)" : ""}`}
                                            dateFieldID="pep-rank-date"
                                            dateFieldLabel={`Ej: 20/09/${new Date().getFullYear()-10}`}
                                            value={fields.pepMemberEntryDate || null}
                                            handleChange={onChange.bind(this, 'date', 'pepMemberEntryDate')}
                                            error={Boolean(errorFields[section]?.pepMemberEntryDate)}
                                            helperText={errorFields[section]?.pepMemberEntryDate ? errorFields[section]?.pepMemberEntryDate : null}
                                            disabled={disabled}
                                        />
                                        <TextFieldComponent 
                                            xs={6}
                                            fullWidth
                                            fieldLabel={`Institución a la que pertenece ${rFields.includes("pepMemberInstitution") ? "(*)" : ""}`}
                                            fieldID="pep-rank-institution"
                                            placeholder="Ej: Marina"
                                            value={fields.pepMemberInstitution || ''}
                                            onChange={onChange.bind(this, 'text', 'pepMemberInstitution')}
                                            error={Boolean(errorFields[section]?.pepMemberInstitution)}
                                            helperText={errorFields[section]?.pepMemberInstitution ? errorFields[section]?.pepMemberInstitution : null}
                                            disabled={disabled}
                                        />
                                    </>
                                }
                                <CheckboxField
                                    xs={12}
                                    label= {
                                        `¿Recibirá el socio mas de $15,000 en la cuenta?
                                        ${rFields.includes("willDebtorReceive15kOrMoreInTheAccount") ? "(*)" : ""}`
                                    }
                                    alignItems="flex-end"
                                    check={fields.willDebtorReceive15kOrMoreInTheAccount || false}
                                    handleChange={onChange.bind(this, 'check', 'willDebtorReceive15kOrMoreInTheAccount')}
                                    color="primary"
                                    textColor="#43A047"
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>
                    }
                </>
            }

            {/* <Grid container item direction='row' style={{padding: '1rem'}}>
                <Typography
                    variant="body1"
                    className="section-header"
                    style={{fontWeight: 'bold', color:'#383838', fontSize:'.9rem'}}
                >
                    {"CERTIFICADO FINANCIERO"}
                </Typography>
                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>

                    <AutocompleteField
                        xs={3}
                        fieldLabel= {`Formas de liquidación ${rFields.includes("settlementWays") ? "(*)" : ""}`}
                        fieldID="leads-field-settlementWays"
                        fieldInnerLabel="Seleccione..."
                        value={
                            // fields.settlementWays || null
                            handleSelectValue(fields.settlementWays, settlementWays, 'value')
                            || null
                        }
                        handleChange={onChange.bind(this, 'autocomplete', 'settlementWays')}
                        options={getFiltersOptions(settlementWays)}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        error={Boolean(errorFields[section]?.settlementWays)}
                        helperText={errorFields[section]?.settlementWays ? errorFields[section]?.settlementWays : null}
                        multiple={false}
                    />
                    <NumericTextField
                        xs={3}
                        fullWidth
                        fieldLabel= {`Monto de apertura ${rFields.includes("certificateAmount") ? "(*)" : ""}`}
                        fieldID="leads-field-certificateAmount"
                        placeholder="Ej: $50,000"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={fields.certificateAmount || ''}
                        onChange={onChange.bind(this, 'numeric', 'certificateAmount')}
                        error={Boolean(errorFields[section]?.certificateAmount)}
                        helperText={errorFields[section]?.certificateAmount ? errorFields[section]?.certificateAmount : null}
                    />
                    <NumericTextField
                        xs={3}
                        fullWidth
                        fieldLabel= {`Plazo ${rFields.includes("certificateTerm") ? "(*)" : ""}`}
                        placeholder="Ej: 5"
                        fieldID="leads-field-certificateTerm"
                        decimalScale={0}
                        value={fields.certificateTerm || ''}
                        onChange={onChange.bind(this, 'numeric', 'certificateTerm')}
                        error={Boolean(errorFields[section]?.certificateTerm)}
                        helperText={errorFields[section]?.certificateTerm ? errorFields[section]?.certificateTerm : null}
                    />
                    <NumericTextField
                        xs={3}
                        fullWidth
                        fieldLabel= {`Tasa ${rFields.includes("certificateRate") ? "(*)" : ""}`}
                        placeholder="Ej: 2%"
                        fieldID="leads-field-certificateRate"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix={"%"}
                        value={fields.certificateRate || ''}
                        onChange={onChange.bind(this, 'numeric', 'certificateRate')}
                        error={Boolean(errorFields[section]?.certificateRate)}
                        helperText={errorFields[section]?.certificateRate ? errorFields[section]?.certificateRate : null}
                    />
                    {
                        (fields.settlementWays?.value === 'withdrawable' || fields.settlementWays === 'withdrawable') ?
                        <AutocompleteField
                            xs={6}
                            fieldLabel= {`Forma de transferencia ${rFields.includes("transferType") ? "(*)" : ""}`}
                            fieldID="leads-field-transferType"
                            fieldInnerLabel="Seleccione..."
                            value={
                                // fields.transferType || null
                                handleSelectValue(fields.transferType, liquidablePaymentTypes, 'value')
                                || null
                            }
                            handleChange={onChange.bind(this, 'autocomplete', 'transferType')}
                            options={getFiltersOptions(liquidablePaymentTypes)}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            error={Boolean(errorFields[section]?.transferType)}
                            helperText={errorFields[section]?.transferType ? errorFields[section]?.transferType : null}
                            multiple={false}
                        />
                        :
                        (fields.settlementWays?.value === 'liquidable' || fields.settlementWays === 'liquidable') ?
                            <NumericTextField
                                xs={6}
                                fullWidth
                                fieldLabel= {`Cuenta de ahorro ${rFields.includes("savingsAccount") ? "(*)" : ""}`}
                                placeholder="Ej: 0930003900"
                                fieldID="leads-field-savingsAccount"
                                decimalScale={0}
                                value={fields.savingsAccount || ''}
                                onChange={onChange.bind(this, 'numeric', 'savingsAccount')}
                                error={Boolean(errorFields[section]?.savingsAccount)}
                                helperText={errorFields[section]?.savingsAccount ? errorFields[section]?.savingsAccount : null}
                            />
                        : null
                    }
                </Grid>
            </Grid> */}

            {/* <SectionHeader 
                title={"cuentas bancarias"}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Institución Financiera ${rFields.includes("financialInstitution") ? "(*)" : ""}`}
                fieldID="leads-field-financialInstitution"
                fieldInnerLabel="Seleccione una institución"
                value={
                    // fields.financialInstitution || null
                    handleSelectValue(
                        fields.financialInstitution || (fields.bankaccount ? fields.bankaccount[0]?.bank : ''), 
                        options.institutions, 'name'
                    )
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'financialInstitution')}
                options={getFiltersOptions(options.institutions)}
                getOptionLabel={(option) => option.fullName}
                getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                error={Boolean(errorFields[section]?.financialInstitution)}
                helperText={errorFields[section]?.financialInstitution ? errorFields[section]?.financialInstitution : null}
                multiple={false}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Tipo de Cuenta ${rFields.includes("accountType") ? "(*)" : ""}`}
                fieldID="leads-field-accountType"
                fieldInnerLabel="Seleccione tipo de cuenta"
                value={
                    // fields.accountType || null
                    handleSelectValue(fields.accountType || (fields.bankaccount ? fields.bankaccount[0]?.accountType : ''), bankAccountTypes, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'accountType')}
                options={getFiltersOptions(bankAccountTypes)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.accountType)}
                helperText={errorFields[section]?.accountType ? errorFields[section]?.accountType : null}
                multiple={false}
            />
            <NumericTextField
                xs={4}
                fullWidth
                fieldLabel= {`Número Cuenta Bancaria ${rFields.includes("bankAccount") ? "(*)" : ""}`}
                placeholder="Ej: 9300349887"
                fieldID="leads-field-bankAccount"
                decimalScale={0}
                value={fields.bankAccount || (fields.bankaccount ? fields.bankaccount[0]?.number : '') }
                onChange={onChange.bind(this, 'numeric', 'bankAccount')}
                error={Boolean(errorFields[section]?.bankAccount)}
                helperText={errorFields[section]?.bankAccount ? errorFields[section]?.bankAccount : null}
            /> */}
            
        </Grid>
    )
}