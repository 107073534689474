
import * as React from 'react';
import { connect } from 'react-redux';

import { createStyles, withStyles } from '@material-ui/core/styles';

import AccountingRecordSection from '../AccountingRecords/AccountingRecordSection';
import AcquaintanceshipCompanyContract from '../AcquaintanceshipCompanyContract/Section';
import AcquaintanceshipPersonContract from '../AcquaintanceshipPersonContract/Section';
import AddendumContractSection from '../AddendumContract/AddendumContractSection';
import AttorneySection from '../Attorney/AttorneySection';
import BankAccountSection from '../BankAccount/BankAccountSection';
import BankReconciliation from '../BankReconciliation/View';
import ChartOfAccounts from '../ChartOfAccounts/ChartOfAccountsSection';
import BlackListSection from '../BlackList/BlackListSection';
import CheckSection from '../Check/CheckSection';
import ClientSection from '../Client/ClientSection';
import CompanyRepresentativeSection from '../CompanyRepresentative/CompanyRepresentativeSection';
import CreditCardSection from '../CreditCard/CreditCardSection';
import CreditorSection from '../Creditor/CreditorSection';
// import DebitAuthorizationContractSection from '../DebitAuthorizationContract/DebitAuthorizationContractSection';
import AutomaticDebitAuthorizationContractSection from '../AutomaticDebitAuthorizationContract/AutomaticDebitAuthorizationContractSection';
import CreditReviewAuthorization from '../CreditReviewAuthorization/CreditReviewAuthorizationSection';
import DepositBankAccountSection from '../DepositBankAccount/DepositBankAccountSection';
import ExpiryView from '../Expiry/View';
import FinancialInstitutionSection from '../FinancialInstitution/FinancialInstitutionSection';
import FlierSecetion from '../Flier/FlierSection';
import InternationalBlackListSection from '../InternationalBlackList/InternationalBlackListSection';
import LoanContractSection from '../LoanContract/LoanContractSection';
import LeadsView from '../Leads/View';
import NationalitySection from '../Nationality/NationalitySection';
import NotarialPayContractSection from '../NotarialPayContract/NotarialPayContractSection';
import OutlayCashSection from '../OutlayCash/OutlayCashSection';
import OutlayCheckSection from '../OutlayCheck/OutlayCheckSection';
import OutlaySection from '../Outlay/OutlaySection';
import PepsListSection from '../PepsList/PepsListSection';
import OFACAndONUListSection from '../OFACAndONUList/OFACAndONUListSection';
import PaymentView from '../Pay/View';
import PayReceiptView from '../PayReceipt/View';
import PrivateInstitutionSection from '../PrivateInstitution/PrivateInstitutionSection';
import ProfessionsSection from '../Profession/ProfessionSection';
import ProviderSection from '../Provider/ProviderSection';
import PublicInstitutionSection from '../PublicInstitution/PublicInstitutionSection';
import QuotationSection from '../QuotationFiles/QuotationSection';
// import TicketsSection from '../TicketsDocs/TicketsSection';
import AcquaintanceshipProviderContract from '../AcquaintanceshipProviderContract/Section';
// import MembershipApplicationSection from '../MembershipApplication/Section';
import MembershipApplicationSection from '../MembershipAdmission/Section';
import SavingsAccountSection from '../SavingsAccount/Section';
import DebitCardOrPassbookAccountContractSection from '../DebitCardOrPassbookAccountContract/Section';
import CancellationMembershipSection from '../CancellationMembership/Section';
import InputCertificationSection from '../InputCertification/Section';
import FinancialCertificatesSection from '../FinancialCertificates/Section';
import WarrantySection from '../Warranty/WarrantySection';
import InterestSection from '../Interest/InterestSection';
import PayrollDeductionSection from '../PayrollDeduction/Section';
import AffidavitOfFundsSection from '../AffidavitOfFunds/Section';
import ClientAffidavitOfFundsSection from '../ClientAffidavitOfFunds/Section'
import ComplianceRegistrationFormSection from '../ComplianceRegistrationForm/Section'
// import ExpirationSection from '../Expiration/ExpirationSection/index';
import PledgeContractWithoutDivestment from '../PledgeContractWithoutDivestment/Section'
import AutomaticWithdrawalAuthorizationContract from '../AutomaticWithdrawalAuthorizationContract/Section'
import ActSection from '../Act/ActSection';
import ApprovementAdmissionView from '../ApprovementAdmission/View'
import ApprovementLoanView from '../ApprovementLoan/View'
import Calculators from '../../components/Calculators';
// RECLAMACIONES
import ClaimView from '../Claim/View';
import AccountStatus from '../../containers/AccountStatus';
import ElectronicMeansServiceContract from '../ElectronicMeansServiceContract/Section';
import BusinessAgentView from '../BusinessRepresentatives/View';
//REPORTS
import DelayReports from '../Reports/DelayReports/delayreport';
import DiscountReports from '../DiscountReports/view';
import TransactionReports from '../Reports/TransactionsReports/View'
import InsuranceReport from '../Reports/InsuranceReport/View';


const styles: any = createStyles({
    root: {
        bottom: 0,
        boxSizing: 'border-box',
        height: '100vh',
        margin: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingLeft: 250,
        position: 'static',
        top: 0,
    }
});

interface IMainSectionProps {
    classes: {
        root: string
    }
    selectedSection: number
}

const sections: JSX.Element[] = [
    // Documents
    <div key={0}><p style={{ display: 'none' }}>none</p></div>,
    <LoanContractSection key={1} />,
    <NotarialPayContractSection key={2} />,

    <div key={3}><p style={{ display: 'none' }}>none</p></div>,
    <AcquaintanceshipPersonContract key={4} />,
    <AcquaintanceshipCompanyContract key={5} />,
    <AcquaintanceshipProviderContract key={6} />,
    <MembershipApplicationSection key={7} />,

    <AddendumContractSection key={8} />,
    <AutomaticDebitAuthorizationContractSection key={9}/>,
    <PayrollDeductionSection key={10} />,
    <Calculators key={11} />,
    <QuotationSection key={12} />,
    <CreditReviewAuthorization key={13} />,
    <CancellationMembershipSection key={14} />,

    <div key={15}><p style={{ display: 'none' }}>none</p></div>,
    <DebitCardOrPassbookAccountContractSection key={16} />,
    <SavingsAccountSection key={17} />,
    <FinancialCertificatesSection key={18} />,
    <InputCertificationSection key={19} />,

    // Participants
    <div key={20}><p style={{ display: 'none' }}>none</p></div>,
    <AttorneySection key={21} />,
    <div key={22}><p style={{ display: 'none' }}>none</p></div>,
    <CompanyRepresentativeSection key={23} />,
    <ClientSection key={24} />,
    <CreditorSection key={25} />,
    <ProviderSection key={26} />,

    // Institutions
    <div key={27}><p style={{ display: 'none' }}>none</p></div>,
    <FinancialInstitutionSection key={28} />,
    <PrivateInstitutionSection key={29} />,
    <PublicInstitutionSection key={30} />,

    // Bank Accounts
    <div key={31}><p style={{ display: 'none' }}>none</p></div>,
    <BankAccountSection key={32} />,
    <DepositBankAccountSection key={33} />,

    // Transactions
    <div key={34}><p style={{ display: 'none' }}>none</p></div>,
    <div key={35}><p style={{ display: 'none' }}>none</p></div>,
    <AccountingRecordSection key={36} />,
    <ChartOfAccounts key={37} />,
    <BankReconciliation key={38}/>,

    <div key={39}><p style={{ display: 'none' }}>none</p></div>,
    <OutlayCheckSection key={40} />,
    <OutlayCashSection key={41} />,

    <div key={42}><p style={{ display: 'none' }}>none</p></div>,
    <OutlaySection key={43} />,
    <ExpiryView key={44}/>,
    <PaymentView key={45}/>,

    // Settings
    <div key={46}><p style={{ display: 'none' }}>none</p></div>,
    <NationalitySection key={47} />,
    <CreditCardSection key={48} />,
    <FlierSecetion key={49} />,
    <CheckSection key={50} />,

    <div key={51}><p style={{ display: 'none' }}>none</p></div>,
    <BlackListSection key={52} />,
    <InternationalBlackListSection key={53}/>,
    <PepsListSection key={54}/>,
    <OFACAndONUListSection key={55}/>,

    <ProfessionsSection key={56} />,
    <WarrantySection key={57} />,
    <InterestSection key={58} />,

    <div key={59}><p style={{ display: 'none' }}>none</p></div>,
    <AffidavitOfFundsSection key={60} />,
    <ClientAffidavitOfFundsSection key={61} />,
    <ComplianceRegistrationFormSection key={62} />,
    <PledgeContractWithoutDivestment key={63} />,
    <AutomaticWithdrawalAuthorizationContract key={64} />,

    <div key={65}><p style={{ display: 'none' }}>none</p></div>,
    <ActSection key={66} />,
    <ApprovementAdmissionView key={67}/>,
    <ApprovementLoanView key={68}/>,
    <PayReceiptView key={69} />,

    // RECLAMACIONES
    <div key={70}><p style={{ display: 'none' }}>none</p></div>,
    <ClaimView key={71} />,
    
    <AccountStatus key={72} />,
    // LEADS
    <div key={73}><p style={{ display: 'none' }}>none</p></div>,
    <LeadsView key={74} />,
    <ElectronicMeansServiceContract key={75} />,
    <BusinessAgentView key={76} />,

    //REPORTS
    <div key={77}><p style={{display: 'none'}}>none</p></div>,
    <DelayReports key={78}/>,
    <DiscountReports key={79}/>,
    <TransactionReports key={80}/>,
    <InsuranceReport key={81}/>
];

function MainSection(props: IMainSectionProps) {
    const { classes, selectedSection } = props;
    return (
        <div className={classes.root}>
            {sections[selectedSection]===undefined?sections.find(m => m.key === selectedSection):sections[selectedSection]}
        </div>
    );
}

function mapStateTopProps(state: IState) {
    return {
        selectedSection: state.selectedSection
    }
}

export default connect(mapStateTopProps) (
    withStyles(styles)(MainSection)
);