import React, { useEffect, useState, useMemo } from "react";
import { validateRequiredFields } from "./../../../components/SharedComponents/CustomHooks";
import { dateFormat } from "./../../../components/SharedComponents/CustomHooks";
import { CheckCircle, RemoveCircle, Work } from "@material-ui/icons";
import { FormWrapper } from "./../../../components/SharedComponents/GeneralComponents";

export default function Layout(props) {
  const [fields, setFields] = useState({ ...props.initialData });
  const [errorFields, setErrorFields] = useState({});
  const [error, setError] = useState(false);
  // const [completedSection, setCompletedSection] = useState(false);

  const valRequiredFields = validateRequiredFields.bind(
    this,
    fields,
    props.rFields,
    errorFields,
    setErrorFields,
    setError
  );

  const handleClickExpand = (form, event, isExpanded) => {
    props.setExpand(isExpanded ? form : false);
  };

  const handleSelectValue = (value, options, property) => {
    // handle logic of multi select
    let data = options.results ? options.results : options;
    let result = value;
    if (typeof result !== "object") {
      result = data.find((option) => option[property] === value);
    }
    if (Array.isArray(value)) {
      const checkIfContainsObject = value.find(
        (item) => typeof item === "object"
      );
      if (!checkIfContainsObject) {
        result = data.filter((option) => value.includes(option[property]));
      }
    }
    return result;
  };

  const handleChange = (type, field, event, value) => {
    let newVal;
    switch (type) {
      case "date":
        newVal = event ? dateFormat(event) : "";
        break;
      case "text":
        newVal = event.target.value;
        break;
      case "autocomplete":
      case "check":
        newVal = value;
        break;
      case "file":
      case "array":
      case "any":
        newVal = event;
        break;
      default:
        newVal = event.target ? event.target.value : event.value;
        break;
    }
    valRequiredFields("single", field, newVal);
    setFields((prevState) => {
      const result = { ...prevState, [field]: newVal };
      props.onChange({ [field]: newVal });
      return result;
    });
  };

  const checkSectionStatus = () => {
    let fieldsStatus = {};
    props.rFields.forEach((rf) => {
      fieldsStatus[rf] = Boolean(fields[rf]);
    });
    return fieldsStatus;
  };
  // testing the memoization
  const completedSection = useMemo(() => checkSectionStatus(), [fields]);

  const findIfHidden = (keys) => {
    let result = true;
    if (fields.accountPurpose && fields.accountPurpose.length) {
      result = !Boolean(
        fields.accountPurpose.find((p) => keys.includes(p.value || p))
      );
    }
    return Boolean(result);
  };

  const section = () => {
    return React.cloneElement(props.children, {
      ...props,
      onChange: handleChange,
      fields,
      errorFields,
      setFields,
      handleSelectValue,
    });
  };

  useEffect(() => {
    if (props.initialData) {
      setFields((prevState) => ({ ...prevState, ...props.initialData }));
    }
  }, [props.initialData]);

  useEffect(() => {
    if (Object.keys(props.errorFields).length) {
      setErrorFields({ ...props.errorFields });
    }
  }, [props.errorFields]);

  useEffect(() => {
    if (props.section === "financialInfo") {
      const hidden = {
        paymentInstructions: findIfHidden([
          "financing",
          "financial_certificate",
        ]),
        financialSituation: findIfHidden(["financing"]),
        fulfillment: findIfHidden(["financial_certificate"]),
      };
      props.setHiddenSections({ ...hidden });
    }
  }, [fields.accountPurpose]);

  useEffect(() => {
    if (props.sectionErrors) {
      setErrorFields({ ...props.sectionErrors });
    }
  }, [props.sectionErrors]);

  return props.noWrapper ? (
    section()
  ) : (
    <FormWrapper
      title={props.title}
      name={props.name}
      contentType={"Formulario"}
      headerAction={
        Object.keys(completedSection).find((k) => !completedSection[k]) ? (
          <RemoveCircle style={{ fontSize: 20, color: "#f5a556" }} />
        ) : (
          <CheckCircle style={{ fontSize: 20, color: "#44c94d" }} />
        )
      }
      toolbarClass="accordion-summary-toolbar-leads"
      form={section()}
      handleExpand={handleClickExpand}
      // expand={props.disabled ? true : props.expand === props.section}
      expand={props.expand === props.section}
      errorStyles={
        props.sectionErrors && props.sectionErrors[props.section]
          ? { border: "2px solid #fc6060", borderRadius: 4 }
          : {}
      }
      // disabled={props.disabled}
    />
  );
}
