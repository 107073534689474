import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Fab from "@material-ui/core/Fab";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { fetchCompanyRepresentatives } from "../../../actions";
import DefaultSection from "../../../components/DefaultSection";
import Loading from "../../../components/Loading";
import { fetchParticipants } from "../../../utils/axios";
import CompanyRepresentativeForm from "../CompanyRepresentativeForm";
import CompanyRepresentativeSearchSection from "../CompanyRepresentativeSearchSection";
import CompanyRepresentativeView from "../CompanyRepresentativeView";

const styles: any = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    marginLeft: 280,
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

interface ICompanyRepresentativeSectionProps {
  classes: {
    addButton: string;
    rightSide: string;
    root: string;
  };

  companyRepresentatives: IParticipant[];
  user: IUser;
  fetchCompanyRepresentatives(
    companyRepresentatives: IParticipant[]
  ): IAction<IParticipant[]>;
}
interface ICompanyRepresentativeSectionState {
  loading: boolean;
  selectedCompanyRepresentativeId: string | undefined;
  selectedSection: number;
}
class CompanyRepresentativeSection extends React.Component<
  ICompanyRepresentativeSectionProps,
  ICompanyRepresentativeSectionState
> {
  public state: ICompanyRepresentativeSectionState = {
    loading: false,
    selectedCompanyRepresentativeId: undefined,
    selectedSection: 0,
  };
  constructor(props: ICompanyRepresentativeSectionProps) {
    super(props);

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchParticipants({
      token: this.props.user.token,
      limit: 20,
      offset: 0,
      participantType: "company representative",
    })
      .then((res) => {
        this.props.fetchCompanyRepresentatives(
          (res.data.results ? res.data.results : res.data) as IParticipant[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChangeSection(sectionNumber: number) {
    this.setState({ selectedSection: sectionNumber });
  }
  public handleClickItem(companyRepresentativeId: string) {
    this.setState(
      {
        selectedCompanyRepresentativeId: companyRepresentativeId,
        selectedSection: 1,
      },
      () => {
        this.setState({ selectedSection: 2 });
      }
    );
  }
  public handleAddClick() {
    this.setState({
      selectedSection: 3,
    });
  }
  public fetchData(params: IFetchParticipantParams) {
    this.setState({ loading: true });
    fetchParticipants(params)
      .then((res) => {
        this.props.fetchCompanyRepresentatives(
          (res.data.results ? res.data.results : res.data) as IParticipant[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const {
      loading,
      selectedCompanyRepresentativeId,
      selectedSection,
    } = this.state;
    return (
      <div className={classes.root}>
        <CompanyRepresentativeSearchSection
          loading={loading}
          handleClick={this.handleClickItem}
          fetchData={this.fetchData}
          pagination={true}
        />
        <div className={classes.rightSide}>
          {selectedSection === 0 ? (
            <DefaultSection
              message={
                translations.COMPANY_REPRESENTATIVE_DEFAULT_SECTION_MESSAGE
              }
              key={0}
            />
          ) : null}
          {selectedSection === 1 ? (
            <Loading message={translations.LOADING} key={1} />
          ) : null}
          {selectedSection === 2 ? (
            <CompanyRepresentativeView
              changeSection={this.handleChangeSection}
              companyRepresentativeId={
                selectedCompanyRepresentativeId
                  ? selectedCompanyRepresentativeId
                  : ""
              }
            />
          ) : null}
          {selectedSection === 3 ? (
            <CompanyRepresentativeForm
              changeSection={this.handleChangeSection}
            />
          ) : null}
          {selectedSection !== 3 ? (
            <Fab className={classes.addButton} onClick={this.handleAddClick}>
              <AddIcon />
            </Fab>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    companyRepresentatives: state.companyRepresentatives,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchCompanyRepresentatives })(
    CompanyRepresentativeSection
  )
);
