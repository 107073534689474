import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Fab from "@material-ui/core/Fab";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { fetchAttorneys } from "../../../actions";
import DefaultSection from "../../../components/DefaultSection";
import Loading from "../../../components/Loading";
import { fetchParticipants } from "../../../utils/axios";
import AttorneyForm from "../AttorneyForm";
import AttorneySearchSection from "../AttorneySearchSection";
import AttorneyView from "../AttorneyView";

const styles: any = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    marginLeft: 280,
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

interface IAttorneySectionProps {
  classes: {
    addButton: string;
    rightSide: string;
    root: string;
  };
  attorneys: IParticipant[];
  user: IUser;
  fetchAttorneys(attorneys: IParticipant[]): IAction<IParticipant[]>;
}
interface IAttorneySectionState {
  selectedAttorneyId: string | undefined;
  selectedSection: number;
  loading: boolean;
}

class AttorneySection extends React.Component<
  IAttorneySectionProps,
  IAttorneySectionState
> {
  public state: IAttorneySectionState = {
    loading: false,
    selectedAttorneyId: undefined,
    selectedSection: 0,
  };
  constructor(props: IAttorneySectionProps) {
    super(props);

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchParticipants({
      token: this.props.user.token,
      limit: 20,
      offset: 0,
      participantType: "attorney",
    })
      .then((res) => {
        this.props.fetchAttorneys(
          res.data.results ? res.data.results : (res.data as IParticipant[])
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChangeSection(sectionNumber: number) {
    this.setState({ selectedSection: sectionNumber });
  }
  public handleClickItem(AttorneyId: string) {
    this.setState(
      {
        selectedAttorneyId: AttorneyId,
        selectedSection: 1,
      },
      () => {
        this.setState({ selectedSection: 2 });
      }
    );
  }
  public handleAddClick() {
    this.setState({
      selectedSection: 3,
    });
  }
  public fetchData(params: IFetchParticipantParams) {
    this.setState({ loading: true });
    fetchParticipants(params)
      .then((res) => {
        this.props.fetchAttorneys(
          res.data.results ? res.data.results : (res.data as IParticipant[])
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { loading, selectedAttorneyId, selectedSection } = this.state;
    return (
      <div className={classes.root}>
        <AttorneySearchSection
          loading={loading}
          handleClick={this.handleClickItem}
          fetchData={this.fetchData}
          pagination={true}
        />
        <div className={classes.rightSide}>
          {selectedSection === 0 ? (
            <DefaultSection
              message={translations.ATTORNEY_DEFAULT_SECTION_MESSAGE}
              key={0}
            />
          ) : null}
          {selectedSection === 1 ? (
            <Loading message={translations.LOADING} key={1} />
          ) : null}
          {selectedSection === 2 ? (
            <AttorneyView
              changeSection={this.handleChangeSection}
              attorneyId={selectedAttorneyId ? selectedAttorneyId : ""}
            />
          ) : null}
          {selectedSection === 3 ? (
            <AttorneyForm changeSection={this.handleChangeSection} />
          ) : null}
          {selectedSection !== 3 ? (
            <Fab className={classes.addButton} onClick={this.handleAddClick}>
              <AddIcon />
            </Fab>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    attorneys: state.attorneys,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchAttorneys })(AttorneySection)
);
