import React, { useState, useEffect } from "react";
import {
  Radio,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  createTheme,
  Chip,
  IconButton,
  Switch,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import green from "@material-ui/core/colors/green";
import { useSelector, useDispatch } from "react-redux";
import {
  // debouncedSearch, search,
  clearSearch,
  throttledSearch,
} from "../../actions";

const RadioStyled = withStyles({
  root: {
    color: "#2E7D32",
    "&$checked": {
      color: "#2E7D32",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StyledTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#4caf50",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        color: "#4caf50",
        borderColor: "#4caf50",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4caf50",
      },
    },
    "& .Mui-disabled": {
      backgroundColor: "#EDEDED",
    },
  },
})(TextField);

const materialTheme = createTheme({
  palette: {
    primary: {
      main: green[600],
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#424242",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#4caf50",
        color: "#fff",
      },
      current: {
        color: "#4caf50",
      },
    },
  },
});

function SearchTextField(props) {
  const [query, setQuery] = useState("");
  const token = useSelector((state) => state.user.token);
  const results = useSelector(
    (state) => state.autocompleteDebunce.searchResults[props.optionType]
  );
  const dispatch = useDispatch();

  const onSearchChange = async (value) => {
    setQuery(value);

    try {
      dispatch(throttledSearch(value, token, props.optionType));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchResults = (results) => {
    if (props.setResults) {
      props.setResults(results);
    }
  }

  const handleChange = (event) => {
    const value = event.target.value;
    onSearchChange(value);
  };

  useEffect(() => {
    if(results){
      handleSearchResults(results)
    }
  }, [results])
  

  useEffect(() => {
    setQuery("");
    dispatch(clearSearch());
  }, [props.optionType]);

  return (
    <TextField
      variant="standard"
      style={{ width: 300 }}
      placeholder="Buscar..."
      fieldID="expiry-table-search"
      margin="dense"
      fullWidth
      value={query}
      onKeyDown={(e) => {
        if (e.key === "Enter") onSearchChange(query);
      }}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              onClick={() => onSearchChange("")}
              variant="text"
              style={{
                fontSize: 12,
                padding: 4,
                margin: 8,
                color: "rgba(0, 0, 0, 0.54)",
              }}
            >
              LIMPIAR
            </Button>
            <IconButton
              onClick={() => onSearchChange(query)}
              style={{ padding: 4 }}
            >
              <Search style={{ fontSize: 20, color: "#43A047", padding: 2 }} />
            </IconButton>
          </Grid>
        ),
      }}
    />
  );
}


function SearchComponent(props) {
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const results = useSelector(
    (state) => state.autocompleteDebunce.searchResults[props.optionType]
  );
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const onSearch = (event, value) => {
    setQuery(value);
    dispatch(throttledSearch(value, token, props.optionType));
    if (props.onInputChange) {
      props.onInputChange(value);
    }
  };

  const handleSelectOption = (event, value) => {
    setSelectedOption(value);
    props.handleChange(value);
  };

  useEffect(() => {
    if (props.savedValue) {
      setSelectedOption(props.savedValue);
    } else {
      setSelectedOption(null);
    }
  }, [props.savedValue]);

  useEffect(() => {
    setQuery("");
    if (!props.savedValue) {
      setSelectedOption(null);
    }
    dispatch(clearSearch());
  }, [props.optionType]);

  return (
    <AutocompleteField
      xs={props.xs}
      containerClass={props.containerClass}
      fieldClass="fields"
      typeVariant="subtitle1"
      typeClass="field-label"
      fieldLabel={props.fieldLabel}
      fieldID={props.fieldID}
      fieldInnerLabel={props.fieldInnerLabel}
      fieldVariant="outlined"
      value={selectedOption || (props.multiple ? [] : null)}
      inputValue={query}
      handleChange={handleSelectOption}
      onInputChange={onSearch}
      options={
        selectedOption && results
          ? results.find((r) => r.value === selectedOption.value)
            ? results
            : results.concat(selectedOption)
          : results || []
      } // Always add selected option to the result list, preventing autocomplete errors.
      multiple={props.multiple}
      clearOnBlur={false}
      limitTags={props.limitTags}
      error={props.error}
      helperText={props.helperText}
      disabled={props.disabled}
      getOptionLabel={(option) => option.name || undefined}
      getOptionSelected={(option, value) =>
        option.value === value.value || undefined
      }
      noOptionsText={query ? "..." : "DIGITE UNA OPCIÓN..."}
    />
  );
}

function TableSearch(props) {
  const [search, setSearch] = useState("");

  const handleSearchChange = (event) => {
    handleTableSearch(event.target.value);
    setSearch(event.target.value);
  };
  const handleTableSearch = (value) => {
    let newData = [];
    if (value && props.searchFields.length) {
      const searchQuery = value.toLowerCase();
      newData = props.data.filter((row) =>
        props.searchFields.find((field) =>
          row[field]?.toLowerCase()?.includes(searchQuery)
        )
      );
    } else {
      newData = props.data;
      setSearch("");
    }
    props.setTableData(newData);
  };
  return (
    <div style={{ margin: "0 1rem 0 0" }}>
      <TextFieldComponent
        fieldVariant="standard"
        style={{ width: 300 }}
        typeVariant="subtitle1"
        typeClass="field-label"
        placeholder="Buscar..."
        fieldID="expiry-table-search"
        fieldClass="fields"
        margin="dense"
        fullWidth
        value={search}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleTableSearch(search);
        }}
        onChange={handleSearchChange}
        disabled={!props.data.length}
        inputProps={{
          endAdornment: (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                onClick={handleTableSearch.bind(this, "")}
                variant="text"
                style={{
                  fontSize: 12,
                  padding: 4,
                  margin: 8,
                  color: "rgba(0, 0, 0, 0.54)",
                }}
                disabled={!props.data.length}
              >
                LIMPIAR
              </Button>
              <IconButton
                onClick={handleTableSearch.bind(this, search)}
                style={{ padding: 4 }}
                disabled={!props.data.length}
              >
                <Search
                  style={{ fontSize: 20, color: "#43A047", padding: 2 }}
                />
              </IconButton>
            </Grid>
          ),
          // onKeyDown: (event) => {
          //      if(event.keyCode == 13) {
          //         handleTableSearch(event.target.value);
          //      }
          // },
        }}
      />
    </div>
  );
}

function AutocompleteField(props) {
  return (
    <Grid
      item
      container={props.container}
      xs={props.xs}
      className={props.containerClass || "field-container"}
    >
      {props.fieldLabel ? (
        <Typography
          variant={props.typeVariant || "subtitle1"}
          className={props.typeClass || "field-label"}
        >
          {props.fieldLabel}
        </Typography>
      ) : (
        ""
      )}
      <Autocomplete
        className={props.fieldClass || "fields"}
        style={props.style}
        id={props.fieldID}
        multiple={props.multiple}
        limitTags={props.limitTags}
        options={props.options}
        value={props.value}
        required={props.required}
        getOptionLabel={props.getOptionLabel}
        getOptionSelected={props.getOptionSelected}
        onChange={props.handleChange}
        onInputChange={props.onInputChange}
        disableCloseOnSelect={props.multiple ? true : false}
        disableClearable={props.disableClearable}
        disabled={props.disabled}
        clearOnBlur={props.clearOnBlur}
        defaultValue={props.defaultValue}
        inputValue={props.inputValue}
        noOptionsText={props.noOptionsText || "NO HAY OPCIONES"}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: "off" }}
            label={props.fieldInnerLabel}
            variant={props.fieldVariant || "outlined"}
            margin="dense"
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: (
                <div>
                  {params.InputProps.endAdornment}
                  {props.insideElement}
                </div>
              ),
              // endAdornment: props.insideElement
            }}
          />
        )}
        renderTags={
          props.optionLabel
            ? (tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option[props.optionLabel]}
                    {...getTagProps({ index })}
                  />
                ))
            : null
        }
        ChipProps={props.chipProps}
      />
    </Grid>
  );
}

function SearchAutocompleteField(props) {
  const [options, setOptions] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const loading = open && options.length === 0 && inputText.length > 0;

  useEffect(() => {
    async function fetchData() {
      const endpoint = previous
        ? props.endpoint({ token: props.token, name: inputText })
        : props.endpoint({ token: props.token, name: inputText, ...previous });

      const res = await endpoint;

      const json = await res.data;

      json ? setOptions(json) : setOptions([]);
    }

    if (inputText?.length > 0) {
      // only search the API if there is something in the text box
      fetchData();
    } else {
      setOptions([]);
      setOpen(false);
    }
  }, [inputText]);

  useEffect(() => {
    setOptions(props.options);
    setPrevious(props.prev);
  }, [props.prev]);

  return (
    <Grid
      item
      container={props.container}
      xs={props.xs}
      className={props.containerClass || "field-container"}
    >
      {props.fieldLabel ? (
        <Typography
          variant={props.typeVariant || "subtitle1"}
          className={props.typeClass || "field-label"}
        >
          {props.fieldLabel}
        </Typography>
      ) : (
        ""
      )}
      <Autocomplete
        className={props.fieldClass || "fields"}
        style={props.style}
        id={props.fieldID}
        multiple={props.multiple}
        limitTags={props.limitTags}
        options={options}
        value={props.value}
        required={props.required}
        onChange={props.handleChange}
        getOptionLabel={props.getOptionLabel}
        filterOptions={(options) => options}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={props.getOptionSelected}
        onInputChange={(event, newValue) => {
          // text box value changed
          if (newValue.length > 3) {
            setInputText(newValue);
          } else {
            setOptions([]);
          }
        }}
        disableCloseOnSelect={props.multiple ? true : false}
        disableClearable={props.disableClearable}
        disabled={props.disabled}
        clearOnBlur={props.clearOnBlur}
        defaultValue={props.defaultValue}
        inputValue={props.inputValue}
        noOptionsText={props.noOptionsText || "NO HAY OPCIONES"}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: "off" }}
            label={props.fieldInnerLabel}
            variant={props.fieldVariant || "outlined"}
            margin="dense"
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: (
                <div>
                  {params.InputProps.endAdornment}
                  {props.insideElement ? (
                    props.insideElement
                  ) : (
                    <InputAdornment position="end" color="inherit">
                      <>
                        {loading ? (
                          <CircularProgress color="secondary" size={"2rem"} />
                        ) : null}
                      </>
                      {/* <>{<CircularProgress color="secondary" size={"2rem"} />}</> */}
                    </InputAdornment>
                  )}
                </div>
              ),
              // endAdornment: props.insideElement
            }}
          />
        )}
        renderTags={
          props.optionLabel
            ? (tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option[props.optionLabel]}
                    {...getTagProps({ index })}
                  />
                ))
            : null
        }
        ChipProps={props.chipProps}
      />
    </Grid>
  );
}

function CheckboxField(props) {
  return (
    <Grid
      style={props.style}
      item
      container
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      xs={props.xs}
      className={props.containerClass}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={props.check}
            onChange={props.handleChange}
            color={props.color}
            style={{ color: props.textColor }}
            disabled={props.disabled}
          />
        }
        label={props.label}
        disabled={props.disabled}
      />
    </Grid>
  );
}

function SelectField(props) {
  return (
    <Grid item xs={props.xs} className={props.containerClass}>
      {props.fieldLabel ? (
        <Typography variant={props.typeVariant} className={props.typeClass}>
          {props.fieldLabel}
        </Typography>
      ) : (
        ""
      )}
      <StyledTextField
        id={props.fieldID}
        className={props.fieldClass}
        fullWidth
        label={props.fieldText}
        variant={props.fieldVariant}
        value={props.value}
        onChange={props.onChangeHandler}
        select
        margin="dense"
      >
        {props.options.map((option, index) => (
          <MenuItem
            key={index + (option.value ? option.value : option)}
            value={option.value ? option.value : option}
          >
            {option.label ? option.label : option}
          </MenuItem>
        ))}
      </StyledTextField>
    </Grid>
  );
}

function ButtonField(props) {
  return (
    <Grid
      item
      container
      xs={props.xs}
      alignItems={props.alignItems}
      className={props.containerClass}
    >
      <Button
        variant={props.btnVariant}
        disableElevation={props.disableElevation}
        size={props.btnSize}
        className={props.btnClass}
        onClick={props.onClick}
      >
        {props.btnLabel}
      </Button>
    </Grid>
  );
}

function DateField(props) {
  const customProps = {
    id: props.dateFieldID,
    fullWidth: props.fullWidth,
    className: props.dateFieldClass || "date-field",
    inputVariant: props.inputVariant || "outlined",
    variant: props.variant,
    label: props.dateFieldLabel,
    format: props.format || "dd/MM/yyyy",
    value: props.value,
    clearable: props.clearable === false ? false : true,
    onChange: props.handleChange,
    margin: "dense",
    invalidLabel: "Fecha incorrecta",
    error: props.error,
    helperText: props.helperText,
    clearLabel: "LIMPIAR",
    cancelLabel: "CANCELAR",
    okLabel: "ACEPTAR",
    disabled: props.disabled,
    disablePast: props.disablePast,
    disableFuture: props.disableFuture,
    KeyboardButtonProps: {
      "aria-label": "change date",
    },
  };
  return (
    <Grid item xs={props.xs} className={props.containerClass}>
      <Typography
        variant={props.typeVariant || "subtitle1"}
        className={props.typeClass || "field-label"}
      >
        {props.fieldLabel}
      </Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <ThemeProvider theme={materialTheme}>
            {props.format?.toLowerCase() === "dd/mm/yyyy hh:mm" ? (
              <KeyboardDateTimePicker {...customProps} />
            ) : (
              <KeyboardDatePicker {...customProps} />
            )}
          </ThemeProvider>
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
}

function SwitchComponent(props) {
  return (
    <Grid item xs={props.xs} className={props.containerClass}>
      <Typography
        variant={props.typeVariant || "subtitle1"}
        className={props.typeClass || "field-label"}
      >
        {props.fieldLabel}
      </Typography>
      <Switch
        checked={props.value}
        inputProps={{ "aria-label": "controlled" }}
        style={props.style}
        className={props.fieldClass || "fields"}
        id={props.fieldID}
        placeholder={props.placeholder}
        fullWidth={props.fullWidth}
        variant={props.fieldVariant || "outlined"}
        InputLabelProps={{
          shrink: props.shrink,
        }}
        InputProps={props.inputProps || props.InputProps}
        disabled={props.disabled}
        onChange={props.onChange}
        readOnly={props.readOnly}
        margin={props.margin || "dense"}
        size={props.size}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
      />
    </Grid>
  );
}

function TextFieldComponent(props) {
  return (
    <Grid item xs={props.xs} className={props.containerClass}>
      <Typography
        variant={props.typeVariant || "subtitle1"}
        className={props.typeClass || "field-label"}
      >
        {props.fieldLabel}
      </Typography>
      <StyledTextField
        inputProps={{ autoComplete: "new-password" }}
        style={props.style}
        className={props.fieldClass || "fields"}
        id={props.fieldID}
        placeholder={props.placeholder}
        fullWidth={props.fullWidth}
        variant={props.fieldVariant || "outlined"}
        InputLabelProps={{
          shrink: props.shrink,
        }}
        InputProps={props.inputProps || props.InputProps}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        inputRef={props.inputRef}
        type={props.type}
        readOnly={props.readOnly}
        margin={props.margin || "dense"}
        size={props.size}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        multiline={props.multiline}
        minRows={props.rows}
      ></StyledTextField>
    </Grid>
  );
}

function NumericTextField(props) {
  return (
    <Grid item xs={props.xs} className={props.containerClass}>
      <Typography
        variant={props.typeVariant || "subtitle1"}
        className={props.typeClass || "field-label"}
      >
        {props.fieldLabel}
      </Typography>
      <NumberFormat
        customInput={StyledTextField}
        style={
          props.disabled
            ? { ...props.style, backgroundColor: "#f5f5f5" }
            : props.style
        }
        value={props.value}
        className={props.fieldClass || "fields"}
        variant={props.fieldVariant || "outlined"}
        readOnly={props.readOnly}
        disabled={props.disabled}
        required={props.required}
        placeholder={props.placeholder}
        margin={props.margin || "dense"}
        thousandSeparator={props.thousandSeparator}
        decimalScale={props.decimalScale}
        fixedDecimalScale={props.fixedDecimalScale}
        fullWidth={props.fullWidth}
        error={props.error}
        prefix={props.prefix}
        suffix={props.suffix}
        format={props.format}
        mask={props.mask}
        type={props.type}
        allowEmptyFormatting={props.allowEmptyFormatting}
        inputMode={props.inputMode || "numeric"}
        allowNegative={false}
        onValueChange={props.onChange}
        helperText={props.helperText}
        isAllowed={props.isAllowed}
        allowLeadingZeros={props.allowLeadingZeros}
      />
    </Grid>
  );
}

function NumericMinMax(props) {
  return (
    <Grid
      container
      item
      xs={props.xs}
      className={props.containerClass}
      spacing={props.spacing}
    >
      <Typography variant={props.typeVariant} className={props.typeClass}>
        {props.fieldLabel}
      </Typography>
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%" }}
      >
        {[1, 2].map((item, index) => {
          return (
            <Grid
              key={item + "-" + index}
              item
              container
              style={{ width: "48%" }}
            >
              <NumberFormat
                customInput={StyledTextField}
                value={item === 1 ? props.valueMin : props.valueMax}
                className={props.fieldClass}
                variant={props.fieldVariant}
                readOnly={props.readOnly}
                disabled={props.disabled}
                placeholder={item === 1 ? "$ Mínimo" : "$ Máximo"}
                margin={props.margin}
                fullWidth={props.fullWidth}
                thousandSeparator={props.thousandSeparator}
                decimalScale={props.decimalScale}
                fixedDecimalScale={props.fixedDecimalScale}
                error={props.error}
                prefix={props.prefix}
                format={props.format}
                mask={props.mask}
                allowEmptyFormatting={props.allowEmptyFormatting}
                inputMode="numeric"
                allowNegative={false}
                onValueChange={
                  item === 1 ? props.onChangeMin : props.onChangeMax
                }
                helperText={props.helperText}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

function RadioGroupComponent(props) {
  return (
    <Grid item xs={props.xs} className={props.containerClass}>
      <FormControl component="fieldset">
        <Typography variant={props.typeVariant} className={props.typeClass}>
          {props.fieldLabel}
        </Typography>
        <RadioGroup
          row={props.row}
          aria-label={props.fieldLabel}
          name={props.fieldLabel}
          value={props.value}
          onChange={props.onChange}
        >
          {props.options.map((item) => {
            return (
              <div
                key={item.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  // onClick={props.onClick}
                  value={item.value}
                  control={<RadioStyled onClick={props.onClick} />}
                  label={item.text}
                  style={{ color: item.color }}
                  disabled={props.disabled}
                />
                {item.element && item.element}
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export {
  AutocompleteField,
  SelectField,
  ButtonField,
  DateField,
  TextFieldComponent,
  RadioGroupComponent,
  CheckboxField,
  NumericTextField,
  NumericMinMax,
  SearchComponent,
  SearchAutocompleteField,
  TableSearch,
  SwitchComponent,
  SearchTextField
};
