import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { ToolbarComponent } from "./../../../components/SharedComponents/Toolbar";
import { LeadsTable } from "./../Tables";
import { LeadsDialog, ConfirmDialogComponent } from "./../Dialogs";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import { Filters } from "./../Filters";
import {
  fetchAssignees,
  fetchLeads,
  createLead,
  updateLead,
  deleteLead,
  fetchNationalities,
  fetchRegions,
  fetchStates,
  fetchCities,
  fetchProfessions,
  fetchInstitutions,
  complianceStateLead,
  hrStateLead,
  businessStateLead,
  cancelStateLead,
  uploadFilesLead,
  completeLead,
  fetchDepartments,
  fetchPositions,
} from "./../../../utils/axios";
import LoadingDialog from "../../../components/SharedComponents/LoadingScreen";
import {
  errorHandling,
  handleToggleDialog as handleDialog,
  handleToggleSimpleDialog as handleSimpleDialog,
} from "../../../components/SharedComponents/CustomHooks";
import {
  leadStatusOrders,
  formattedFieldsData,
  fieldsTranslations,
} from "./../GeneralMethods";
import axios from "axios";
import jwtDecode from "jwt-decode";
import "./../main.css";
import { Prompt } from "react-router";

function LeadsView(props) {
  const ignoreFlattenFields = [
    "financialCertificate",
    "addressFile",
    "identificationFile",
    "blacklist",
  ];
  const limitRequest = { limit: 25 };
  const dialogTypes = ["leads"];
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({});
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [leads, setLeads] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selected, setSelected] = useState([]);
  const [generalData, setGeneralData] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedLeadData, setSelectedLeadData] = useState(null);
  const [isSuperUser, setIsSuperUser] = useState(false);

  const handleToggleDialog = handleDialog.bind(this, setDialog, dialog);
  const handleToggleSimpleDialog = handleSimpleDialog.bind(
    this,
    setSimpleDialog
  );

  const deepFindErrs = (errs, newResult, parentKey) => {
    let result = newResult || {};
    for (const property in errs) {
      const isArr = Array.isArray(errs[property]);
      if (isArr) {
        const translation =
          `Campo <strong>${fieldsTranslations[property] ||
            property}:</strong>` || "";
        result[property] = `<span>${translation + " " + errs[property]}</span>`;
      } else {
        deepFindErrs(errs[property], result, property);
      }
    }
    return result;
  };

  const handleFieldErrors = (setErrors, errors) => {
    const errObj = errors?.response?.data;
    let formattedErrors = {};
    if (typeof errObj === "object") {
      formattedErrors = deepFindErrs(errObj);
    }
    let err = errObj || "";
    if (!Object.keys(formattedErrors || {}).length && !err) {
      err = {
        response: {
          data: "Un error técnico ha ocurrido.",
        },
      };
    } else {
      err = { response: { data: formattedErrors } };
    }
    if (!errObj) err = "";
    errorHandling(err, handleToggleSimpleDialog);
    if (setErrors && err) {
      setErrors((prevState) => ({ ...prevState, errors: errObj || {} }));
    }
  };

  const getInitData = () => {
    getLeads(limitRequest);
    getPersonalInfoData();
  };

  const getPersonalInfoData = () => {
    const token = { token: props.user.token };
    const nationalities = fetchNationalities(token);
    const regions = fetchRegions(token);
    const states = fetchStates(token);
    const cities = fetchCities(token);
    const professions = fetchProfessions(token);
    const departments = fetchDepartments(props.user.token);
    const positions = fetchPositions({ token: props.user.token });
    const financialInstitutions = fetchInstitutions({
      ...token,
      institutionType: "financial",
    });
    const endpoints = [
      nationalities,
      regions,
      states,
      cities,
      professions,
      financialInstitutions,
      departments,
      positions,
    ];
    axios
      .all(endpoints)
      .then(
        axios.spread((...responses) => {
          const data = {
            nationalities: responses[0].data,
            regions: responses[1].data,
            states: responses[2].data,
            cities: responses[3].data,
            professions: responses[4].data,
            institutions: responses[5].data.map((i) => ({
              fullName: `${i.abbreviation} - ${i.name}`,
              name: i.name,
              uniqueId: i.uniqueId,
            })),
            departments: responses[6].data,
            positions: responses[7].data,
          };
          setGeneralData({ ...data });
        })
      )
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const flattenData = (data, newResult, parentKey) => {
    let result = newResult || {};
    for (const property in data) {
      // FIRST CONDITION ONLY TO HANDLE REFERENCE FIELDS
      if (ignoreFlattenFields.includes(property)) {
        result[property] = data[property];
      } else {
        if (
          Array.isArray(data[property]) ||
          typeof data[property] !== "object" ||
          !data[property]
        ) {
          // HANDLE UNIQUEID
          if (parentKey && property === "uniqueId") {
            result[`${property}_${parentKey}`] = data[property];
          } else if (result.hasOwnProperty(property)) {
            result[`${parentKey || ""}_${property}`] = data[property];
          } else {
            let value = data[property];
            result[property] = value;
          }
        } else {
          flattenData(data[property], result, property);
        }
      }
    }
    return result;
  };

  const formatData = (response) => {
    const data = response.data.results ? response.data.results : response.data;
    let formattedData = [];
    data.forEach((item) => {
      const newData = flattenData(item);
      formattedData.push(newData);
    });
    return formattedData;
  };

  const getLeads = (data) => {
    setLoading(true);
    const endpoint = fetchLeads({ ...data, token: props.user.token });
    endpoint
      .then((response) => {
        const formattedData = formatData(response);
        setLeads(formattedData);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const saveCreateLead = async (data, action, setSectionErrors) => {
    if (setSectionErrors) {
      setSectionErrors({ errors: null, formatted: null });
    }
    setLoading(true);
    let endpoint;
    if (action === "update") {
      endpoint = updateLead(props.user.token, data.uniqueId, data);
    } else {
      endpoint = createLead(props.user.token, data);
    }
    return await endpoint
      .then((response) => {
        getLeads(limitRequest);
        setLoading(false);
        handleToggleSimpleDialog("success-dialog");
        if (action === "create") handleToggleDialog("leads");
        if (action === "update") {
          const newData = flattenData(response.data);
          setSelectedLeadData({ ...newData });
        }
        return response.data.uniqueId;
      })
      .catch((errors) => {
        setLoading(false);
        if (setSectionErrors) {
          handleFieldErrors(setSectionErrors, errors);
        } else {
          errorHandling(errors, handleToggleSimpleDialog);
        }
        return false;
      });
  };
  const getUser = () => {
    const userId = jwtDecode(props.user.token).user_id;
    fetchAssignees({ token: props.user.token, id: userId }).then((response) => {
      const data = response.data[0];
      // setLoggedUser(data.username);
      setIsSuperUser(data.isSuperuser)
      setRoles(data.groups);
    });
  };

  // method to set all states and validations to show correctly.
  const leadStates = (data) => {
    const bl = data.blacklist;
    const isFlagged = bl
      ? Boolean(Object.keys(bl || {}).find((key) => bl[key] === true))
      : false;
    const leadType = data.institutionType || "affiliate";
    const states = [
      {
        label: "Cancelar",
        show: (data?._state || data?.state) !== "canceled",
        state: "canceled",
        hasPermission: true,
        color: "#f24e4e",
      },

      {
        label: "Aut. Oficial",
        state: "pending_evaluation",
        hasPermission: roles.includes("oficial de negocio"),
        color: "#4688e3",
      },
      {
        label: "Aut. RRHH",
        state: "hr_confirmation_pending",
        hasPermission: roles.includes("rh empresa afiliada"),
        color: "#ed9e4a",
      },
      {
        label: "Aut. Cumplimiento",
        state: "compliance_review_pending",
        hasPermission: roles.includes("cumplimiento"),
        color: "#edb03e",
      },
      {
        label: "Aut. Conciliación",
        state: "conciliation_pending",
        hasPermission: roles.includes("lead conciliacion"),
        color: "#17b38c",
      },
      {
        label: "Aut. Firma",
        state: "pending_signature",
        hasPermission: roles.includes("lead firma"),
        color: "#3ac5c7",
      },
      {
        label: "Completar Lead",
        state: "signature_completed",
        // completeLead: handleRohiStep,
        hasPermission: roles.includes("lead firma"),
        show: (data?._state || data?.state) === "signature_completed",
        color: "#43a047",
      },
    ];

    // create flagged/unflagged states order buttons validations.
    const flaggedValidation = (ls) =>
      isFlagged
        ? ["flagged", "both"].includes(ls.case)
        : ["", "both"].includes(ls.case);
    const newOrders = leadStatusOrders.filter(
      (ls) => ls.type === leadType && flaggedValidation(ls)
    );
    // ^ after, create conciliation/sign states order buttons validations.
    const concilSignValidation =
      !isFlagged && data.wayToPay !== "payroll_deduction"
        ? [
            { state: "conciliation_pending", order: newOrders.length + 1 },
            { state: "pending_signature", order: newOrders.length + 2 },
          ]
        : [{ state: "pending_signature", order: newOrders.length + 1 }];
    // set both validations on states main array.
    const permStates = states.filter((item) =>
      ["canceled", "signature_completed"].includes(item.state)
    );
    let newStateData = permStates;
    states.forEach((st) => {
      const newInfo = newOrders.find((o) => o.value === st.state);
      const concilSign = concilSignValidation.find(
        (csv) => csv.state === st.state
      );
      if (concilSign) {
        newStateData.push({
          ...st,
          order: concilSign.order,
          show: (data?._state || data?.state) === st.state,
        });
      } else if (newInfo) {
        newStateData.push({
          ...st,
          order: newInfo.order,
          // show: ((data?._state || data?.state) === "pending_evaluation" && newInfo.order === 1) ? true : (data?._state || data?.state) === st.state,
          show: (data?._state || data?.state) === st.state,
        });
      }
    });
    // filter the array to remove orderless items.
    return newStateData;
  };

  // methods that fires when clicking state buttons:
  const handleStates = (leadData, leadInfo) => {
    let formattedLeadData = formattedFieldsData(leadData, leadData, {
      institution: leadInfo.institution,
    });
    const currState = formattedLeadData.state;
    let next = "";
    if (leadInfo.currBtnState === "canceled") {
      next = leadInfo.currBtnState;
    } else {
      next =
        currState === "signature_completed"
          ? "complete"
          : leadInfo.nextOrder || "signature_completed";
    }
    if (next === "pending_signature") {
      handleRohiStep({
        leadData: {
          ...formattedLeadData,
          [formattedLeadData.hasOwnProperty("_state")
            ? "_state"
            : "state"]: "pending_signature",
        },
        setSectionErrors: leadInfo.setSectionErrors,
      });
      return;
    }
    setLoading(true);
    const endpoint = updateLead(props.user.token, leadData?.uniqueId, {
      ...formattedLeadData,
      state: next,
    });
    endpoint
      .then((res) => {
        // refresh leads data behing.
        getLeads(limitRequest);
        handleToggleSimpleDialog("success-dialog");
        if (leadInfo.order) {
          setSelectedLeadData(() => {
            let data = flattenData(
              res.data.results ? res.data.results : res.data
            );
            if (data.hasOwnProperty("_state")) {
              data._state = next;
            } else {
              data.state = next;
            }
            return data;
          });
        } else {
          handleToggleDialog("leads");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // nullified due to object provided
        errorHandling(null, handleToggleSimpleDialog);
      });
  };

  const handleRohiStep = (options) => {
    options.setSectionErrors({ errors: null, formatted: null });
    setLoading(true);
    const endpoint = completeLead(props.user.token, options.leadData.uniqueId);
    endpoint
      .then(() => {
        // if everything is ok, update lead to complete state and finish ok.
        saveCreateLead(options.leadData, "update", options.setSectionErrors);
      })
      .catch((errors) => {
        handleFieldErrors(options.setSectionErrors, errors);
        setLoading(false);
      });
  };

  const deleteSingleLead = (receipt) => {
    // setLoading(true);
    // const endpoint = deleteLead(props.user.token, receipt.uniqueId);
    // endpoint.then(() => {
    //     getLeads(selectedFilters);
    //     setLoading(false);
    //     handleToggleSimpleDialog('success-dialog');
    // }).catch(errors => {
    //     setLoading(false);
    //     errorHandling(errors, handleToggleSimpleDialog);
    // });
  };
  const handleDeleteMultipleLeads = () => {
    // const endpoints = selected.map(item => deleteLead(props.user.token, item.uniqueId));
    // axios.all(endpoints).then(() => {
    //     getLeads(selectedFilters);
    //     setSelected([]);
    //     setLoading(false);
    //     handleToggleSimpleDialog('success-dialog');
    // }).catch(errors => {
    //     setLoading(false);
    //     errorHandling(errors, handleToggleSimpleDialog);
    // });
  };
  const handleSelected = (selection) => {
    setSelected(selection);
  };

  const applyFilters = (fields) => {
    let data = fields;
    if (Object.keys(fields).length === 0) {
      data = limitRequest;
    }
    getLeads(data);
    setSelectedFilters({ ...data });
  };

  useEffect(() => {
    getInitData();
    getUser();
    // prevent page from leaving;
    window.onbeforeunload = function() {
      return "";
    };
  }, []);

  // useEffect(() => {
  //     // stop fetch dialog
  //     if(leads?.length && Object.keys(generalData).length) {
  //         setLoading(false);
  //     }
  // }, [leads, generalData])

  useEffect(() => {
    if (selectedLeadData) setSelectedLeadData(null);
  }, [dialog]);

  return (
    <div
      style={{
        padding: "20px 20px 10% 20px",
        maxWidth: "100rem",
        margin: "0 auto",
      }}
    >
      <Prompt
        when={Boolean(dialog["leads"])}
        message="¿Quiere irse de la página?"
      />
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="GESTIÓN DE LEADS"
        btnVariant="contained"
        toolbarActions={
          <Button
            variant="contained"
            className={"action-method-btn"}
            size="small"
            disableElevation
            onClick={() => handleToggleDialog("leads")}
          >
            nueva solicitud
          </Button>
        }
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <Filters
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          applyFilters={applyFilters}
        />
        <div style={{ margin: "10px 0" }}></div>
        <LeadsTable
          data={leads}
          user={props.user}
          handleToggleDialog={handleToggleDialog}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          handleDelete={deleteSingleLead}
          handleDeleteMultipleLeads={handleDeleteMultipleLeads}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          setLoading={setLoading}
        />
      </div>
      {dialog["leads"]?.open ? (
        <LeadsDialog
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleSimpleDialog={handleToggleSimpleDialog}
          data={selectedLeadData || dialog["leads"].data}
          leadStates={leadStates}
          modify={dialog["leads"].data ? true : false}
          dialogTitle={
            dialog["leads"].data
              ? "Información de Solicitud"
              : "Nueva Solicitud"
          }
          generalData={generalData}
          saveCreateLead={saveCreateLead}
          setLoading={setLoading}
          token={props.user.token}
          isSuperUser={isSuperUser}
          handleStates={handleStates}
          getLeads={() => getLeads(limitRequest)}
          handleFieldErrors={handleFieldErrors}
        />
      ) : null}
      {simpleDialog.name === "confirm-dialog" ? (
        <ConfirmDialogComponent
          open={true}
          data={simpleDialog}
          handleToggle={handleToggleSimpleDialog}
        />
      ) : null}
      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedSection: state.selectedSection,
  };
}
export default connect(mapStateToProps)(LeadsView);
