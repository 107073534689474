import * as React from "react";
import { connect } from "react-redux";

import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { titleCaseHandler, translations } from "../../../utils";
import {
  fetchNationalities,
  fetchRegions,
  fetchStates,
  fetchCities,
} from "../../../utils/axios";
import { MenuItem } from "@material-ui/core";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    display: "none",
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowy: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    marginTop: 20,
    width: "100%",
  },
});

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      loading: false,
      countries: [],
      regions: [],
      states: [],
      cities: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: false });
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          countries: res.data.results ? res.data.results : res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando nacionalidades",
          loading: false,
        });
      });
    fetchRegions({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          regions: res.data.results ? res.data.results : res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando regiones",
          loading: false,
        });
      });
    fetchStates({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          states: res.data.results ? res.data.results : res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando provincia",
          loading: false,
        });
      });
    fetchCities({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          cities: res.data.results ? res.data.results : res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando ciudades",
          loading: false,
        });
      });
  }
  handleReactSelectChange(nationality) {
    this.setState({ nationality });
  }

  handleChange(event, key) {
    this.props.handleSelect(key, event.target.value);
  }

  handleSelectChange(key, fn = null, stateChange, providedToken) {
    return (event) => {
      this.props.handleSelect(key, event.target.value);
      if (fn !== null) {
        fn({ token: providedToken, [key]: event.target.value })
          .then((res) => {
            const data = res.data.results ? res.data.results : res.data;
            this.setState({
              [stateChange]: data,
            });
          })
          .catch((err) => {
            this.setState({
              dialog: true,
              dialogTitle: "Error",
              errorMessage: "Problema cargando opciones",
              loading: false,
            });
          });
      }
    };
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <br />
        <Typography variant="h6">
          <b>{this.props.title}</b>
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              select
              variant="outlined"
              label={translations.COUNTRY}
              value={this.props.country}
              onChange={this.handleSelectChange(
                "country",
                fetchRegions,
                "regions",
                this.props.user.token
              )}
              error={this.props.countryError !== ""}
              inputProps={{
                id: "country",
                name: "country",
              }}
              InputLabelProps={{ style: styles.checkboxLabel }}
            >
              {this.state.countries.map((opt, index) => (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              select
              variant="outlined"
              label={translations.REGION}
              value={this.props.region}
              onChange={this.handleSelectChange(
                "region",
                fetchStates,
                "states",
                this.props.user.token
              )}
              error={this.props.regionError !== ""}
              inputProps={{
                id: "region",
                name: "region",
              }}
              InputLabelProps={{ style: styles.checkboxLabel }}
            >
              {this.state.regions.map((opt, index) => (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              select
              label={translations.PROVINCE}
              variant="outlined"
              value={this.props.state}
              onChange={this.handleSelectChange(
                "state",
                fetchCities,
                "cities",
                this.props.user.token
              )}
              error={this.props.stateError !== ""}
              inputProps={{
                id: "state",
                name: "state",
              }}
              InputLabelProps={{ style: styles.checkboxLabel }}
            >
              {this.state.states.map((opt, index) => (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              required
              variant="outlined"
              label={translations.CITY}
              value={this.props.city}
              onChange={this.handleSelectChange("city")}
              error={this.props.cityError !== ""}
              inputProps={{
                id: "city",
                name: "city",
              }}
              InputLabelProps={{ style: styles.checkboxLabel }}
            >
              {this.state.cities.map((opt, index) => (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label={translations.SECTOR}
              value={titleCaseHandler(this.props.sector)}
              onChange={(event) => this.handleChange(event, "sector")}
              error={this.props.sectorError !== ""}
              helperText={this.props.sectorError}
              InputLabelProps={{ style: styles.checkboxLabel }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              multiline
              variant="outlined"
              label={translations.REFERENCE}
              value={this.props.reference}
              onChange={(event) => this.handleChange(event, "reference")}
              error={this.props.referenceError !== ""}
              helperText={this.props.referenceError}
              InputLabelProps={{ style: styles.checkboxLabel }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(Form));
