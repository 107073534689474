// import  * as React from 'react';
import { AxiosError, AxiosResponse } from "axios";

import { API_BASE_URL } from "../Environment";
import {
  fetchLoanContract,
  fetchParticipant,
  generateRiskMatrix,
  http,
} from "./axios";

export function clearStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  // localStorage.removeItem('storageType');
  // sessionStorage.removeItem('token');
  // sessionStorage.removeItem('storageType');
}

export function getToken() {
  // const storageType = sessionStorage.getItem('storageType') || localStorage.getItem('storageType');

  // if (storageType) {
  // if (storageType === 'session') {
  //     return sessionStorage.getItem('token');
  // } else {
  return localStorage.getItem("token");
  // }
  // } else {
  //     return false;
  // }
}

export function setToken(token: string, refresh: string) {
  // ,storageType: string) {

  clearStorage();

  // if (storageType === "session") {
  //     sessionStorage.setItem('token', token);
  //     sessionStorage.setItem('storageType', storageType);
  // } else {
  localStorage.setItem("token", token);
  localStorage.setItem("refresh", refresh);
  // localStorage.setItem('storageType', storageType);
  // }
}

// force cast
export function forcecast<T>(input: any): T {
  return input;
}

// get id from url
export function getParticipantId(url: string): number {
  return parseInt(
    url.replace(`${API_BASE_URL}/participants/`, "").replace("/", ""),
    10
  );
}
export function getBankAccountId(url: string): number {
  return parseInt(
    url.replace(`${API_BASE_URL}/bank-accounts/`, "").replace("/", ""),
    10
  );
}

export const translations = {
  CLAIMS: "Reclamaciones",
  ABBREVIATION: "Abreviatura",
  ACCEPT: "Aceptar",
  ACCOUNT: "Cuenta",
  ACCOUNTING_RECORDS: "Asientos contables",
  ACCOUNT_MANAGEMENT_CONTRACT: "Contrato de manejo de cuenta",
  ACCOUNT_NUMBER: "Número de cuenta",
  ACCOUNT_NUMBER_FORMAT: "Formato de número de cuenta",
  ACCOUNT_REQUEST: "Solicitud de cuenta",
  ACCOUNT_REQUEST_TYPE: "Tipo de solicitud de cuenta",
  ACCOUNT_TYPE: "Tipo de cuenta",
  ACCRUED: "Devengados",
  ACF: "Formulario de conocimiento de compañia",
  ACT_DEFAULT_SECTION_MESSAGE: "Seleccione una acta para empezar.",
  ACTION: "Accion",
  ACTIVE: "Activo",
  ACTIVITY: "Actividad",
  ACTIVITY_TYPE: "Tipo de actividad",
  ACT_NUMBER: "Número de acto",
  ADDENDUM: "Addendum",
  ADDENDUM_TYPE: "Tipo de addendum",
  ADDITIONAL_PHONE: "Teléfono adicional",
  ADDRESS: "Dirección",
  ADMINISTRATION_ACCOUNT_RECEIVABLE: "Administración de cuentas por cobrar",
  ADMINISTRATION_EXPENSES: "Gastos administrativos",
  ADMINISTRATION_EXPENSES_TYPE: "Tipo de gastos administrativos",
  ADS: "avance de sueldo",
  ADVANCE_PURCHASES: "Compras Anticipadas",
  ADVANCE_SEARCH: "Búsqueda avanzada",
  AFFIDAVIT_FUNDS: "Procedencia de Fondos Juridico",
  AFFILIATE_STATE: "Estado del Afiliado",
  AFFILIATE_STATE_AFFILIATED: "Afiliado",
  AFFILIATE_STATE_LIQUIDATED: "Liquidado",
  AFFILIATE_STATE_NOT_AFFILIATE: "No Socio",
  AFFILIATE_STATE_PASSED_AWAY: "Fallecido",
  AFFILIATE_STATE_RETIRED: "Retirado",
  AFFILIATE_STATE_UNAFFILIATED: "Desafiliado",
  AFFILIATE_TYPE: "Tipo de Socio",
  AGE: "Edad",
  AGRICULTURAL: "Agricultura",
  ALL: "Todos",
  AMORTIZATION_TABLE: "Tabla de Amortización",
  AMOUNT: "Cantidad",
  AMOUNT_2: "Monto",
  AMOUNT_RECEIVED: "Monto Recibido",
  ANALYSIS_PENDING: "analisis pendiente",
  ANNIVERSARY_BONUS: "Bono Aniversario",
  ANNUALLY: "Anualmente",
  ANNUAL_BONUS: "Bonificacion Anual",
  ANNUAL_INTEREST: "Interés Anual",
  ANOTHER: "Otra",
  ANOTHER_ACTIVITY_TYPE_DESCRIPTION: "Descripción de otro tipo de actividad",
  ANSWER: "Respuesta",
  ANTIQUITY_BONUS: "Premio por antiguedad",
  ANTIQUITY_INCENTIVE: "Incentivo por antiguedad",
  ANUAL_PRIMARY_INCOME: "Ingreso anual primario",
  APF: "Formulario de conocimiento de personas",
  APPROVED: "Aprobado",
  APPROVED_AMOUNT: "Monto Aprobado",
  APPROXIMATE_AMOUNT_OF_TRANSACTIONS_WHICH_DEBTOR_WILL_MAKE_ON_THE_COOPERATIVE:
    "Monto apróximado de las trasacciones que el deudor hará con la cooperativa",
  APRF: "Formulario de conocimiento de proveedor",
  ARCHIVED: "Archivado",
  AREA: "Área",
  ARE_YOU_SURE: "Seguro de realizar esta acción?",
  ASSIGNEE: "Asignado",
  ATTORNEY: "Abogado",
  ATTORNEYS: "Abogados",
  ATTORNEY_DEFAULT_SECTION_MESSAGE: "Seleccione un abogado para empezar",
  ATTORNEY_IDENTIFICATION: "Identificación del abogado",
  ATTORNEY_NAME: "Nombre del abogado",
  ATTORNEY_NUMBER: "Número de abogado",
  AUNT: "Tia",
  AUTOMATIC_BANK_DEBIT: "Debito automatico bancario",
  AUTOMATIC_DEBIT_AUTHORIZATION: "Autorización de débito autómatico",
  AVAILABILITY_CALCULATOR: "Calculadora de Disponibilidad",
  BACK: "Atras",
  BANK: "Banco",
  BANKS: "Bancos",
  BANK_ACCOUNT: "Cuenta de banco",
  BANK_ACCOUNTS: "Cuentas de banco",
  BANK_ACCOUNT_DEFAULT_SECTION_MESSAGE:
    "Seleccione una cuenta de banco para empezar",
  BANK_CELL: "Celular bancario",
  BANK_DEFAULT_SECTION_MESSAGE: "Seleccione un banco para empezar.",
  BANK_EMAIL: "Email bancario",
  BANK_PASSWORD: "Clave Bancaria",
  BANK_RECONCILIATION: "Conciliación bancaria",
  BANK_TRANSACTION: "Transacción bancaria",
  BANK_TRANSFER: "Transferencia bancaria",
  BANK_USERNAME: "Usuario",
  BENEFICIARY: "Beneficiario",
  BIANNUAL: "Semestral",
  BILLING_SETTING: "Configuración de Cobro",
  BIMONTHLY: "Bimensual",
  BIRTHDATE: "Fecha de nacimiento",
  BIWEEKLY: "Quincenal",
  BLOCKED_USER: "Usuario Bloqueados",
  BOTH: "Ambos",
  BRANCH: "Categoría",
  BROTHER: "Hermano",
  BUILDING: "Constructura",
  BUSINESS_NAME: "Razón social",
  CANCEL: "Cancelar",
  CANCELLATION_DATE: "Día de cancelación",
  CANCELLATION_MEMBERSHIP: "Cancelación de membresia",
  CANCELLED: "Cancelado",
  CAPITALIZED: "Capitalizadas",
  CAPITALIZED_2: "Capitalizados",
  CARD_NETWORK: "Red de la tarjeta",
  CARD_OWNER: "Propietario de la Tarjeta",
  CARD_TYPE: "Tipo de tarjeta",
  CASH: "Efectivo",
  CASH_AND_CHECK: "Cheque y efectivo",
  CASH_TRANSACTION: "Transacción de efectivo",
  CELL_PHONE: "Teléfono móvil",
  CEMENTS: "Cemento",
  CERTIFICATE_OF_DEPOSITS: "Certificado de depositos",
  COMPLIANCE_REVIEW_PENDING: "Revisión de cumplimiento pendiente",
  CHARGING_INTERFACE: "Interfaz de Cobros",
  CHARGING_METHOD: "Método de cobro",
  CHART_OF_ACCOUNTS: "Catálogo de cuentas",
  CHECK: "Cheque",
  CHECKING: "Corriente",
  CHECK_DEFAULT_SECTION_MESSAGE: "Seleccione un cheque para empezar.",
  CHECK_NUMBER: "No. Cheque",
  CHEMISTRY: "Química",
  CHILD_SAVINGS: "Contrato de ahorro infantil",
  CHILD_SAVINGS_DEFAULT_SECTION_MESSAGE:
    "Seleccione un contrato de ahorro infantil para empezar.",
  CIR: "Comisión por tasa de interes",
  CITIZENSHIP_COUNTRY: "País de la Ciudadanía",
  CITY: "Ciudad",
  CIVIL_STATUS: "Estado civil",
  CLEAR: "Limpiar",
  CLIENT: "Socio",
  CLIENTS: "Socios",
  CLIENT_DEFAULT_SECTION_MESSAGE: "Seleccione un socio para empezar",
  CLINET_AFFIDAVIT_FUNDS: "Procedencia de Fondos Persona",
  COLLEGE_CREDIT: "Crédito universitario",
  COMMENT: "Comentario",
  COMMERCIAL: "Comercial",
  COMMERCIAL_REFERENCES: "Referencias comerciales",
  COMMISSION_AMOUNT: "Monto de Comisión",
  COMMISSION_RATE: "Tasa de Comisión",
  OTHER_INSURANCE: "Otro Seguro",
  COMMISSION_TYPE: "Tipo de Comisión",
  COMPANIES: "Compañías",
  COMPANY: "Compañía",
  COMPANY_DEFAULT_SECTION_MESSAGE: "Seleccione una compañía para empezar",
  COMPANY_REPRESENTATIVE: "Representante de compañía",
  COMPANY_REPRESENTATIVES: "Representantes de compañía",
  COMPANY_REPRESENTATIVE_DEFAULT_SECTION_MESSAGE:
    "Seleccione un representante de compañía para empezar",
  COMPLETED: "Completado",
  COMPREHENSIVE_FINANCIAL_SOLUTIONS: "Soluciones integradas financieras",
  COMPUTING: "Computación",
  CONCEPT: "Concepto",
  CONDONATION: "Condonación",
  CONDONED: "Condonadas",
  CONDONED_2: "Condonados",
  CONFIGURATION: "Configuración",
  CONFIRMED: "Confirmada",
  CONSECUTIVE_QUOTAS: "Cuotas consecutivas",
  CONSTANT_FEES_LOAN: "Escalonado constante",
  CONSULTING: "Consultorías",
  CONSUMER_FINANCING: "Financiamientos de consumo",
  CONTACT: "Contacto",
  CONTACT_METHOD: "Metodo de contacto",
  CONTRACT: "Contrato",
  CONTRACT_DATE: "Fecha del contrato",
  CONTRACT_DURATION: "Duración del Contrato",
  CONTRACT_FEE: "Tarifa de contrato",
  CONTRACT_PENDING: "Contrato pendiene",
  CONTRIBUTION_CERTIFICATE: "Certificado de aportación",
  CONVENTIONAL: "Convencional",
  CORPORATIVE_AFFILIATE: "Socio Coorporativo",
  COUNTRY: "País",
  COUSIN_F: "Prima",
  COUSIN_M: "Primo",
  CREATED: "Creado",
  CREATE_TICKET: "Crear ticket",
  CREATION_DATE: "Fecha de creación",
  CREDIT: "Crédito",
  CREDITOR: "Acreedor",
  CREDITORS: "Acreedores",
  CREDITOR_DEFAULT_SECTION_MESSAGE: "Seleccione un acreedor para empezar",
  CREDITOR_IDENTIFICATION: "Identificación del acreedor",
  CREDITOR_NAME: "Nombre del acreedor",
  CREDIT_CARD: "Tarjeta de crédito",
  CREDIT_CARD_DEFAULT_SECTION_MESSAGE:
    "Seleccione una tarjeta de crédito para empezar.",
  CREDIT_LINE: "Línea de Crédito",
  CREDIT_LINE_AMOUNT: "Cantidad de la línea de crédito",
  CREDIT_METHOD: "Método de Crédito",
  CREDIT_REFERENCE: "Referencia de Crédito",
  CREDIT_REVIEW_AUTHORIZATION: "Autorización de revisión crediticia",
  CREDIT_REVIEW_AUTHORIZATIONS_DEFAULT_SECTION_MESSAGE:
    "Seleccione un una autorización de revisión crediticia para empezar.",
  CURRENCY: "Moneda",
  CURRENT_AMOUNT: "Monto actual",
  CUSTOMER_ASSISTANCE: "Asistencia al socio",
  CUSTOMER_INFO: "Informacion del socio",
  CUSTORMER_ACCOUNT_MANAGEMENT: "Manejo de cuenta del socio",
  CUTTING_DAY: "Día de corte",
  CVC: "CVC",
  DATA_ADQUISITION: "Adquisición de datos",
  DATE: "Fecha",
  DEBIT: "Débito",
  DEBIT_AUTHORIZATION: "Autorización de débito",
  DEBTOR: "Deudor/a",
  DEBTORS: "Deudores",
  DEBTOR_ANOTHER_CITIZENSHIP: "¿El deudor posee otra ciudadanía?",
  DEBTOR_HANDLED_PUBLIC_RESOURCES: "El deudor maneja recursos públicos?",
  DEBTOR_IDENTIFICATION: "Identificación del deudor",
  DEBTOR_NAME: "Nombre del Deudor",
  DEBTOR_POSSESSED_PUBLIC_POWER: "El deudor posee poder público?",
  DEBTOR_PUBLIC_FIGURE: "El deudor es una figura publica?",
  DEBTOR_VEHICLE: "El deudor posee vehículo?",
  DEBT_CAPACITY: "Capacidad de endeudamiento",
  DEBT_REFINANCING: "Refinanciamiento de deudas",
  DECREASING_FEES_LOAN: "Escalonado decreciente",
  DEFAULT_ERROR_MESSAGE: "Por favor trate de nuevo o refresque la pagina",
  DEFAULT_INTERESTS: "Intereses moratorios",
  DELAYED: "Atrasado",
  DELETE: "Eliminar",
  DELIVERY_PENDING: "Entrega pendiente",
  DEMOMYN_FEMALE: "Gentilicio para mujeres",
  DEMOMYN_MALE: "Gentilicio para hombres",
  DEPARTMENT: "Departamento",
  DEPARTURE_DATE: "Fecha de Salida",
  DEPOSIT: "Deposito",
  DEPOSIT_15K:
    "¿El deudor recibirá depósitos iguales o mayores a 15 mil dólares o su equivalente en moneda nacional en la cuenta?",
  DEPOSIT_ACCOUNT: "Cuenta de deposito",
  DEPOSIT_ACCOUNTS: "Cuentas de deposito",
  DEPOSIT_DATE: "Fecha de deposito",
  DESCRIPTION: "Descripción",
  DESTINATION: "Destino",
  DESTINATION_ACCOUNT: "Cuenta de destino",
  DEVICE_EXPIRATION_DATE: "Fecha de expiración del dispositivo",
  DEVICE_NUMBER: "Número de Dispositivo",
  DISBURSEMENT_PENDING: "Desembolso pendiente",
  DISCARTED: "Descartada",
  DISCOUNTED: "Descontadas",
  DISCOUNTED_2: "Descontados",
  DISTRIBUTION_PERCENTAGE: "Porcentaje de distribución",
  DIVORCED: "Divorciado(a)",
  DOCTOR: "Doctor",
  DOCUMENTS: "Documentos",
  DOMINICAN: "Dominicano",
  DOP: "DOP",
  DUE_DATE: "Fecha de vencimiento",
  DWELLING_TYPE: "Tipo de Vivienda",
  EASTER_ROYALTY: "NAVICOOP",
  ECONOMIC_ACTIVITY_INFO: "Información de actividad económica",
  ECONOMIC_SECTOR: "Sector económico",
  EDIT_TICKET: "Editar ticket",
  EDUCATION: "Educación",
  EDUCATION_LEVEL: "Nivel de Educación",
  EDUCATION_LEVEL_BACHELOR: "Universitario",
  EDUCATION_LEVEL_DOCTOR: "Doctorado",
  EDUCATION_LEVEL_ELEMENTARY: "Primario",
  EDUCATION_LEVEL_HIGHSCHOOL: "Bachiller",
  EDUCATION_LEVEL_POSTGRADUATED: "Maestria",
  ELECTRONIC: "Electrónica",
  EMAIL: "Correo electrónico",
  EMPLOYEE: "Empleado",
  EMPLOYEE_SALARIED: "Empleado / Asalariado",
  EMPLOYMENT_LENGTH: "Longitud de empleo (Años)",
  EMPLOYER_CODE: "Código empleador",
  ENSURES_AFFILIATE: "Socio Garante",
  ENTRY_DATE: "Fecha de Entrada",
  COOPERATIVE_ENTRY_DATE: "Fecha de Ingreso en la Cooperativa",
  ERROR_LOGIN_MESSAGE: "Usuario o Contraseña no son validos",
  EVALUATION_PENDING: "Evaluacion pendiente",
  EXPIRATION: "Vencimiento",
  EXPIRATION_DATE: "Fecha de expiración",
  EXPIRATION_DATE_ID: "Fecha vencimiento Identificación",
  EXPIRED: "Vencido",
  EXPIRY_VIEW: "Vencimientos",
  FACTORING: "Facturación",
  FAMILIAR: "Familiar",
  FAMILY_MEMBER_INFO: "Información de miembros familiares",
  FATHER: "Padre",
  FEE: "Cuota/s",
  FEMALE: "Femenino",
  FILE_UPLOADED: "Archivo Cargado",
  FINALITY: "Finalidad",
  FINAL_CONSUMER: "Consumidor Final - B02",
  FINANCED: "Financiado",
  FINANCIAL: "Financieras",
  FINANCIAL_2: "Financiero",
  FINANCIAL_CERTIFICATE: "Certificado financiero",
  FINANCIAL_CONFIG: "Configuración financiera",
  FINANCIAL_EVALUATION_PENDING: "Evaluacion financiera pendiente",
  FINANCIAL_INFORMATION: "Información financiera",
  FINANCIAL_PROFILE: "Perfil financiero",
  FINANCIAL_EDUCATION: "Educación financiera",
  FINANCING: "Financiamiento",
  FIRST_INSTALLMENT: "Primera cuota",
  FIRST_INSTALLMENT_DATE: "Fecha primera cuota",
  FIRST_OUTLAY_AMOUNT: "Monto de primer desembolso",
  FIRST_OUTLAY_DATE: "Fecha de primer desembolso",
  FISCAL_CONFIG: "Configuración Fiscal",
  FLIER: "Volante",
  FLIER_DEFAULT_SECTION_MESSAGE: "Seleccione un volante para empezar.",
  FOREIGN_ID: "ID extranjera",
  FRACTIONATION_TYPE: "Tipo fraccionamiento",
  FREE_UNION: "Unión libre",
  FRIEND: "Amigo/a",
  FROM: "Desde",
  FROM_1000_TO_4000_MM: "Desde RD$1000 millones hasta RD$4000 millones",
  FROM_100_TO_200_MM: "Desde RD$100 millones hasta RD$200 millones",
  FROM_120_TO_170: "Desde RD$120 mil hasta RD$170 mil",
  FROM_170_TO_200: "Desde RD$170 mil hasta RD$200 mil",
  FROM_200_TO_1000_MM: "Desde RD$200 millones hasta RD$1000 millones",
  FROM_20_TO_100_MM: "Desde RD$20 millones hasta RD$100 millones",
  FROM_20_TO_50: "Desde RD$20 mil hasta RD$50 mil",
  FROM_30_TO_60: "Desde RD$30 mil hasta RD$60 mil",
  FROM_30_TO_80: "Desde RD$30 mil hasta RD$80 mil",
  FROM_50_TO_100: "Desde RD$50 mil hasta RD$100 mil",
  FROM_60_TO_90: "Desde RD$60 mil hasta RD$90 mil",
  FROM_80_TO_120: "Desde RD$80 mil hasta RD$120 mil",
  FROM_90_TO_120: "Desde RD$90 mil hasta RD$120 mil",
  FULL_AFFILIATE: "Socio Pleno",
  FULL_NAME: "Nombre completo",
  FUNDS_ORIGIN: "Origen de fondos",
  GASTRONOMY: "Gastronomía",
  GENDER: "Sexo",
  GENERAL_MANAGER: "Gerente general",
  GENERATE_DOCUMENT: "Generar Documento",
  GOVERNMENTAL: "Gubernamental - B15",
  GRADUATE: "Licenciado",
  GROUP: "Grupo",
  HEALTHCARE: "Cuidado de la salud",
  HIGH: "Alta",
  HOME: "Casa",
  HOME_PHONE: "Teléfono de casa",
  HOUSEWIFE: "Ama de casa",
  HOUSE_MONTHLY_PAYMENT: "Pago mensual de propiedad", // Verificar con los Chicos.
  HR_CONFIRMATION_PENDING: "Confirmación pendiente RRHH",
  IDENTIFICATION: "Identificación",
  IDENTIFICATION_EXPIRATION_DATE: "Fecha de expiración de identificación",
  IDENTIFICATION_NUMBER: "Número de identificación",
  IDENTIFICATION_TYPE: "Tipo de identificación",
  INACTIVE: "Inactivo",
  INCLUDED: "Incluidos",
  INDEPENDENT: "Independiente",
  INDUSTRIAL: "Industrial",
  INFO_DESTINATION: "Información de destino",
  INVESTMENT: "Inversión",
  INVESTMENT_CERTIFICATE: "Certificado de inversión",
  INITIAL_PAYMENT_PENDING: "Pago inicial pendiente",
  INSTALLMENT_LOAN: "Prestamo cuotas",
  INSTITUTION: "Institución",
  INSTITUTIONS: "Instituciones",
  INSTITUTION_DEFAULT_SECTION_MESSAGE:
    "Seleccione una institución para empezar",
  INSTITUTION_ECONOMIC_PORPORTION: "Proporción económica de la institución",
  INSTITUTION_TYPE: "Tipo de Institución",
  INSURANCE: "Seguro",
  INSURANCE_RATE: "Tasa de seguro",
  INTEREST: "Interés",
  INTEREST_RATE: "Tasa de interés",
  INTERNAL_SIGNATURE_PENDING: "Firma interna pendiente",
  INVOICE_MANAGEMENT: "Manejo de factura",
  INVOICE_PENDING: "Registro de recibo pendiente",
  IN_LEGALIZATION_PROCESS: "En tramite de legalizacion",
  IRREGULAR_BONUS: "Préstamo irregular",
  ISSUE_MANAGEMENT: "Manejo de problema",
  IS_MAIN_ACCOUNT: "Cuenta principal",
  IS_PAYROLL_ACCOUNT: "Cuenta nómina",
  IS_SPLIT: "Es Fraccionado",
  IS_SPLIT_CONDONATED: "¿Condonar Fraccionamiento?",
  KNOWLEDGE_FORM: "Formulario de conocimiento",
  LABORAL_CODE: "Código laboral",
  LABORAL_STATUS: "Estado laboral",
  LAST_NAME: "Apellido",
  LEDGER_ACCOUNT: "Cuenta Contable",
  LEADS: "Leads",
  LEADS_REQUESTS_MANAGEMENT: "SOLICITUDES",
  LEGAL: "Jurídico",
  LEGALIZED: "Legalizado",
  LEGAL_2: "Legal",
  LEGAL_EVALUATION_PENDING: "Evaluacion legal pendiente",
  LEGAL_OFFICE: "Oficina legal",
  LENDER_INVESTOR: "Prestamista / Inversionista",
  LESS_20: "Menos de RD$20 mil",
  LESS_20_MM: "Menos de RD$20 millones",
  LESS_30: "Menos de RD$30 mil",
  LOADING: "Cargando",
  LOANS: "Préstamos",
  LOAN_CONTRACT: "Contrato de préstamo",
  LOAN_CONTRACTS: "Contratos linea de crédito",
  LOAN_CONTRACTS_DEFAULT_SECTION_MESSAGE:
    "Seleccione un contrato para empezar.",
  LOAN_PAYMENTS: "Pago de Prestamos",
  LOAN_TYPES: "Tipos de Prestamo",
  LOG_IN: "Entrar",
  LOG_OUT: "Cerrar Sesión",
  LOW: "Baja",
  MA: "Solicitud de admisión de socio",
  MAIN_ACCOUNT: "Cuenta Principal",
  MAIN_ADDRESS: "Dirección Principal",
  MAIN_OFFICE: "Oficina principal",
  MALE: "Masculino",
  MARRIED: "Casado(a)",
  MERCHANT: "Comerciante",
  MERCHANT_RECORD: "Registro mercantil",
  MERCHANT_RECORD_NUMBER: "Número de registro mercantil",
  MERCHANT_RECORD_NUMBER_EXPIRATION_DATE:
    "Fecha de vencimiento de registro mercantil",
  METALWORKING: "Metalurgia",
  MEMBERS_ADMISSION_FORM: "Formulario de admisión de socio",
  MID: "Media",
  MINIMUM_BALANCE: "Balance mínimo",
  MINIMUM_QUOTA: "Cuota mínima",
  MISCELLANEOUS: "Miscelaneo",
  MISTER: "Señor",
  MISTRESS: "Señora",
  MODIFICATION_DATE: "Fecha de modificación",
  MONTHLY: "Mensual",
  MONTHS: "Meses",
  MORE_100: "Más de RD$100 mil",
  MORE_120: "Más de RD$120 mil",
  MORE_200: "Más de RD$200 mil",
  MORE_4000_MM: "Más de RD$4000 millones",
  MORTGAGED: "Hipotecada",
  MOTHER: "Madre",
  MOTOR_VEHICLE: "Vehículo de Motor",
  NAME: "Nombre",
  NATIONAL: "Cédula",
  NATIONALITIES: "Nacionalidades",
  NATIONALITY: "Nacionalidad",
  NATIONALITY_DEFAULT_SECTION_MESSAGE:
    "Seleccione una nacionalidad para empezar.",
  NEW: "Nuevo",
  NEW_ACCOUNT_REQUEST: "Nueva solicitud de cuenta",
  NEW_ACF: "Nuevo formulario de conocimiento de Compañia",
  NEW_ADDENDUM_CONTRACT: "Nuevo adendum a contrato de prestamo",
  NEW_APF: "Nuevo formulario de conocimiento de persona",
  NEW_APRF: "Nuevo formulario de conocimiento de proveedor",
  NEW_ATTORNEY: "Nuevo abogado",
  NEW_BANK: "Nuevo Banco",
  NEW_BANK_ACCOUNT: "Nueva cuenta de banco",
  NEW_CASH: "Nuevo Efectivo",
  NEW_CHECK: "Nuevo Cheque",
  NEW_CLIENT: "Socio nuevo",
  NEW_CLIENT_PENDING_APROVAL: "Socio nuevo, pendiente de aprobación",
  NEW_COMPANY: "Nueva compañía",
  NEW_COMPANY_REPRESENTATIVE: "Nuevo representante de compañía",
  NEW_CONTACT: "Nuevo contacto",
  NEW_CONTRACT: "Nuevo contrato",
  NEW_CREDITOR: "Nuevo acreedor",
  NEW_CREDIT_LINE_AMOUNT: "Nueva cantidad de crêdito",
  NEW_CREDIT_REVIEW_AUTHORIZATION: "Nueva autorización de revisión crediticia",
  NEW_DEBIT_AUTHORIZATION: "Nueva autorización de débito",
  NEW_INSTITUTION: "Nueva institución",
  NEW_INTEREST_RATE: "Nueva tasa de interés",
  NEW_LOAN_CONTRACT: "Nuevo contrato de préstamo",
  NEW_MEMBERSHIP_APPLICATION: "Nueva solicitud de admisión de socio",
  NEW_NOTARIAL_PAY_CONTRACT: "Nuevo pago notarial",
  NEW_OCCUPATION: "Nueva Ocupacion",
  NEW_PARTICIPANT: "Nuevo participante",
  NEW_PERSON: "Nueva persona",
  NEW_PHONE: "Nuevo número telefónico",
  NEW_PROFESSION: "Nueva Profesion",
  NEW_PROVIDER: "Nuevo proveedor",
  NEW_REQUEST: "Nueva solicitud",
  NEW_ÁUTOMATIC_DEBIT_AUTHORIZATION: "Nueva autorización de débito automático",
  NEXT: "Siguiente",
  NICKNAME: "Apodo",
  NON_APPLICABLE: "N/A",
  NORTH_AMERICAN: "Norteamericano",
  NOTARIAL_AMOUNT: "Monto notarial",
  NOTARIAL_PAY: "Pagare notarial",
  NOTARIAL_PAY_CONTRACT: "Contrato de pagare notarial",
  NOTARIAL_PAY_CONTRACT_FAULT_SECTION_MESSAGE:
    "Seleccione un contrato de pagare notarial para empezar.",
  NOTARIAL_PAY_TYPE: "Tipo de pagare notarial",
  NOT_FOUND: "No hay resultados",
  NOT_OWN: "No propio",
  OCCUPATION: "Ocupación",
  OCCUPATIONS: "Ocupaciones",
  OCCUPATION_DEFAULT_SECTION_MESSAGE: "Seleccione una ocupacion para empezar.",
  OCTOBER_BONUS_1: "Aniversario I",
  OCTOBER_BONUS_2: "Aniversario II",
  OFFICE: "Oficina",
  ON_PROCESS: "En proceso",
  ON_TRANSIT: "En tránsito",
  OPENING_DATE: "Fecha de apertura",
  OPEN_NEW_ON_SAVE: "Abrir uno nuevo al guardar",
  OPERATION_TYPES: "Tipos de Operación",
  ORIGINAL_AMOUNT: "Monto original",
  ORIGIN_ACCOUNT: "Cuenta de Origen",
  OTHERS: "Otros",
  OTHER_FEES: "Otros Gastos",
  OUTLAY: "Desembolso",
  OUTLAY_CASH_DEFAULT_SECTION_MESSAGE:
    "Seleccione una entrada de efectivo para empezar.",
  OUTLAY_CHECK_DEFAULT_SECTION_MESSAGE: "Seleccione un cheque para empezar.",
  OUTLAY_CHECK_STATUS_ARCHIVED: "Archivado",
  OUTLAY_CHECK_STATUS_CANCELLED: "Cancelado",
  OUTLAY_CHECK_STATUS_COMPLETED: "Completado",
  OUTLAY_CHECK_STATUS_IN_TRANSIT: "En Transito",
  OUTLAY_CHECK_STATUS_PENDING_SIGNATURE: "Pendiente de firma",
  OUTLAY_CHECK_STATUS_RETURNED: "Devuelto",
  OUTLAY_COMMISSION_RATE: "Tasa de comisión de desembolso",
  OUTLAY_DATA_ADQUISITION: "Adquisicion de datos de desembolso",
  OUTLAY_DEFAULT_SECTION_MESSAGE: "Seleccione un desembolso para empezar.",
  OUTLAY_EXISTING_CLIENT: "Desembolso",
  OUTLAY_NEW_CLIENT: "Desembolso (Socio nuevo)",
  OUTLAY_STATUS_CANCELLED: "Cancelado",
  OUTLAY_STATUS_ON_PROCESS: "En Proceso",
  OUTLAY_STATUS_PROCESSED: "Procesado",
  OUTLAY_STATUS_RECONCILED: "Conciliado",
  OUTLAY_STATUS_VALIDATED: "Validado",
  OUTLAY_NEW_PAYMENT_TICKET: "Pago",
  OWN: "Propia",
  OWNER: "Propietario",
  OWNER_PARTNER: "Propietario / Socio",
  PAID: "Pagado",
  PAID_OUT: "Saldado",
  PARENT_TICKET: "Ticket padre",
  PARTICIPANT: "Participante",
  PARTICIPANTS: "Participantes",
  PARTICIPANT_TYPE: "Tipo de participante",
  PARTNER: "Socio",
  PARTNERS: "Socios",
  PARTNER_EVALUATION_PENDING: "Evaluación de socio pendiente",
  PASSPORT: "Pasaporte",
  PASSWORD: "Contraseña",
  PAYMENT_DAY: "Dia de pago",
  PAYMENT_FREQUENCY: "Frecuencia de Pago",
  PAYMENT_METHOD: "Método de pago",
  PAYMENT_PENDING: "Pago pendiente",
  PAYMENT_PROCESSING: "Proceso de pago",
  PAYMENT_RECEIPT: "Recibo de pago",
  PAYMENT_SETTINGS: "Configuración de Pago",
  PAYMET_CAPACITY: "Capacidad de pago",
  PAYMENT_VIEW: "Pagos",
  PAYROLL: "Descuento por Nomina",
  PAYMET_TYPE: "Tipo de pago",
  PAYROLL_CARD: "Tarjeta de nomina",
  PAYROLL_DEDUCTION_CONTRACT: "Contrato de Descuento por Nomina",
  PAY_DAY: "Día de Pago",
  PENDING_APPROVAL: "Pendiente de aprobacón",
  PENDING_LEGALIZATION: "Pendiente de legalización",
  PENDING_SIGNATURE: "Firma pendiente",
  PERIODICITY: "Periodicidad",
  PERSON: "Persona",
  PCWD: "Contrato de préstamo con prenda sin desapoderamiento",
  PERSONAL_LOAN: "Prestamo personal",
  PETROLEUM: "Petroleo",
  PHARMACEUTICS: "Farmacéutica",
  PHONE: "Teléfono",
  PHONE_COMPANY: "Compañía de teléfono",
  PHONE_NUMBER: "Número telefonico",
  PHYSICAL: "Físico",
  PLANNED_SAVINGS: "Ahorros Planificados",
  PLEASE_SELECT_AT_LEAST:
    "Seleccione al menos un acreedor, un abogado y un deudor.",
  POSITION: "Posición",
  POSTPONED: "Postergadas",
  POSTPONED_2: "Postergados",
  PREVIOUS_QUESTIONS_VALID_DEBTOR_RELATIVES:
    "Las preguntas anteriores son válidas para familiares deudores?",
  PRIMARY_INCOME_MONTHLY_ACTIVITY: "Actividad mensual de ingresos primarios",
  PRIORITY: "Prioridad",
  PRIVATE: "Privadas",
  PRIVATE_SECTOR: "Sector Privado",
  PROCESS: "Procesar",
  PROCESSED: "Procesado",
  PROCESSING: "Procesando",
  PRODUCT_TYPE: "Tipo de Producto",
  PROFESSION: "Profesion",
  PROFESSIONS: "Profesiones",
  PROFESSION_DEFAULT_SECTION_MESSAGE: "Seleccione una profesion para empezar.",
  PROGRAMMED: "Programado",
  PROPERTY: "Immobiliaria",
  PROPORTION: "Proporción",
  PROVIDER: "Proveedor",
  PROVIDERS: "Proveedores",
  PROVIDER_DEFAULT_SECTION_MESSAGE: "Seleccione un proveedor para empezar.",
  PROVINCE: "Provincia",
  PUBLIC: "Públicas",
  PUBLIC_SECTOR: "Sector Público",
  PUERTO_RICAN: "Puertorriqueño",
  PURPOSE_OF_THE_ACCOUNT_TO_BE_OPENED: "Propósito de cuenta a aperturar",
  QUARTER: "Cuatrimestral",
  QUARTERLY: "Trismestral",
  QUESTION: "Pregunta",
  QUOTA: "Cuota",
  QUOTATION: "Cotización",
  QUOTATIONS: "Cotizaciones",
  QUOTATION_DATE: "Fecha Cotización",
  QUOTATION_NUMBER: "Número de Cotización",
  QUOTA_REDUCTION: "Reduccion de Cuotas",
  REAL_STATE_PROPERTY: "Propiedad Inmobiliaria",
  REENTRY_DATE: "Fecha de Reingreso",
  REFERENCE: "Referencia",
  REFERENCE_NUMBER: "No. Referencia",
  REFUNDED: "Reembolsado",
  REGION: "Región",
  REGISTRATION_PENDING: "Registro pendiente",
  REGISTRY: "RNC",
  REGISTRY_END_DATE: "Fecha de vencimiento de registro",
  REGULAR_LOAN: "Prestamo regular",
  RELATIONSHIP: "Parentesco",
  RENTAL_MANAGMENT: "Gestión de alquiler",
  RENTED: "Alquilada",
  REPORT_PENDING: "Reporte pendiente",
  REPROGRAMMING: "Reprogramación",
  REQUEST_AMOUNT: "Monto Solicitado",
  RESET: "Limpiar",
  RETIRED: "Jubilado",
  RETIRED_PENSIONER: "Jubilado / Pensionado",
  REVIEWER: "Supervisor",
  REVIEW_PENDING: "Revision pendiente",
  RNC: "RNC",
  SALARY: "Salario",
  SALARY_ADVANCE: "Ordinario expreso",
  SAVE: "Guardar",
  SAVING: "Ahorro",
  SAVINGS_ACCOUNT: "Cuenta de ahorro",
  SAVINGS_ACCOUNT_CONTRACTS: "Solicitud de cuentas de ahorro",
  SAVING_BOOK: "Libreta de ahorro",
  SCHOOL_SUPPLIES: "Bono COOP",
  SEARCH: "Búsqueda",
  SEARCH_BY: "Buscar por",
  SEARCH_FILTERS: "Filtros de busqueda",
  SECONDARY_INCOME_ACTIVITY: "Actividad mensual de ingresos secundarios",
  SECONDARY_INCOME_AVERAGE: "Promedio de ingresos secundarios",
  SECRET_QUESTIONS: "Preguntas secretas",
  SECTOR: "Sector",
  SECURITY_INFORMATION: "Información de seguridad",
  SELECT_NONE: "Seleccione",
  SERVICES: "Servicios",
  SERVICE_USER_AFFILIATE: "Socio Usuario de Servicio",
  SETTING: "Configuración",
  SETTLEMENT_FORM_HANDLING: "Manejo de formularios de liquidación",
  SHARE_HOLDER: "Accionista",
  SIGNED: "Firmado",
  SIMPLE_LOAN: "Ordinario Simple",
  SINGLE: "Soltero(a)",
  SINGLE_PAYMENT: "Al Vencimiento",
  SISTER: "Hermana",
  SITUATION: "Situación",
  SPECIAL_REGIME: "Regimen Especial - B14",
  STAKE_HOLDERS: "Partes interesadas",
  STATUS: "Estado",
  SUBSIDIARY: "Sucursal",
  SUCCESS: "Éxito",
  SUCCESSFULLY: "éxitosamente",
  SYSTEM_NAME: "Cooperativa Barcelona",
  TAX_VALUE: "Valor Fiscal - B01",
  TELECOMMUNICATION: "Telecomunicaciones",
  TERM: "Plazo",
  TERM_REDUCTION: "Reduccion de Plazo",
  TEXTIL: "Textil",
  THIRD_PARTY_TYPE: "Tipo de terceros",
  TICKET: "Ticket",
  TICKET_NUMBER: "Número de ticket",
  TICKET_SUMMARY: "Resumen de tickets",
  TICKET_TYPE: "Tipo de ticket",
  TIME_LIMIT: "Plazo",
  TITLE: "Titulo",
  TO: "Hasta",
  TOURISM: "Turismo",
  TRADE_NAME: "Nombre comercial",
  TRANSACTIONAL_PASSWORD: "Contraseña transaccional",
  TRANSACTIONS: "Transacciones",
  TRANSACTION_CLASS: "Clasificación de la transacción",
  TRANSACTION_DATE: "Fecha Transacción",
  TRANSACTION_PENDING: "Transacción pendiente",
  TRANSACTION_REFERENCE: "Referencia de transacción",
  TRANSACTION_STATUS: "Estado de la transacción",
  TRANSACTION_TYPE: "Tipo de Transacción",
  TRANSFER_AMOUNT: "Monto a transferir",
  TRANSFER_CODE_CARD: "Tarjeta de códigos de transferencia",
  TRANSFER_OR_DEPOSIT: "Transferencia o depósito",
  TRANSPORTATION: "Transportación",
  TYPE: "Tipo",
  UNCLE: "Tio",
  UPDATED: "Actualizado",
  UPDATE_TICKET: "Actualizar Ticket",
  USD: "USD",
  USED: "En Uso",
  USER_NAME: "Usuario",
  VACATIONAL_BONUS: "Bono vacacional (MH y BCRD)",
  VEHICLE: "Vehícular",
  VEHICLE_BRAND: "Marca del  vehículo",
  VEHICLE_COLOR: "Color del vehículo",
  VEHICLE_FINANCIAL_STATUS: "Estado financiero del vehículo",
  VEHICLE_MODEL: "Modelo del vehículo",
  VEHICLE_YEAR: "Año del vehículo",
  VERIFICATION_PENDING: "Verificacion pendiente",
  WARRANT: "Garante",
  WARRANTIES: "Garantías",
  WARRANTY: "Garantía",
  WARRANTY_CLASSIFICATION: "Clasificación de garantía",
  WARRANTY_DEFAULT_SECTION_MESSAGE: "Seleccione una garantía para empezar.",
  WARRANTY_TYPES: "Tipos de garantía",
  WEBSITE: "Pagina web",
  WEEKLY: "Semanal",
  WEEK_AFTER: "Bisemanal",
  WIDOWED: "Viudo(a)",
  WIFE: "Cónyuge",
  WITHDRAWAL_DAY: "Día de Retiro",
  WITHDRAWAL_FAILED: "Retiro fallido",
  WITHDRAWAL_PENDING: "Retiro pendiente",
  WITHDRAWAL_PIN: "Pin de Retiro",
  WITNESSES: "Testigos",
  WORK: "Trabajo",
  WORKING_CAPITAL: "Capital de trabajo",
  WORK_PHONE: "Teléfono del trabajo",
  WORK_PHONE_EXTENSION: "Extensión del trabajo",
  WORK_PROFILE: "Perfil de trabajo",
  ZIP_CODE: "Código Postal",
  CHILDREN: "Hijo/a",
  INSTITTUTION_AFFILIATE: "Afiliado",
  INSTITUTION_NOT_AFFILIATE: "No afiliado",
  INSTITUTION_RETIRED: "Retirado",
};

export const institutionStatus = [
  { value: "affiliate", label: translations.INSTITTUTION_AFFILIATE },
  { value: "not affiliate", label: translations.INSTITUTION_NOT_AFFILIATE },
  { value: "retired", label: translations.INSTITUTION_RETIRED },
  { value: "prospect", label: "Prospecto Feria" },
];

export const nationalitiesOptions: IOptionType[] = [
  { label: translations.DOMINICAN, value: "dominican" },
  { label: translations.NORTH_AMERICAN, value: translations.NORTH_AMERICAN },
  { label: translations.PUERTO_RICAN, value: translations.PUERTO_RICAN },
];

export function createErrorObject(data: IDataErrors, context: any) {
  let errorObject = {};
  for (const prop in data) {
    /* For take every key on data object and assig to prop,
        then determines if each prop is in data*/
    if (data.hasOwnProperty(prop)) {
      if (typeof data[prop] === "object" && !Array.isArray(data[prop])) {
        errorObject = {
          ...errorObject,
          ...createErrorObject(data[prop] as IDataErrors, context),
        };
      } else if ("mainAddress" + titleCaseHandler(prop) in context) {
        errorObject["mainAddress" + titleCaseHandler(prop) + "Error"] =
          data[prop][0];
      } else if (prop in context) {
        errorObject[prop + "Error"] = data[prop][0];
      } else if (Array.isArray(data[prop])) {
        errorObject = data[prop][0];
      } else {
        errorObject = data[prop];
      }
    }
  }
  return errorObject;
}

export function catchError(err: AxiosError, context: React.Component<{}, {}>) {
  let errors: IErrorsObject = {};
  if (err.response && [400, 403].includes(err.response.status)) {
    errors = createErrorObject(err.response.data, context.state);
    if (typeof errors === "object") {
      // if errors is an object, errors key would match context state and spread
      // the new values on matched fields and display the message on the speciifc field
      context.setState({
        dialog: true,
        dialogTitle: "Error",
        errorMessage: translations.DEFAULT_ERROR_MESSAGE,
        ...errors,
        loading: false,
      });
    } else {
      context.setState({
        //  in case the error is not a object display error message on modal
        dialog: true,
        dialogTitle: "Error",
        errorMessage: `Favor Verificar: ${errors}`,
        loading: false,
      });
    }
  } else {
    //  default message if other condition dont work
    context.setState({
      dialog: true,
      dialogTitle: "Error",
      errorMessage: translations.DEFAULT_ERROR_MESSAGE,
      loading: false,
    });
  }
}

export function resetError(context: React.Component) {
  for (const key in context.state) {
    if (context.state.hasOwnProperty(key)) {
      context.setState({
        [key + "Error"]: "",
        errors: {},
      });
    }
  }
}

export function successHandler(
  res: AxiosResponse,
  context: React.Component,
  key: string
) {
  if (res.status === 200 && res.config.method === "patch") {
    context.setState({
      dialog: true,
      dialogTitle: translations.SUCCESS,
      errorMessage: `${key} ${translations.UPDATED}`,
      loading: false,
    });
  } else if (res.status === 201 && res.config.method === "post") {
    context.setState({
      dialog: true,
      dialogTitle: translations.SUCCESS,
      errorMessage: `${key} ${translations.CREATED}`,
      loading: false,
    });
  } else if (res.status === 200 && res.config.responseType === "blob") {
    context.setState({
      dialog: true,
      dialogTitle: translations.SUCCESS,
      errorMessage: `${key} ${translations.SUCCESSFULLY}`,
      loading: false,
    });
  } else {
    context.setState({
      dialog: true,
      dialogTitle: translations.SUCCESS,
      errorMessage: `${key} ${translations.UPDATED}`,
      loading: false,
    });
  }
}

export function formatDayFirst(date: string) {
  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
}

export async function filesGeneration(url: any, token: string) {
  try {
    const response = await http.get(`${url}`, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "text/html",
      },
    });

    const file = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const fileURL = URL.createObjectURL(file);

    // Verificar si NewWindow es null antes de usarlo
    const NewWindow = window.open();
    if (NewWindow) {
      NewWindow.location.href = fileURL;
    } else {
      console.error("No se pudo abrir una nueva ventana.");
    }
  } catch (error) {
    console.error(error);
  }
}

export const getRiskMatrix = async (
  unique_id: string,
  is_lead: boolean,
  token: string,
  fullName: string
) => {
  try {
    const params = {
      participant_unique_id: unique_id,
      is_lead: is_lead,
    };
    const response = await generateRiskMatrix(token, params);

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = `matrizderiesgo-${fullName}.xlsm`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error("Error al generar matriz de riesgo: ", error);
  }
};

export async function debtorsValidation(
  context: any,
  token: string,
  id: string[],
  isContract: boolean,
  needFinan: boolean = false
) {
  let participants: any = [];
  if (isContract) {
    participants = await (
      await fetchLoanContract(token, id[0]).then(
        (res) => res.data as ILoanContract
      )
    ).debtors;
  } else {
    participants = id;
  }

  for (const f of participants) {
    const debtor = await fetchParticipant(token, f).then(
      (res) => res.data as IParticipant
    );
    if (
      participants
        ? participants.length > 1 && debtor.civilStatus === "married"
        : false
    ) {
      context.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: `Si ${debtor.fullName} está casado(a) con el otro deudor,
                remover el otro deudor, de lo contrario el contrato no puede proceder`,
        loading: false,
      });
      return false;
    } else if (
      debtor.hasOwnProperty("workProfileObj") &&
      debtor.workProfileObj
    ) {
      if (
        !needFinan ||
        (debtor.hasOwnProperty("financialProfileObj") &&
          debtor.financialProfileObj)
      ) {
        continue;
      } else {
        context.setState({
          dialog: true,
          dialogTitle: "Disculpe",
          errorMessage: `${debtor.fullName} no dispone de perfil de financiero.`,
          loading: false,
        });
        return false;
      }
    } else {
      context.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: `${debtor.fullName} no dispone de perfil de trabajo.`,
        loading: false,
      });
      return false;
    }
  }
  return true;
}

export function jsonToQuestionArray(json: {
  [key: string]: string;
}): IQuestion[] {
  try {
    const array: IQuestion[] = [];

    for (const prop in json) {
      if (json.hasOwnProperty(prop)) {
        array.push({ question: prop, answer: json[prop] });
      }
    }

    return array;
  } catch (err) {
    return [];
  }
}

export function questionArrayToJson(
  questions: IQuestion[]
): { [key: string]: string } {
  if (questions.length === 0) {
    return {};
  }
  const obj: { [key: string]: string } = {};

  questions.forEach((q) => {
    obj[q.question] = q.answer;
  });

  return obj;
}

export function jsonToCommercialReferenceArray(json: {
  [key: string]: any;
}): ICommercialReferences[] {
  try {
    const array: ICommercialReferences[] = [];

    for (const prop in json) {
      if (json.hasOwnProperty(prop)) {
        array.push({
          company: json[prop].company,
          phoneNumber: json[prop].phoneNumber,
        });
      }
    }

    return array;
  } catch (err) {
    return [];
  }
}
export function commercialReferenceArrayToJson(
  commercialReferences: ICommercialReferences[]
): { [key: string]: ICommercialReferences } {
  if (commercialReferences.length === 0) {
    return {};
  }
  const obj: { [key: string]: ICommercialReferences } = {};

  commercialReferences.forEach((q, index) => {
    obj[index] = q;
  });

  return obj;
}

export function jsonToShareholderArray(json: {
  [key: string]: any;
}): IShareholder[] {
  try {
    const array: IShareholder[] = [];

    for (const prop in json) {
      if (json.hasOwnProperty(prop)) {
        array.push({
          identificationType: json[prop].identificationType,
          identificationNumber: json[prop].identificationNumber,
          fullName: json[prop].fullName,
          position: json[prop].position,
          percentageOfParticipation: json[prop].percentageOfParticipation,
        });
      }
    }

    return array;
  } catch (err) {
    return [];
  }
}

export function shareholderArrayToJson(
  shareholders: IShareholder[]
): { [key: string]: IShareholder } {
  if (shareholders.length === 0) {
    return {};
  }
  const obj: { [key: string]: IShareholder } = {};

  shareholders.forEach((q, index) => {
    obj[index] = q;
  });

  return obj;
}

export function jsonToStakeHolderArray(json: {
  [key: string]: any;
}): IStakeholder[] {
  try {
    const array: IStakeholder[] = [];
    for (const prop in json) {
      if (json.hasOwnProperty(prop)) {
        array.push({
          full_name:
            typeof parseInt(prop) === "number" ? json[prop].fullName : prop,
          identification_number: json[prop].identificationNumber,
          identification_type: json[prop].identificationType,
          phoneNumber: json[prop].phoneNumber,
          relationship: json[prop].relationship,
          proportion: json[prop].proportion,
        });
      }
    }

    return array;
  } catch (err) {
    return [];
  }
}

export function stakeHolderArrayToJson(
  stakeholders: IStakeholder[]
): { [key: string]: IStakeholder } {
  if (stakeholders.length === 0) {
    return {};
  }
  const obj: { [key: string]: IStakeholder } = {};

  stakeholders.forEach((q) => {
    obj[q.full_name] = q;
  });

  return obj;
}

export function toOptions(collection: Array<{ [key: string]: string }>) {
  return collection.map((item) => ({
    label: item.name,
    value: item.uniqueId,
  }));
}

export function nationalitiesToOptions(
  collection: Array<{ [key: string]: string }>
) {
  const data = collection["results"] ? collection["results"] : collection;
  return data.map((item: { denomynForMale: any; name: any }) => ({
    label: item.denomynForMale,
    value: item.name,
  }));
}

// handle that convert to title case else return Null
export function titleCaseHandler(props: string) {
  return props
    ? props.replace(/\w\S*/g, (txt) => {
        return txt[0].toUpperCase() + txt.slice(1).toLowerCase();
      })
    : props;
}

export function capitalizeCaseHandler(props: string) {
  return props ? props[0].toUpperCase() + props.slice(1).toLowerCase() : props;
}

export const destination = [
  { value: translations.WORKING_CAPITAL },
  { value: translations.CONSUMER_FINANCING },
  { value: translations.COLLEGE_CREDIT },
  { value: translations.DEBT_REFINANCING },
];
export const creditMethod = [
  { value: "cash", label: translations.CASH },
  { value: "internet_banking", label: translations.BANK_TRANSFER },
  { value: "check", label: translations.CHECK },
];
export const financialMethods = [
  { value: "internet_banking", label: translations.BANK_TRANSFER },
  { value: "check", label: translations.CHECK },
  { value: "payroll_card", label: translations.PAYROLL_CARD },
  { value: "flier", label: translations.FLIER },
  { value: "payroll", label: translations.PAYROLL },
  { value: "cash", label: translations.CASH },
];

export const cardNetwork = [
  { value: "Visa", label: "Visa" },
  { value: "Mastercard", label: "Mastercard" },
  { value: "American_Express", label: "American Express" },
  { value: "Discover", label: "Discover" },
];

export const cardType = [
  { value: "Debit", label: "Debito" },
  { value: "Credit", label: "Crédito" },
];

export const transactionType = [
  { value: "Debit", name: "Debito" },
  { value: "Credit", name: "Crédito" },
];

export const gender = [
  { value: "m", label: translations.MALE },
  { value: "f", label: translations.FEMALE },
  { value: "l", label: translations.LEGAL },
];

export const affiliateState = [
  { value: "affiliate", label: translations.AFFILIATE_STATE_AFFILIATED },
  { value: "unaffiliated", label: translations.AFFILIATE_STATE_UNAFFILIATED },
  { value: "retired", label: translations.AFFILIATE_STATE_RETIRED },
  { value: "liquidated", label: translations.AFFILIATE_STATE_LIQUIDATED },
  { value: "passed_away", label: translations.AFFILIATE_STATE_PASSED_AWAY },
  { value: "not_affiliate", label: translations.AFFILIATE_STATE_NOT_AFFILIATE },
];

export const civilStatus = [
  { value: "single", label: translations.SINGLE },
  { value: "married", label: translations.MARRIED },
  // {value: "widowed",label: translations.WIDOWED },
  // {value: "divorced",label: translations.DIVORCED },
  // {value: "free_union",label: translations.FREE_UNION },
];

export const participantType = [
  { value: "client", label: translations.CLIENT },
  { value: "attorney", label: translations.ATTORNEY },
  {
    value: "company representative",
    label: translations.COMPANY_REPRESENTATIVE,
  },
];

export const paymentFrequency = [
  { value: "weekly", label: translations.WEEKLY },
  { value: "every week after", label: translations.WEEK_AFTER },
  { value: "biweekly", label: translations.BIWEEKLY },
  { value: "monthly", label: translations.MONTHLY },
  { value: "bimonthly", label: translations.BIMONTHLY },
  { value: "quarterly", label: translations.QUARTERLY },
  { value: "quarter", label: translations.QUARTER },
  { value: "semiannual", label: translations.BIANNUAL },
  { value: "annually", label: translations.ANNUALLY },
  { value: "due_date", label: translations.DUE_DATE },
];

export const daysOfTheMonth = [
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
  { value: "21" },
  { value: "22" },
  { value: "23" },
  { value: "24" },
  { value: "25" },
  { value: "26" },
  { value: "27" },
  { value: "28" },
  { value: "29" },
  { value: "30" },
  { value: "31" },
];

export const dwellingTypes = [
  { value: "pending_dwelling", label: "Pendiente de Actualizacion" },
  { value: "own_dwelling", label: translations.OWN },
  { value: "family_dwelling", label: translations.FAMILIAR },
  { value: "rented_dwelling", label: translations.RENTED },
  { value: "mortgaged_dwelling", label: translations.MORTGAGED },
];

export const educationLevel = [
  { value: "none", label: "Ninguno" },
  { value: "elementary", label: translations.EDUCATION_LEVEL_ELEMENTARY },
  { value: "highschool", label: translations.EDUCATION_LEVEL_HIGHSCHOOL },
  { value: "bachelor", label: translations.EDUCATION_LEVEL_BACHELOR },
  { value: "postgraduated", label: translations.EDUCATION_LEVEL_POSTGRADUATED },
  { value: "doctor", label: translations.EDUCATION_LEVEL_DOCTOR },
];

export const affiliateType = [
  { value: "ensures_affiliate", label: translations.ENSURES_AFFILIATE },
  { value: "full_affiliate", label: translations.FULL_AFFILIATE },
  {
    value: "service_user_affiliate",
    label: translations.SERVICE_USER_AFFILIATE,
  },
  { value: "corporative_affiliate", label: translations.CORPORATIVE_AFFILIATE },
];

export const prefix = [
  { value: "mister", label: translations.MISTER },
  { value: "mistress", label: translations.MISTRESS },
  { value: "graduate", label: translations.GRADUATE },
  { value: "doctor", label: translations.DOCTOR },
];

export const transactionClass = [
  {
    value: "regular_loan",
    label: translations.REGULAR_LOAN,
    rohi: "PRESTAMO REGULAR",
  },
  {
    value: "advance_purchases",
    label: translations.ADVANCE_PURCHASES,
    rohi: "COMPRA ANTICIPADA DE BONOS ",
  },
];

export const activityTypeOptions = [
  { value: "agricultural", label: translations.AGRICULTURAL },
  { value: "computing", label: translations.COMPUTING },
  { value: "gastronomy", label: translations.GASTRONOMY },
  { value: "cements", label: translations.CEMENTS },
  { value: "transportation", label: translations.TRANSPORTATION },
  { value: "petroleum", label: translations.PETROLEUM },
  { value: "education", label: translations.EDUCATION },
  { value: "telecommunication", label: translations.TELECOMMUNICATION },
  { value: "pharmaceutics", label: translations.PHARMACEUTICS },
  { value: "healthcare", label: translations.HEALTHCARE },
  { value: "textil", label: translations.TEXTIL },
  { value: "metalworking", label: translations.METALWORKING },
  { value: "building", label: translations.BUILDING },
  { value: "tourism", label: translations.TOURISM },
  { value: "chemistry", label: translations.CHEMISTRY },
  { value: "another", label: translations.ANOTHER },
];
export const anualPrimaryIncomeOptions = [
  { value: "less than RD$20 millions", label: translations.LESS_20_MM },
  {
    value: "from RD$20 to RD$100 millions",
    label: translations.FROM_20_TO_100_MM,
  },
  {
    value: "from RD$100 to RD$200 millions",
    label: translations.FROM_100_TO_200_MM,
  },
  {
    value: "from RD$200 to RD$1000 millions",
    label: translations.FROM_200_TO_1000_MM,
  },
  {
    value: "from RD$1000 to 4000 millions",
    label: translations.FROM_1000_TO_4000_MM,
  },
  { value: "more than RD$4000 millions", label: translations.MORE_4000_MM },
];

export const transactionTypeAP = [
  { value: "vacational_bonus", label: translations.VACATIONAL_BONUS },
  { value: "antiquity_incentive", label: translations.ANTIQUITY_INCENTIVE },
  { value: "antiquity_bonus", label: translations.ANTIQUITY_BONUS },
  { value: "october_bonus_1", label: translations.OCTOBER_BONUS_1 },
  { value: "october_bonus_2", label: translations.OCTOBER_BONUS_2 },
  { value: "easter_royalty", label: translations.EASTER_ROYALTY },
  // { value: 'personal_loan', label: translations.PERSONAL_LOAN },
  { value: "school_supplies", label: translations.SCHOOL_SUPPLIES },
  { value: "irregular_bonus", label: translations.IRREGULAR_BONUS },
  { value: "anniversary_bonus", label: translations.ANNIVERSARY_BONUS },
  { value: "annual_bonus", label: translations.ANNUAL_BONUS },
];
export const availabilityTypes = [
  { value: "regular_loan", label: translations.REGULAR_LOAN },
  { value: "vacational_bonus", label: translations.VACATIONAL_BONUS },
  { value: "antiquity_incentive", label: translations.ANTIQUITY_INCENTIVE },
  { value: "antiquity_bonus", label: translations.ANTIQUITY_BONUS },
  { value: "october_bonus_1", label: translations.OCTOBER_BONUS_1 },
  { value: "october_bonus_2", label: translations.OCTOBER_BONUS_2 },
  { value: "annual_bonus", label: translations.ANNUAL_BONUS },
  { value: "easter_royalty", label: translations.EASTER_ROYALTY },
  // { value: 'personal_loan', label: translations.PERSONAL_LOAN },
  { value: "school_supplies", label: translations.SCHOOL_SUPPLIES },
  { value: "anniversary_bonus", label: translations.ANNIVERSARY_BONUS },
  { value: "irregular_bonus", label: translations.IRREGULAR_BONUS },
];

export const transactionTypeRL = [
  { value: "simple_loan", label: translations.SIMPLE_LOAN },
  { value: "installment_loan", label: translations.INSTALLMENT_LOAN },
  { value: "salary_advance", label: translations.SALARY_ADVANCE },
];

export const IDENTIFICATION_TYPE = [
  { value: "national", label: translations.NATIONAL },
  { value: "passport", label: translations.PASSPORT },
  { value: "RNC", label: translations.RNC },
];
export const pepsStatus = [
  { value: "undefined", label: "Sin definir" },
  { value: "passed", label: "Aprobado" },
  { value: "rejected", label: "Rechazado" },
];
export const personTypes = [
  { value: "legal", label: "Juridica" },
  { value: "physical", label: "Fisica" },
];
export const priority = [
  { value: "low", label: "Baja" },
  { value: "mid", label: "Media" },
  { value: "high", label: "Alta" },
];

export const clientTypes = [
  { value: "regular", label: "Socio" },
  { value: "shareholder", label: "Accionista" },
  { value: "employee", label: "Empleado" },
  { value: "new regular", label: "Nuevo Socio" },
];

export const administrativeExpensesType = [
  // { value: 'capitalized', label: translations.CAPITALIZED },
  { value: "discounted", label: translations.DISCOUNTED },
  { value: "condonated", label: translations.CONDONED },
  // { value: 'postponed', label: translations.POSTPONED },
];

export const blacklistReasons = [
  { value: "weakCreditHistory", label: "Historial de crédito Debil" },
  {
    value: "insufficientDebtCapacity",
    label: "Capacidad de Endeudamiento Insuficiente",
  },
  {
    value: "insufficientPaymentCapacity",
    label: "Capacidad de Pago Insuficiente",
  },
  { value: "highLevelIndebtedness", label: "Alto Nivel de Endeudamiento" },
  { value: "pendingDocumentation", label: "Pendiente de documentación" },
  {
    value: "paymentConfigurationPending",
    label: "Pendiente de configuración cobro",
  },
];

export const headersCM = [
  { name: translations.CREDIT_METHOD, prop: "creditMethod" },
  { name: translations.TRANSFER_AMOUNT, prop: "transferAmount" },
  { name: translations.DESTINATION_ACCOUNT, prop: "destinationAccount" },
  { name: translations.ORIGIN_ACCOUNT, prop: "originAccount" },
];

export const headersIB = [
  { name: translations.TRANSACTION_REFERENCE, prop: "transactionReference" },
  { name: "Tipo de transferencia", prop: "transferType" },
  { name: translations.OTHER_FEES, prop: "otherBankFees" },
];

export const fractionationType = [
  { value: "conventional", label: translations.CONVENTIONAL },
  // { value: 'capitalized', label: translations.CAPITALIZED_2 },
  // { value: 'discounted', label: translations.DISCOUNTED_2 },
  { value: "condonated", label: translations.CONDONED_2 },
  { value: "postponed", label: translations.POSTPONED_2 },
];

export const fiscalConfigTypes = [
  { value: "tax_value", label: translations.TAX_VALUE },
  { value: "final_consumer", label: translations.FINAL_CONSUMER },
  { value: "special_regime", label: translations.SPECIAL_REGIME },
  { value: "governmental", label: translations.GOVERNMENTAL },
];
export const ratingTypes = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
];

export const periodicity = [
  { value: "monthly", label: translations.MONTHLY, periods: 1 / 12, days: 30 },
  { value: "weekly", label: translations.WEEKLY, periods: 1 / 52, days: 7 },
  {
    value: "biweekly",
    label: translations.BIWEEKLY,
    periods: 1 / 26,
    days: 15,
  },
  {
    value: "bimonthly",
    label: translations.BIMONTHLY,
    periods: 1 / 6,
    days: 60,
  },
  {
    value: "quarterly",
    label: translations.QUARTERLY,
    periods: 1 / 4,
    days: 90,
  },
  {
    value: "biannual",
    label: translations.BIANNUAL,
    periods: 1 / 2,
    days: 180,
  },
  {
    value: "single_payment",
    label: translations.SINGLE_PAYMENT,
    periods: 1,
    days: 360,
  },
  { value: "annual", label: translations.ANNUALLY, periods: 1, days: 360 },
];

export const weekDays = [
  { value: "MONDAY", label: "Lunes" },
  { value: "TUESDAY", label: "Martes" },
  { value: "WEDNESDAY", label: "Miercoles" },
  { value: "THURSDAY", label: "Jueves" },
  { value: "FRIDAY", label: "Viernes" },
];

export const rohiDBList = [
  { value: "barcelona", label: "Cooperativa Barcelona" },
  // { value: 'ie', label: "Inversiones España" },
  // { value: 'mfs', label: "MFS" },
];

export const interestDecision = [
  { value: translations.DISCOUNTED, label: translations.DISCOUNTED },
  { value: translations.POSTPONED, label: translations.POSTPONED },
  { value: translations.CONDONED, label: translations.CONDONED },
  { value: translations.INCLUDED, label: translations.INCLUDED },
  { name: translations.ACCRUED, label: translations.ACCRUED },
];
export const transactionTypes = [
  { value: "simple_loan", label: "Ordinario Simple", rohi: "ORDINARIO SIMPLE" },
  {
    value: "constant_fees",
    label: "Escalonado constante",
    rohi: "ORDINARIO ESCALONADO",
  },
  {
    value: "decreasing_fees",
    label: "Escalonado decreciente",
    rohi: "ORDINARIO ESCALONADO",
  },
  {
    value: "vacational_bonus",
    label: "Bono vacacional (BCRD)",
    rohi: "BONO VACACIONAL (BCRD)",
  },
  {
    value: "vacational_bonus",
    label: "Bono vacacional (MH)",
    rohi: "BONO VACACIONAL (MH)",
  },
  {
    value: "antiquity_incentive",
    label: "Incentivo por antiguedad",
    rohi: "INCENTIVO POR ANTIGÜEDAD (BCRD)",
  },
  {
    value: "antiquity_bonus",
    label: "Premio por antiguedad",
    rohi: "PREMIO POR ANTIGÜEDAD (BCRD)",
  },
  {
    value: "october_bonus_1",
    label: "Aniversario I",
    rohi: "DE ANIVERSARIO I (BCRD)",
  },
  {
    value: "october_bonus_2",
    label: "Aniversario II",
    rohi: "DE ANIVERSARIO II (BCRD)",
  },
  { value: "easter_royalty", label: "NAVICOOP", rohi: "NAVICOOP BCRD" },
  // {value: 'personal_loan', label: 'Prestamo personal', rohi: "PRESTAMO PERSONAL"},
  { value: "school_supplies", label: "Bono COOP", rohi: "BONOCOOP (BCRD)" },
  {
    value: "irregular_bonus",
    label: "Préstamo irregular",
    rohi: "PRESTAMOS BONO IRREGULAR",
  },
  {
    value: "anniversary_bonus",
    label: "Bono Aniversario",
    rohi: "ANIVERSARIO (MH)",
  },
  {
    value: "annual_bonus",
    label: "Bonificacion Anual",
    rohi: "BONIFICACION ANUAL",
  },
  {
    value: "salary_advance",
    label: translations.SALARY_ADVANCE,
    rohi: "ORDINARIO EXPRESO",
  },

  // REVISION
  /* ORD. CONDICIONAL INMUEBLE
    PRESTAMO HIPOTECARIO
    PRESTAMOS DE VEHICULOS
    ORDINARIO ORDEN SERVICIO
    PRESTAMO DE EXCEPCION
    COMERCIALES LARGO PLAZO
    COMERCIALES CAPITAL DE TRABAJO
    GAR. LIQUIDA DPF
    GAR. LIQUIDA AHORROS */

  // { value: 'simple_loan', label: translations.SIMPLE_LOAN, rohi: "" },
  // { value: 'constant_fees', label: translations.CONSTANT_FEES_LOAN, rohi: "" },
  // { value: 'decreasing_fees', label: translations.DECREASING_FEES_LOAN, rohi: "" },
  // { value: 'salary_advance', label: translations.SALARY_ADVANCE, rohi: "" },
  // { value: 'vacational_bonus', label: translations.VACATIONAL_BONUS, rohi: "" },
  // { value: 'antiquity_incentive', label: translations.ANTIQUITY_INCENTIVE, rohi: "" },
  // { value: 'antiquity_bonus', label: translations.ANTIQUITY_BONUS, rohi: "" },
  // { value: 'october_bonus_1', label: translations.OCTOBER_BONUS_1, rohi: "" },
  // { value: 'october_bonus_2', label: translations.OCTOBER_BONUS_2, rohi: "" },
  // { value: 'easter_royalty', label: translations.EASTER_ROYALTY, rohi: "" },
  // { value: 'personal_loan', label: translations.PERSONAL_LOAN, rohi: "" },
  // { value: 'school_supplies', label: translations.SCHOOL_SUPPLIES, rohi: "" },
  // { value: 'irregular_bonus', label: translations.IRREGULAR_BONUS, rohi: "" },
  // { value: 'anniversary_bonus', label: translations.ANNIVERSARY_BONUS, rohi: "" },
  // { value: 'annual_bonus', label: translations.ANNUAL_BONUS, rohi: "" },
];

export const notarialTypes = [
  { label: "Garantía Solidaria" },
  { label: "Contrato de préstamo con prenda sin desapoderamiento" },
  { label: "Contrato de préstamo con garantía hipotecaria" },
  { label: "Contrato de préstamo condicional de inmueble" },
];
export const financialConfig = [
  { value: "justInterest", label: "Solo Interes", rohi: "SOLO INTERERES" },
  { value: "staggered", label: "Escalonada", rohi: "ESCALONADA" },
  { value: "toExpiration", label: "A Vencimiento", rohi: "A VENCIMIENTO" },
];
export const staggeredOptions = [
  { value: "constant_fees", label: translations.CONSTANT_FEES_LOAN },
  { value: "decreasing_fees", label: translations.DECREASING_FEES_LOAN },
];

export const termReductionOptions = [
  {
    name: "Metodología de Calculo",
    options: [
      { value: "quota_reduction", label: translations.QUOTA_REDUCTION },
      { value: "term_reduction", label: translations.TERM_REDUCTION },
    ],
    prop: "termReduction",
    type: "select",
  },
];

export const operatorList = [
  { value: "equal", label: "Igual" },
  { value: "between", label: "Entre" },
  { value: "greater_than_equal", label: "Mayor o igual" },
  { value: "low_than_equal", label: "Menor o igual" },
];

export const termReduction = [
  { value: "quota_reduction", label: translations.QUOTA_REDUCTION },
  { value: "term_reduction", label: translations.TERM_REDUCTION },
  { value: "total_balance", label: "Saldo Total" },
];

export const transferType = [
  { value: "ach", label: "ACH" },
  { value: "lbtr", label: "LBTR" },
];
export const insuranceTypes = [
  { value: "fixed", label: "Cuota fija" },
  { value: "prorated", label: "Cuota Prorrateada" },
  { value: "", label: "N/A" },
];

export const CHECKBOX_OPTIONS = [
  { name: "notarialPay", label: translations.NOTARIAL_PAY },
  { name: "addendum", label: translations.ADDENDUM },
  { name: "debitAuthorization", label: translations.DEBIT_AUTHORIZATION },
  { name: "knowledgeForm", label: translations.KNOWLEDGE_FORM },
  { name: "flier", label: translations.FLIER },
  { name: "check", label: translations.CHECK },
];

export const CONTACT_REASON = [
  { value: "accountReceivable", label: "Cuenta por Cobrar" },
  { value: "documentUpdate", label: "Actualización de documentación" },
  { value: "payCapacityVariation", label: "Variación en la capacidad de pago" },
  { value: "other", label: "Otros" },
];

export const SITUATION_OPTIONS = [
  { value: "active", label: translations.ACTIVE },
  { value: "inactive", label: translations.INACTIVE },
  { value: "delayed", label: translations.DELAYED },
  { value: "on transit", label: translations.ON_TRANSIT },
];

export const headersDBS = [
  { name: "Fecha de Vencimiento", prop: "dueDate", type: "date" },
  {
    name: "Tipo de Transaccion",
    prop: "transactionType",
    type: "select",
    options: [...transactionTypes],
  },
  { name: "Monto Abono", prop: "payAmount", type: "number" },
  { name: "Notas", prop: "notes", type: "text" },
];

export const headersDeposit = [
  { name: "Monto Deposito", prop: "amount" },
  { name: "Fecha Deposito", prop: "date" },
  { name: "Proporcion", prop: "proportion" },
];
export const headersVacation = [
  { name: translations.DEPARTURE_DATE, prop: "departureDate" },
  { name: translations.ENTRY_DATE, prop: "entryDate" },
  { name: "Dias", prop: "daysTaken" },
];

export const headersLoan = [
  { name: "Periodo", prop: "period" },
  { name: "Fecha Vencimiento", prop: "dueDate" },
  { name: "Balance Principal", prop: "mainBalance" },
  { name: "Cuota", prop: "fee" },
  { name: "Principal", prop: "principal" },
  { name: "Interes", prop: "interes" },
  { name: "Otro seguro", prop: "commission" },
  { name: "Seguro", prop: "insurance" },
  { name: "Ahorro", prop: "saving" },
  { name: "Aportaciones", prop: "contribution" },
  { name: "Balance Final", prop: "finalBalance" },
];

export const headersCheck = [
  { name: "Número del Cheque", prop: "checkNumber", type: "numberOnly" },
  { name: "Fecha Vencimiento", prop: "expirationDate", type: "date" },
];

export const headersPropertyWarranty = [
  {
    name: "Descripción",
    prop: "propertyDescription",
    type: "text",
    required: true,
  },
  {
    name: "Número de referencia",
    prop: "propertyReferenceNumber",
    type: "text",
    required: true,
  },
  {
    name: "Monto del bien",
    prop: "propertyAmount",
    type: "number",
    required: true,
  },
  {
    name: "Fecha del bien",
    prop: "propertyDate",
    type: "date",
    required: true,
  },
  {
    name: "Tipo de documento",
    prop: "propertyDocumentType",
    type: "select",
    options: [
      { label: "factura", name: "Factura" },
      { label: "título", name: "Título" },
      { label: "contrato", name: "Contrato" },
    ],
    required: true,
  },
];
export const headersVehicleWarranty = [
  { name: "Tipo", prop: "vehicleType", type: "text", required: true },
  { name: "Marca", prop: "vehicleBrand", type: "text", required: true },
  { name: "Modelo", prop: "vehicleModel", type: "text", required: true },
  { name: "Año", prop: "vehicleYear", type: "numberOnly", required: true },
  {
    name: "Matrícula",
    prop: "vehicleRegistration",
    type: "text",
    required: true,
  },
  { name: "Color", prop: "vehicleColor", type: "text", required: true },
  { name: "Chasis", prop: "vehicleChassis", type: "text", required: true },
  { name: "Placa", prop: "vehicleLicensePlate", type: "text", required: true },
];

export const headersMortgageWarranty = [
  {
    name: "Descripción",
    prop: "houseDescription",
    type: "textArea",
    required: true,
  },
];

export const warrantyTypes = [
  { value: "mortgage", label: "Garantía hipotecaria" },
  { value: "vehicle_warranty", label: "Garantía vehicular" },
  { value: "property_warranty", label: "Garantía de bienes" },
];
export const loanTypes = [
  { value: "jointGuarantee", label: "Garantía solidaria" },
  { value: "collateralGuarantee", label: "Garantía prendaria" },
  { value: "liquidGuarantee", label: "Garantía liquida" },
  { value: "mortgageGuarantee", label: "Garantía hipotecaria" },
];
export const savingsAccountTypes = [
  { value: "functional", label: "Funcional" },
  { value: "coopsan", label: "Coopsan" },
  { value: "youth", label: "Infantil" },
  { value: "in_sight", label: "A la vista" },
  { value: "institutional", label: "Institucional" },
  { value: "planned", label: "Planificada" },
];
export const collectionTypes = [
  { value: "payroll_deduction", label: "Descuento por Nómina" },
  { value: "direct_debit", label: "Debito automático" },
  { value: "individual_responsibility", label: "Responsabilidad individual" },
];
export const signatureTypes = [
  { value: "joint", label: "Conjunta" },
  { value: "individual", label: "Individual" },
  { value: "indistinct", label: "Indistinta" },
  { value: "indistinct_or_joint", label: "Indistinta y/o conjunta" },
];

export const collateralTypes = [
  {
    value: "motorVehicles",
    label: "Vehiculos de motor",
    rohi: "PRESTAMOS DE VEHICULOS",
  },
  // { value: "financialInstrument", label: "Instrumento financiero", rohi: "" },
  // { value: "pledged", label: "Pignoración inventario", rohi: "" },
];

export const liquidTypes = [
  { value: "saving", label: translations.SAVING, rohi: "GAR. LIQUIDA AHORROS" },
  {
    value: "contributionCertificate",
    label: translations.CONTRIBUTION_CERTIFICATE,
    rohi: "GAR. LIQUIDA AHORROS",
  },
  {
    value: "financialCertificate",
    label: translations.FINANCIAL_CERTIFICATE,
    rohi: "GAR. LIQUIDA DPF",
  },
];

export const jointTypes = [
  { value: "solidarity", label: "Solidaria", rohi: "SOLIDARIA" },
  {
    value: "colabSalaryAdvance",
    label: "Avances a Colaboradores",
    rohi: "AVANCES A COLABORADORES",
  },
  {
    value: "conditionalRealEstateLoans",
    label: "Préstamos condicional de inmueble",
    rohi: "ORD. CONDICIONAL INMUEBLE",
  },
  {
    value: "exceptionalLoans",
    label: "Préstamos de excepción",
    rohi: "PRESTAMO DE EXCEPCION",
  },
  {
    value: "longTermBusiness",
    label: "Comercial a largo plazo",
    rohi: "COMERCIALES LARGO PLAZO",
  },
  {
    value: "commercialWorkingCapital",
    label: "Comercial de capital de trabajo",
    rohi: "COMERCIALES CAPITAL DE TRABAJO",
  },
  {
    value: "serviceOrder",
    label: "Orden de servicio",
    rohi: "ORDINARIO ORDEN SERVICIO",
  },
  {
    value: "healtServiceOrder",
    label: "Orden de servicio (Salud)",
    rohi: "ORDEN DE SERVICIO (SALUD)",
  },
  {
    value: "applianceServiceOrder",
    label: "Orden de servicio (Electrodomesticos)",
    rohi: "ORDEN DE SERVICIO ELECTRODOMÉSTICOS",
  },
  {
    value: "fairServiceOrder",
    label: "Préstamos de Feria",
    rohi: "PRESTAMO DE FERIA (LIQUIDO)",
  },
];
export const allWarranty = [
  ...jointTypes,
  ...collateralTypes,
  ...liquidTypes,
  { value: "mortgage", label: "Hipotecaria", rohi: "PRESTAMO HIPOTECARIO" },
];
export const amortizationTable = [
  {
    category: 1,
    value: "administrativeExpensesType",
    label: "Tipo gastos administrativos",
    type: "com",
    disabled: false,
    required: true,
    options: [...administrativeExpensesType],
  },
  {
    category: 1,
    value: "administrativeExpensesRate",
    label: "Tasa gastos administrativos",
    type: "%",
    disabled: false,
    required: true,
  },
  {
    category: 1,
    value: "otherFees",
    label: "Otros gastos",
    type: "$",
    disabled: false,
    required: true,
  },
  {
    category: 1,
    value: "savingFees",
    label: "Monto de ahorro",
    type: "$",
    disabled: false,
    required: true,
  },
  {
    category: 1,
    value: "contributionFees",
    label: "Monto de aportaciones",
    type: "$",
    disabled: false,
    required: true,
  },
  {
    category: 1,
    value: "administrativeExpensesAmount",
    label: "Monto gastos administrativos",
    type: "$",
    disabled: true,
    required: true,
  },
  // { category: 1, value: "commissionRate", label: 'Tasa comisión', type: '%', disabled: false, required: true },
  // { category: 1, value: "commissionAmount", label: 'Monto comisión', type: '$', disabled: true, required: true },
  {
    category: 1,
    value: "originalAmount",
    label: "Monto a Financiar",
    type: "$",
    disabled: true,
    required: true,
  },
  {
    category: 1,
    value: "transferAmount",
    label: "Monto a transferir",
    type: "$",
    disabled: true,
    required: true,
  },
  {
    category: 2,
    value: "timeLimit",
    label: "Plazo",
    type: "n",
    disabled: false,
    required: true,
  },
  {
    category: 2,
    value: "periodicity",
    label: "Periodicidad",
    type: "cbx",
    disabled: false,
    required: true,
    options: [...periodicity],
  },
  {
    category: 2,
    value: "paymentDay",
    label: "Dia de Pago",
    type: "n",
    disabled: false,
    required: true,
  },
  {
    category: 2,
    value: "secondPaymentDay",
    label: "Dia de Pago 2",
    type: "n2",
    disabled: false,
    required: true,
  },
  {
    category: 2,
    value: "outlayDate",
    label: "Fecha de desembolso",
    type: "f",
    disabled: false,
    required: true,
  },
  {
    category: 2,
    value: "dueDate",
    label: "Fecha caducidad",
    type: "f",
    disabled: true,
    required: true,
  },
  {
    category: 3,
    value: "hasFractionation",
    label: "Fraccionado?",
    type: "cb",
    disabled: false,
    required: true,
  },
  {
    category: 3,
    value: "fractionationType",
    label: "Tipo fraccionamiento",
    type: "fract",
    disabled: "hasFractionation",
    required: true,
    options: [...fractionationType],
  },
  {
    category: 3,
    value: "firstInstallmentDate",
    label: "Fecha Primera Cuota",
    type: "f",
    disabled: true,
    required: true,
  },
  {
    category: 4,
    value: "hasSpp",
    label: "Incluye pagos programados?",
    type: "cb",
    disabled: "toExpiration",
    required: true,
  },
];
// {category: 2, value: "numberPeriods",        label: 'Número de Periodos',         type: 't',   disabled: false,        required: true},

export const headersPCP = [
  { name: "Monto", prop: "pcpAmount", type: "number" },
  { name: "Fecha Pago", prop: "pcpDate", type: "date" },
  {
    name: "Configuracion Financiera",
    prop: "sppTransactionType",
    type: "select",
    options: [...transactionTypeAP],
  },
];

export const headersBILL = [
  {
    name: "Tipo Transacción",
    prop: "transactionType",
    type: "select",
    options: [...transactionTypes],
  },
  {
    name: "Tipo de Pago",
    prop: "payType",
    type: "select",
    options: [
      { name: "Extraordinario", label: "Extraordinario" },
      { name: "Convencional", label: "Convencional" },
    ],
  },
  { name: "Monto Aplicado", prop: "appliedAmount", type: "number" },
  {
    name: "Decisión interés",
    prop: "interestDecision",
    type: "select",
    options: [...interestDecision],
  },
  { name: "Monto Recibido", prop: "amountReceived", type: "number" },
  { name: "Descuento", prop: "discount", type: "number" },
  { name: "Total Recibido", prop: "totalReceived", type: "number" },
];

export const headersBlacklist = [
  { value: "referenceCode", label: translations.REFERENCE },
  { value: "fullName", label: translations.CLIENT },
  { value: "identificationType", label: translations.IDENTIFICATION_TYPE },
  { value: "identificationNumber", label: translations.IDENTIFICATION },
  { value: "reason", label: "Razón  de Bloqueo" },
];

export const headersInternationalBlacklist = [
  { value: "fullName", label: translations.NAME },
  { value: "identificationNumber", label: translations.IDENTIFICATION },
  { value: "nationality", label: "Nacionalidad" },
  { value: "crimeCommitted", label: "Crimen cometido" },
];

export const quotationStatus = [
  { value: "on_process", label: translations.ON_PROCESS },
  { value: "approved", label: translations.APPROVED },
  { value: "cancelled", label: translations.CANCELLED },
  { value: "used", label: translations.USED },
];

export const notarialPayStatus = [
  { value: "pending_signature", label: translations.PENDING_SIGNATURE },
  {
    value: "internal_signature_pending",
    label: translations.INTERNAL_SIGNATURE_PENDING,
  },
  { value: "pending_legalization", label: translations.PENDING_LEGALIZATION },
  {
    value: "in_legalization_process",
    label: translations.IN_LEGALIZATION_PROCESS,
  },
  { value: "archived", label: translations.ARCHIVED },
];

export const loanContractStatus = [
  { value: "pending_signature", label: translations.PENDING_SIGNATURE },
  {
    value: "internal_signature_pending",
    label: translations.INTERNAL_SIGNATURE_PENDING,
  },
  {
    value: "in_legalization_process",
    label: translations.IN_LEGALIZATION_PROCESS,
  },
  { value: "completed", label: translations.COMPLETED },
  { value: "archived", label: translations.ARCHIVED },
];

export const inputCertificationStatus = [
  { value: "in_process", label: translations.PROCESSING },
  { value: "completed", label: translations.COMPLETED },
  { value: "archived", label: translations.ARCHIVED },
];

export const payrollDeductionStatus = [
  { value: "archived", label: translations.ARCHIVED },
  { value: "completed", label: "Completado" },
  { value: "pending_signature", label: translations.PENDING_SIGNATURE },
  {
    value: "internal_signature_pending",
    label: translations.INTERNAL_SIGNATURE_PENDING,
  },
];

export const cancelOptions = [
  { value: "lackDocumentation", label: "Falta de Documentacion" },
  { value: "customerWithdraws", label: "Cliente desiste del Prestamo" },
  { value: "insPayCapacity", label: "Capacidad de Pago Insuficiente" },
  {
    value: "insDebtCapacity",
    label: "Capacidad de Endeudamiento Insuficiente",
  },
  { value: "createdByError", label: "Creado por Error" },
  { value: "impairedCreditHistory", label: "Historial Crediticio Deteriorado" },
];

export const cancelQuotation = [
  { value: "customerWithdraws", label: "Cliente desiste del Prestamo" },
  { value: "createdByError", label: "Creado por Error" },
];

export const dataAcquisitionOptions = [
  {
    name: "Actualización de datos de contacto",
    prop: "updateContactInfo",
    help: "Correo, Telefono, Dirección",
  },
  {
    name: "Actualización de datos laborales",
    prop: "updateFinancialData",
    help: "Salario, Tiempo y Departamento",
  },
  {
    name: "Comprobación de método de cobro ",
    prop: "paymentMethodCheck",
    help: "Usuario, Contraseña, Preguntas",
  },
];

export const dataAcquisitionOptionsNewClient = [
  {
    name: "Datos de contacto",
    prop: "updateContactInfo",
    condition: "newClientUniqueId",
    help: "Correo, Telefono, Dirección",
  },
  {
    name: "Datos de perfil financiero",
    prop: "updateFinancialData",
    condition: "newClientFinancialProfileUniqueId",
    help: "Usuario, Contraseña, Preguntas",
  },
  {
    name: "Datos de perfil laboral",
    prop: "paymentMethodCheck",
    condition: "newClientWorkProfileUniqueId",
    help: "Salario, Tiempo y Departamento",
  },
];

export const dataAcquisitionOptionsMembershipApplication = [
  {
    name: "Datos de contacto",
    prop: "updateContactInfo",
    condition: "membershipApplicationUniqueId",
    help: "Correo, Teléfono, Dirección",
  },
  {
    name: "Datos de perfil financiero",
    prop: "updateFinancialData",
    condition: "membershipApplicationFinancialProfileUniqueId",
    help: "Usuario, Contraseña, Preguntas",
  },
  {
    name: "Datos de perfil laboral",
    prop: "paymentMethodCheck",
    condition: "membershipApplicationWorkProfileUniqueId",
    help: "Salario, Tiempo y Departamento",
  },
];

export const STATUS_TICKET_TYPES = [
  { value: "analysis pending", name: translations.ANALYSIS_PENDING },
  { value: "cancelled", name: translations.CANCELLED },
  { value: "client contact pending", name: "Contacto Pendiente" },
  { value: "contract pending", name: translations.CONTRACT_PENDING },
  { value: "delayed", name: translations.DELAYED },
  { value: "delivery pending", name: translations.DELIVERY_PENDING },
  { value: "disbursement pending", name: translations.DISBURSEMENT_PENDING },
  { value: "evaluation pending", name: translations.EVALUATION_PENDING },
  {
    value: "financial evaluation pending",
    name: translations.FINANCIAL_EVALUATION_PENDING,
  },
  {
    value: "initial payment pending",
    name: translations.INITIAL_PAYMENT_PENDING,
  },
  { value: "invoice registration pending", name: translations.INVOICE_PENDING },
  { value: "issue Management", name: translations.ISSUE_MANAGEMENT },
  {
    value: "legal evaluation pending",
    name: translations.LEGAL_EVALUATION_PENDING,
  },
  { value: "new request", name: translations.NEW_REQUEST },
  {
    value: "outlay data adquisition",
    name: translations.OUTLAY_DATA_ADQUISITION,
  },
  { value: "payment pending", name: translations.PAYMENT_PENDING },
  {
    value: "status evaluation partner pending",
    name: translations.PARTNER_EVALUATION_PENDING,
  },
  { value: "payment processing", name: translations.PAYMENT_PROCESSING },
  { value: "processed", name: translations.PROCESSED },
  { value: "processing", name: translations.PROCESSING },
  { value: "registration pending", name: translations.REGISTRATION_PENDING },
  { value: "report pending", name: translations.REPORT_PENDING },
  { value: "reset pending", name: "Restablecimiento de Usuario Pendiente" },
  {
    value: "reset verification pending",
    name: "Verificacion de Restablecimiento Pendiente ",
  },
  { value: "review pending", name: translations.REVIEW_PENDING },
  {
    value: "system update pending",
    name: "Actualizacion de Sistemas Pendiente",
  },
  { value: "transaction pending", name: translations.TRANSACTION_PENDING },
  {
    value: "user verification pending",
    name: "Verificacion de Estado de Usuario",
  },
  { value: "verification pending", name: translations.VERIFICATION_PENDING },
  { value: "withdrawal failed", name: translations.WITHDRAWAL_FAILED },
  { value: "withdrawal pending", name: translations.WITHDRAWAL_PENDING },
  {
    value: "client debt capacity estimation",
    name: "Estimación de capacidad de endeudamiento",
  },
  {
    value: "client payment capacity estimation",
    name: "Estimación de capacidad de pago",
  },
  // { value: 'client settlement form handling', name: ''},
  { value: "client quotation estimation", name: "Cotización" },
];

export const SIMPLE_STATUS_TICKET_TYPES = [
  { value: "cancelled", name: translations.CANCELLED },
  { value: "delayed", name: translations.DELAYED },
  { value: "new request", name: translations.NEW_REQUEST },
  { value: "processed", name: translations.PROCESSED },
  { value: "on_process", name: translations.ON_PROCESS },
];

export const RELATIONSHIP = [
  { value: "Father", name: translations.FATHER },
  { value: "Mother", name: translations.MOTHER },
  { value: "Children", name: translations.CHILDREN },
  { value: "Partner", name: translations.WIFE },
  { value: "Sister", name: translations.SISTER },
  { value: "Brother", name: translations.BROTHER },
  { value: "Cousin m", name: translations.COUSIN_M },
  { value: "Cousin f", name: translations.COUSIN_F },
  { value: "Friend", name: translations.FRIEND },
  { value: "Aunt", name: translations.AUNT },
  { value: "Uncle", name: translations.UNCLE },
];

export const PRIORITIES_TICKET = [
  { value: "high", name: translations.HIGH },
  { value: "low", name: translations.LOW },
  { value: "mid", name: translations.MID },
];

export const BLOCKED_REASON = [
  { value: "disabled", name: "Deshabilitado" },
  { value: "blocked", name: "Bloqueado" },
  { value: "password_changed", name: "Cambio de contraseña" },
  { value: "code_card_change", name: "Cambio de tarjeta de código" },
  { value: "expired_device", name: "Dispositivo Vencido" },
];

export const checkStatus = [
  { value: "in_transit", label: translations.OUTLAY_CHECK_STATUS_IN_TRANSIT },
  { value: "archived", label: translations.OUTLAY_CHECK_STATUS_ARCHIVED },
  { value: "completed", label: translations.OUTLAY_CHECK_STATUS_COMPLETED },
  { value: "pending_signature", label: translations.PENDING_SIGNATURE },
  { value: "cancelled", label: translations.OUTLAY_CHECK_STATUS_CANCELLED },
];

export const REQUEST_TICKET_TYPES = [
  // { value:'payment', name:'payment'},
  // { value: 'automatic bank debit', name: translations.AUTOMATIC_BANK_DEBIT },
  // { value: 'bank transaction', name: translations.BANK_TRANSACTION },
  // { value: 'blocked user', name: translations.BLOCKED_USER },
  // { value: 'cash transaction', name: translations.CASH_TRANSACTION },
  // { value: 'contact client', name: 'Contacto de Cliente' },
  // { value: 'customer account management', name: translations.CUSTORMER_ACCOUNT_MANAGEMENT },
  // { value: 'customer assistance', name: translations.CUSTOMER_ASSISTANCE },
  // { value: 'invoice management', name: translations.INVOICE_MANAGEMENT },
  // { value: 'miscellaneous', name: translations.MISCELLANEOUS },
  { value: "outlay", name: translations.OUTLAY_EXISTING_CLIENT },
  // { value: 'payment', name: translations.LOAN_PAYMENTS },
  // { value: 'quotation', name: translations.QUOTATION },
  // { value: 'reprogramming', name: translations.REPROGRAMMING },
  // { value: 'new client', name: translations.OUTLAY_NEW_CLIENT },
  { value: "new partner", name: translations.MA },
  { value: "payment", name: translations.OUTLAY_NEW_PAYMENT_TICKET },
];

/* Status options */
const INVOICE_STATUS = [
  { value: "invoice registration pending", name: translations.INVOICE_PENDING },
  { value: "payment pending", name: translations.PAYMENT_PENDING },
];

const ACCOUNT_HANDLING_STATUS = [
  { value: "issue Management", name: translations.ISSUE_MANAGEMENT },
  { value: "transaction pending", name: translations.TRANSACTION_PENDING },
];
export const ONPROCESS_TYPES = {
  "automatic bank debit": [...INVOICE_STATUS],
  "bank transaction": [...ACCOUNT_HANDLING_STATUS],
  "blocked user": [
    {
      value: "user verification pending",
      name: "Verificacion de Usuario pendiente",
    },
    { value: "reset pending", name: "Restablecimiento de Usuario Pendiente" },
    {
      value: "reset verification pending",
      name: "Verificacion de Restablecimiento Pendiente ",
    },
    {
      value: "system update pending",
      name: "Actualizacion de Sistemas Pendiente",
    },
  ],

  "cash transaction": [
    { value: "withdrawal failed", name: translations.WITHDRAWAL_FAILED },
    { value: "withdrawal pending", name: translations.WITHDRAWAL_PENDING },
  ],
  "contact client": [
    { value: "client contact pending", name: "Contacto Pendiente" },
  ],
  "customer account management": [...ACCOUNT_HANDLING_STATUS],
  "customer assistance": [
    { value: "delivery pending", name: translations.DELIVERY_PENDING },
    { value: "report pending", name: translations.REPORT_PENDING },
    { value: "verification pending", name: translations.VERIFICATION_PENDING },
  ],
  general: [
    { value: "evaluation pending", name: translations.EVALUATION_PENDING },
    { value: "registration pending", name: translations.REGISTRATION_PENDING },
    { value: "review pending", name: translations.REVIEW_PENDING },
  ],
  "invoice management": [...INVOICE_STATUS],

  miscellaneous: [{ value: "processing", name: translations.PROCESSING }],
  outlay: [
    { value: "review pending", name: translations.REVIEW_PENDING },
    {
      value: "outlay data adquisition",
      name: translations.OUTLAY_DATA_ADQUISITION,
    },
    {
      value: "financial evaluation pending",
      name: translations.FINANCIAL_EVALUATION_PENDING,
    },
    {
      value: "legal evaluation pending",
      name: translations.LEGAL_EVALUATION_PENDING,
    },
    { value: "transaction pending", name: translations.TRANSACTION_PENDING },
  ],
  "new partner": [
    {
      value: "status evaluation partner pending",
      name: translations.PARTNER_EVALUATION_PENDING,
    },
    {
      value: "outlay data adquisition",
      name: translations.OUTLAY_DATA_ADQUISITION,
    },
  ],
  payment: [
    { value: "payment pending", name: translations.PAYMENT_PENDING },
    { value: "registration pending", name: translations.REGISTRATION_PENDING },
    { value: "verification pending", name: translations.VERIFICATION_PENDING },
  ],
  quotation: [
    { value: "analysis pending", name: translations.ANALYSIS_PENDING },
    {
      value: "initial payment pending",
      name: translations.INITIAL_PAYMENT_PENDING,
    },
    { value: "payment processing", name: translations.PAYMENT_PROCESSING },
  ],
  status: [
    { value: "new request", name: translations.NEW_REQUEST },
    { value: "cancelled", name: translations.CANCELLED },
    { value: "delayed", name: translations.DELAYED },
    { value: "processed", name: translations.PROCESSED },
  ],
};

export const OUTLAY_USER_FLOW = {
  group1: [
    { value: "dadames", name: "Darkis Adames" },
    { value: "jsena", name: "Jeyson Sena" },
    { value: "csilvestre", name: "Claudia Silvestre" },
    { value: "ylantigua", name: "Yorman Lantigua" },
  ],
  group2: [
    { value: "jrodriguez", name: "Junior Rodriguez" },
    { value: "scapriles", name: "Shaylis Capriles" },
  ],
  group3: [{ value: "lmercedes", name: "Lanna Mercedes" }],
};

export const LABORAL_STATUS_OPTIONS = [
  { value: "active", label: translations.ACTIVE },
  { value: "inactive", label: translations.INACTIVE },
  { value: "retired", label: translations.RETIRED },
];

export const COMPANIES = ["COOPERATIVA BARCELONA", "INVERSIONES ESPANA"];

export const CALCULATORS = [
  // { value: "quotation", label: "Calculadora de Prestamos"},
  { value: "planned savings", label: translations.PLANNED_SAVINGS },
  { value: "coopsan", label: "Ahorros CoopSan" },
  { value: "payment", label: "Calculadora de Pagos" },
];

export const INTEREST_CALCULATOR_TYPES = [
  { value: "in_sight", label: "A la vista" },
  { value: "youth", label: "Infantil" },
  { value: "functional", label: "Ahorro Funcional"},
  { value: "institutional", label: "Institucional" },
  { value: "planned_savings", label: translations.PLANNED_SAVINGS },
  { value: "coopsan", label: "CoopSan" },
  { value: "financial_certificates", label: "Certificados financieros" },
  { value: "service_order", label: "Orden de servicio" },
  { value: "contributions", label: "Aportaciones" },
  { value: "digital_loans", label: "Prestamos Digitales" },
  { value: "ap_lvl_na", label: "Nivel de Aprobacion (Nomina agil)" },
  { value: "ap_lvl_sl", label: "Nivel de Aprobacion (Socio libre)" },
  { value: "membership_breakddown", label: "Desglose de Afiliacion" },
];

export const CALCULATION_TYPES = [
  { value: "quota", label: "Cuota" },
  { value: "amount", label: "Monto" },
  { value: "periods", label: "Plazo" },
];

export const PLANNED_SAVINGS_PERIODICITY = [
  { value: "monthly", label: translations.MONTHLY, periods: 1 },
  { value: "biweekly", label: translations.BIWEEKLY, periods: 1 / 2 },
];

export const HEADERS_PLANNED_SAVINGS = [
  { name: "Periodo", prop: "period" },
  { name: "Fecha", prop: "date" },
  { name: "Balance Inicial", prop: "balance" },
  { name: "Cuota", prop: "quota" },
  { name: "Interes", prop: "interes" },
  { name: "Retencion", prop: "retention" },
  { name: "Balance Final", prop: "finalBalance" },
];

export const HEADERS_COOPSAN = [
  { name: "Periodo", prop: "period" },
  { name: "Fecha", prop: "date" },
  { name: "Total de cuotas", prop: "totalQuota" },
  { name: "Interes", prop: "interest" },
  { name: "Total", prop: "total" },
];

export const headersCoopSanForm = [
  {
    name: translations.CONSECUTIVE_QUOTAS,
    prop: "consecutiveQuotas",
    type: "number",
  },
  {
    name: translations.DISTRIBUTION_PERCENTAGE,
    prop: "distributionPercentage",
    type: "number",
  },
  { name: translations.INTEREST_RATE, prop: "interestRate", type: "number" },
];

export const headersPlannedSavingsForm = [
  { name: translations.FROM, prop: "from", type: "number" },
  { name: translations.TO, prop: "to", type: "number" },
  { name: translations.INTEREST_RATE, prop: "interestRate", type: "number" },
];
export const headersFinancialCertificatesForm = [
  { name: translations.FROM, prop: "from", type: "number" },
  { name: translations.TO, prop: "to", type: "number" },
  { name: "Plazo mínimo", prop: "minTimeLimit", type: "number" },
  { name: "Plazo máximo", prop: "maxTimeLimit", type: "number" },
  {
    name: "Otras formas de liquidación",
    prop: "otherWayInterestRate",
    type: "number",
  },
  { name: "Capitalizable", prop: "capitalizedInterestRate", type: "number" },
];

export const apLevelOptions = [
  {
    value: "analista de credito",
    name: "Analista de Credito"
  },
  {
    value: "gerente de analisis de credito",
    name: "Gerente de analisis de credito"
  },
  {
    value: "gerente general",
    name: "Gerente General"
  },
  {
    value: "comite de credito",
    name: "Comite de Credito"
  },
  {
    value: "consejo de administracion",
    name: "Consejo de administracion"
  }
]

export const conceptOptions = [
  { value: "suscription", name: "Inscripción" },
  { value: "onSight", name: "Ahorro a la Vista" },
  { value: "contributions", name: "Aportaciones" },
]

export const headerApLevel = [
  { name: "Nivel de aprobación", prop: "level", type: "select", options: apLevelOptions },
  { name: "Desde", prop: "from", type: "number" },
  { name: "Hasta", prop: "to", type: "number" },
]

export const headerFunctional = [
  { name: "Desde", prop: "from", type: "number" },
  { name: "Hasta", prop: "to", type: "number" },
  { name: "Cuota de ahorro", prop: "quota", type: "number" },
]

export const headerMembershipBreakdown = [
  { name: "Concepto", prop: "reason", type: "select", options: conceptOptions },
  { name: "Empresa Afiliada", prop: "affiliated", type: "number" },
  { name: "Empresa no afiliada", prop: "notaffiliated", type: "number" },
  { name: "Cuota Afiliada", prop: "affiliateRohiQuota", type: "number" },
  { name: "Cuota No Afiliada", prop: "notAffiliateRohiQuota", type: "number" },
]

export const paymentMethods = [
  { value: "individual_responsibility", label: "Responsabilidad individual" },
  { value: "payroll_discount", label: translations.PAYROLL },
  { value: "automatic_debit", label: "Débito automático" },
];

export const finalities = [
  { value: "mortgage", label: "(H) Hipotecario" },
  { value: "microcredit", label: "(M) Microcrédito" },
  { value: "consumption", label: "(N) Consumo" },
  { value: "commercial", label: "(C) Comercial" },
];

export const paymentTypes = [
  { value: "conventional", label: translations.CONVENTIONAL },
  { value: "extraordinary", label: "Extraordinario" },
];
export const creditMethods = [
  { value: "internet_banking", label: translations.BANK_TRANSFER },
  { value: "check", label: translations.CHECK },
  { value: "savings_account", label: translations.SAVINGS_ACCOUNT },
  { value: "financial_certificate", label: translations.FINANCIAL_CERTIFICATE },
  { value: "cash", label: translations.CASH },
  { value: "expiration", label: translations.EXPIRATION },
  { value: "outlay", label: translations.OUTLAY },
];

export const approbationStates = [
  { value: "pending_approval", label: "Pendiente de aprobación" },
  { value: "approved", label: "Aprobado" },
  { value: "known", label: "Conocido" },
  { value: "postpone", label: "Pospuesto" },
  { value: "denied", label: "Denegado" },
  { value: "reclassified", label: "Reclasificado" },
  { value: "diligence", label: "Debidas diligencias" },
  { value: "extra_req", label: "Requisitos extras" },
];

export const TicketStates = [
  { value: "approval_pending", label: "Aprobacion pendiente" },
  { value: "denied", label: "Negado" },
  { value: "approved", label: "Aprovado" },
];

export const responsibleOptionsAssociate = [
  { value: "Consejo de administración" },
];
export const responsibleOptions = [
  { value: "Gerente general" },
  { value: "Consejo de administracion" },
  { value: "Analista de credito" },
  { value: "Comite de credito" },
];
export const checkActions = [
  { value: "deposit", label: "Depósito" },
  { value: "direct_exchange", label: "Cambio directo" },
];

export const institutionClassification = [
  { value: "1120601", label: "INSTITUCIONES PÚBLICAS CENTRALIZADAS" },
  { value: "1120602", label: "INSTITUCIONES PÚBLICAS DESCENTRALIZADAS" },
  { value: "1120603", label: "INSTITUCIONES PRIVADAS" },
  { value: "1120604", label: "INSTITUCIONES SIN FINES DE LUCRO" },
  { value: "1120605", label: "INSTITUCIONES PÚBLICAS MILITARES" },
];

export const expirationStatus = [
  { value: "Programmed", label: "Progamado" },
  { value: "Complete", label: "Completo" },
  { value: "Partially paid", label: "Pago parcial" },
  { value: "Processed", label: "Procesado" },
  { value: "Late", label: "Atrasado" },
  { value: "On process", label: "En proceso" },
  { value: "Error", label: "Error" },
  { value: "Info", label: "Notificación" },
  { value: "Warn", label: "Alerta" },
];

export const affiliationPurpose = [
  { value: "financing", label: translations.FINANCING },
  { value: "savings", label: translations.SAVING },
  { value: "investment", label: translations.INVESTMENT_CERTIFICATE },
  { value: "financial_certificate", label: translations.FINANCIAL_CERTIFICATE },
  { value: "insurance", label: translations.INSURANCE },
  { value: "financial_education", label: translations.FINANCIAL_EDUCATION },
];

export const paymentMethodsLeads = [
  { value: "office", label: translations.OFFICE },
  { value: "payroll_deduction", label: translations.PAYROLL },
  { value: "transfer_or_deposit", label: translations.TRANSFER_OR_DEPOSIT },
];

export const paymentTypesLeads = [
  { value: "fixed", label: "Fijo" },
  { value: "perhour", label: "Por hora" },
  { value: "na", label: "No Aplica" },
];

export const sessionTypes = [
  { value: "fulltime", label: "Tiempo completo" },
  { value: "halftime", label: "Medio tiempo" },
  { value: "na", label: "No Aplica" },
];

export const workTypes = [
  { value: "permanent", label: "Fijo" },
  { value: "temporary", label: "Temporal" },
  { value: "na", label: "No Aplica" },
];

export const bankAccountTypes = [
  { value: "cash", label: translations.CASH },
  { value: "saving", label: translations.SAVING },
  { value: "checking", label: translations.CHECKING },
];

export const leadsState = [
  { value: "pending_evaluation", label: translations.EVALUATION_PENDING },
  {
    value: "hr_confirmation_pending",
    label: translations.HR_CONFIRMATION_PENDING,
  },
  {
    value: "compliance_review_pending",
    label: translations.COMPLIANCE_REVIEW_PENDING,
  },
  { value: "complete", label: translations.COMPLETED },
  { value: "canceled", label: translations.CANCELLED },
];

export const economicActivity = [
  { value: "employee/salaried", label: translations.EMPLOYEE_SALARIED },
  { value: "owner/partner", label: translations.OWNER_PARTNER },
  { value: "retired/pensioner", label: translations.RETIRED_PENSIONER },
  { value: "independent", label: translations.INDEPENDENT },
  { value: "lender/investor", label: translations.LENDER_INVESTOR },
  { value: "housewife", label: translations.HOUSEWIFE },
];

export const primaryIncomeMonthlyActivity = [
  { value: "less than RD$20 thousands", label: translations.LESS_20 },
  {
    value: "from RD$20 thousands to RD$50 thousands",
    label: translations.FROM_20_TO_50,
  },
  {
    value: "from RD$50 thousands to RD$100 thousands",
    label: translations.FROM_50_TO_100,
  },
  { value: "more than RD$100 thousands", label: translations.MORE_100 },
];

export const secondaryIncomeMonthlyActivity = [
  { value: "N/A", label: translations.NON_APPLICABLE },
  { value: "less than RD$30 thousands", label: translations.LESS_30 },
  {
    value: "from RD$30 thousands to RD$60 thousands",
    label: translations.FROM_30_TO_60,
  },
  {
    value: "from RD$60 thousands to RD$90 thousands",
    label: translations.FROM_60_TO_90,
  },
  {
    value: "from RD$90 thousands to RD$120 thousands",
    label: translations.FROM_90_TO_120,
  },
  { value: "more than RD$120 thousands", label: translations.MORE_120 },
];

export const approximateAmountOfTransactions = [
  { value: "less than RD$30 thousands", label: translations.LESS_30 },
  {
    value: "from RD$30 thousands to RD$80 thousands",
    label: translations.FROM_30_TO_80,
  },
  {
    value: "from RD$80 thousands to RD$120 thousands",
    label: translations.FROM_80_TO_120,
  },
  {
    value: "from RD$120 thousands to RD$170 thousands",
    label: translations.FROM_120_TO_170,
  },
  {
    value: "from RD$170 thousands to RD$200 thousands",
    label: translations.FROM_170_TO_200,
  },
  { value: "more than RD$200 thousands", label: translations.MORE_200 },
];

export const settlementDisbursement = [
  { value: "saving_account", label: translations.SAVINGS_ACCOUNT },
  { value: "transfer_or_deposit", label: translations.TRANSFER_OR_DEPOSIT },
];

export const settlementWays = [
  { value: "due_date", label: "A vencimiento" },
  { value: "withdrawable", label: "Retirables" },
  { value: "capitalizable", label: "Capitalizable" },
  { value: "liquidable", label: "Liquidable" },
];

export const liquidablePaymentTypes = [
  { value: "not_applicable", label: "N/A" },
  { value: "check", label: translations.CHECK },
  { value: "wire_transfer", label: translations.BANK_TRANSFER },
];

export const salaryStructures = [
  { value: "fixed_salary", label: "Salario fijo" },
  { value: "variable_salary", label: "Salario variable" },
];

export const economicSectors = [
  { value: "Agricultura" },
  { value: "Administración pública" },
  { value: "Aseguradora" },
  { value: "Artes y diseño" },
  { value: "Bienes raíces y leasing" },
  { value: "Cuidado Animal" },
  { value: "Construcción" },
  { value: "Cuidado personal y de belleza" },
  { value: "Consultoría y Coaching" },
  { value: "Deportes y actividades" },
  { value: "Educación" },
  { value: "E-commerce y venta al detalle" },
  { value: "Energía y minería" },
  { value: "Finanzas" },
  { value: "Filantrópico" },
  { value: "Hotelería y turismo" },
  { value: "Industrias químicas" },
  { value: "Ingeniería mecánica y eléctrica" },
  { value: "Marketing e investigación" },
  { value: "Recursos humanos" },
  { value: "Salud" },
  { value: "Tecnología y TI" },
  { value: "Transporte y viajes" },
  { value: "Telecomunicaciones" },
  { value: "Textiles y Zona Franca" },
  { value: "Turismo" },
  { value: "Restaurantes y alimentos" },
  { value: "Organización de eventos" },
  { value: "Video y fotografía" },
  { value: "Otro" },
];
