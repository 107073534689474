import { AxiosError } from "axios";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createParticipant } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import attorneyNumberMaskInput from "../../../components/TextMaskInputComponents/attorneyNumberMaskInput";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneMaskInput";
import {
  affiliateState,
  affiliateType,
  catchError,
  civilStatus,
  dwellingTypes,
  educationLevel,
  gender,
  IDENTIFICATION_TYPE,
  nationalitiesToOptions,
  participantType,
  prefix,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils/";
import {
  createParticipant as create,
  fetchNationalities,
} from "../../../utils/axios";
import Address from "../../../components/Address/Address";
import ProfessionSelectInput from "../../Profession/ProfessionSelectInput";
import { Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";
// import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    display: "none",
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowy: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IAttorneyFormProps {
  classes: { [key: string]: string };

  user: IUser;
  createParticipant(participant: IParticipant): IAction<IParticipant[]>;
  changeSection(sectionNumber: number): void;
}
interface IAttorneyFormState {
  [key: string]:
    | string
    | string[]
    | number
    | boolean
    | IOptionType
    | IOptionType[];
  additionalPhone: string;
  additionalPhoneError: string;
  affiliateState: string;
  affiliateStateError: string;
  affiliateType: string;
  affiliateTypeError: string;
  attorneyNumber: string;
  attorneyNumberError: string;
  birthDate: string;
  birthDateError: string;
  cellPhone: string;
  cellPhoneError: string;
  city: string;
  cityError: string;
  civilStatus: string;
  civilStatusError: string;
  country: string;
  countryError: string;
  dialog: boolean;
  dialogTitle: string;
  educationLevel: string;
  educationLevelError: string;
  email: string;
  emailError: string;
  errorMessage: string;
  fullName: string;
  fullNameError: string;
  gender: string;
  genderError: string;
  homePhone: string;
  homePhoneError: string;
  identificationNumber: string;
  identificationNumberError: string;
  identificationType: string;
  identificationTypeError: string;
  identificationExpirationDate: string;
  identificationExpirationDateError: string;
  loading: boolean;
  nationality: IOptionType;
  nationalityError: string;
  nationalityOptions: IOptionType[];
  nickName: string;
  nickNameError: string;
  participantType: string;
  participantTypeError: string;
  prefix: string;
  prefixError: string;
  profession: string[];
  reference: string;
  referenceError: string;
  region: string;
  regionError: string;
  sector: string;
  sectorError: string;
  state: string;
  stateError: string;
  workPhone: string;
  workPhoneError: string;
  workPhoneExtension: string;
  workPhoneExtensionError: string;
  vehicleColor: string;
  vehicleColorError: string;
  vehicleBrand: string;
  vehicleBrandError: string;
  vehicleModel: string;
  vehicleModelError: string;
  vehicleYear: number;
  vehicleYearError: string;
  hasDebtorAVehicle: false;
  hasDebtorAVehicleError: string;
  vehicleFinancialStatus: string;
  vehicleFinancialStatusError: string;
  // houseMonthlyPaymentError: string,
  // houseMonthlyPayment: number,
  // houseMonthlyPaymentCurrency: string,
  // houseMonthlyPaymentCurrencyError: string,
  dwellingType: string;
}
class Form extends React.Component<IAttorneyFormProps, IAttorneyFormState> {
  public state: IAttorneyFormState = {
    additionalPhone: "",
    additionalPhoneError: "",
    affiliateState: "not_affiliate",
    affiliateStateError: "",
    affiliateType: "service_user_affiliate",
    affiliateTypeError: "",
    attorneyNumber: "",
    attorneyNumberError: "",
    birthDate: "",
    birthDateError: "",
    cellPhone: "",
    cellPhoneError: "",
    city: "",
    cityError: "",
    civilStatus: "single",
    civilStatusError: "",
    country: "República Dominicana",
    countryError: "",
    dialog: false,
    dialogTitle: "",
    educationLevel: "none",
    educationLevelError: "",
    email: "",
    emailError: "",
    errorMessage: "",
    fullName: "",
    fullNameError: "",
    gender: "m",
    genderError: "",
    homePhone: "",
    homePhoneError: "",
    identificationNumber: "",
    identificationNumberError: "",
    identificationType: "national",
    identificationTypeError: "",
    identificationExpirationDate: "",
    identificationExpirationDateError: "",
    loading: false,
    nationality: { label: "", value: "" },
    nationalityError: "",
    nationalityOptions: [],
    nickName: "",
    nickNameError: "",
    participantType: "client",
    participantTypeError: "",
    prefix: "mister",
    prefixError: "",
    profession: [],
    reference: "",
    referenceError: "",
    region: "",
    regionError: "",
    sector: "",
    sectorError: "",
    state: "",
    stateError: "",
    workPhone: "",
    workPhoneError: "",
    workPhoneExtension: "",
    workPhoneExtensionError: "",
    vehicleColor: "",
    vehicleColorError: "",
    vehicleBrand: "",
    vehicleBrandError: "",
    vehicleModel: "",
    vehicleModelError: "",
    vehicleYear: 0,
    vehicleYearError: "",
    hasDebtorAVehicle: false,
    hasDebtorAVehicleError: "",
    vehicleFinancialStatus: "financed",
    vehicleFinancialStatusError: "",
    // houseMonthlyPaymentError: "",
    // houseMonthlyPayment: 0,
    // houseMonthlyPaymentCurrency: "DOP",
    // houseMonthlyPaymentCurrencyError: "",
    dwellingType: "",
  };

  constructor(props: IAttorneyFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleAddProfession = this.handleAddProfession.bind(this);
    this.handleDeleteProfession = this.handleDeleteProfession.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
  }

  public handleAddProfession(id: string) {
    this.setState({ profession: [id] });
  }

  public handleDeleteProfession(id: string) {
    this.setState({ profession: [] });
  }

  public componentDidMount() {
    this.setState({ loading: false });
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState(
          {
            nationalityOptions: nationalitiesToOptions(
              res.data.results ? res.data.results : res.data
            ),
          },
          () => {
            this.setState((prevState) => ({
              nationality: prevState.nationalityOptions[0],
            }));
          }
        );
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando  nacionalidades",
          loading: false,
        });
      });
  }
  public handleReactSelectChange(nationality: IOptionType) {
    this.setState({ nationality });
  }
  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }

  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }
  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    let participant: IParticipant = {
      additionalPhone: this.state.additionalPhone,
      address: {
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        reference: this.state.reference,
        sector: titleCaseHandler(this.state.sector),
      },
      affiliateState: this.state.affiliateState,
      affiliateType: this.state.affiliateType,
      attorneyNumber: this.state.attorneyNumber,
      birthDate: this.state.birthDate,
      cellPhone: this.state.cellPhone,
      civilStatus: this.state.civilStatus,
      educationLevel: this.state.educationLevel,
      email: this.state.email,
      fullName: this.state.fullName,
      gender: this.state.gender,
      homePhone: this.state.homePhone,
      identificationNumber: this.state.identificationNumber.replace(/-/g, ""),
      identificationType: this.state.identificationType,
      identificationExpirationDate:
        this.state.identificationExpirationDate || null,
      nationality: this.state.nationality.value,
      nickName: this.state.nickName,
      participantType: this.state.participantType,
      prefix: this.state.prefix,
      profession: this.state.profession[0],
      workPhone: this.state.workPhone,
      workPhoneExtension: this.state.workPhoneExtension,
      vehicleColor: this.state.vehicleColor,
      vehicleBrand: this.state.vehicleBrand,
      vehicleModel: this.state.vehicleModel,
      vehicleYear: this.state.vehicleYear,
      hasDebtorAVehicle: this.state.hasDebtorAVehicle,
      vehicleFinancialStatus: this.state.vehicleFinancialStatus,
      houseInfo: this.state.dwellingType,
      // houseMonthlyPayment: this.state.houseMonthlyPayment,
      // houseMonthlyPaymentCurrency: this.state.houseMonthlyPaymentCurrency,
    };

    this.setState({ loading: true });

    if (this.state.participantType !== "attorney") {
      participant = _.omit(participant, "attorneyNumber");
    }

    create(this.props.user.token, participant)
      .then((res) => {
        this.props.createParticipant(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.PARTICIPANT);
        this.handleReset();
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState((prevState) => ({
      additionalPhone: "",
      affiliateState: "not_affiliate",
      affiliateStateError: "",
      affiliateType: "service_user_affiliate",
      affiliateTypeError: "",
      attorneyNumber: "",
      birthDate: "",
      cellPhone: "",
      city: "",
      civilStatus: "single",
      country: "República Dominicana",
      educationLevel: "none",
      educationLevelError: "",
      email: "",
      fullName: "",
      gender: "m",
      homePhone: "",
      identificationNumber: "",
      identificationType: "national",
      identificationExpirationDate: "",
      nationality: prevState.nationalityOptions[0],
      nickName: "",
      nickNameError: "",
      participantType: "client",
      participantTypeError: "",
      prefix: "mister",
      profession: [],
      reference: "",
      region: "",
      sector: "",
      state: "",
      workPhone: "",
      workPhoneExtension: "",
      vehicleColor: "",
      vehicleColorError: "",
      vehicleBrand: "",
      vehicleBrandError: "",
      vehicleModel: "",
      vehicleModelError: "",
      vehicleYear: 0,
      vehicleYearError: "",
      hasDebtorAVehicle: false,
      hasDebtorAVehicleError: "",
      vehicleFinancialStatus: "financed",
      vehicleFinancialStatusError: "",
      // houseMonthlyPaymentError: "",
      // houseMonthlyPayment: 0,
      // houseMonthlyPaymentCurrency: "DOP",
      // houseMonthlyPaymentCurrencyError: "",
      dwellingType: "",
    }));
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public getIdentificationInput(identificationType: string) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  public render() {
    const { classes } = this.props;

    return (
      <form
        id="AttorneyForm"
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">{translations.NEW_PARTICIPANT}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="participantType" shrink={true}>
                  {translations.PARTICIPANT_TYPE}
                </InputLabel>
                <Select
                  value={this.state.participantType}
                  onChange={this.handleSelectChange("participantType")}
                  required={true}
                  error={this.state.participantTypeError !== ""}
                  inputProps={{
                    id: "participantType",
                    name: "participantType",
                  }}
                >
                  {participantType.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              {this.state.participantType === "attorney" && (
                <TextField
                  label={translations.ATTORNEY_NUMBER}
                  className={classes.textField}
                  value={this.state.attorneyNumber}
                  onChange={this.handleChange("attorneyNumber")}
                  required={true}
                  fullWidth={true}
                  error={this.state.attorneyNumberError !== ""}
                  helperText={this.state.attorneyNumberError}
                  InputProps={{
                    inputComponent: attorneyNumberMaskInput,
                  }}
                  InputLabelProps={{
                    style: styles.checkboxLabel,
                  }}
                />
              )}
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="identificationType" shrink={true}>
                  {translations.IDENTIFICATION_TYPE}
                </InputLabel>
                <Select
                  value={this.state.identificationType}
                  onChange={this.handleSelectChange("identificationType")}
                  required={true}
                  error={this.state.identificationTypeError !== ""}
                  inputProps={{
                    id: "identificationType",
                    name: "identificationType",
                  }}
                >
                  {IDENTIFICATION_TYPE.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label={translations.IDENTIFICATION}
                className={classes.textField}
                value={this.state.identificationNumber}
                onChange={this.handleChange("identificationNumber")}
                fullWidth={true}
                required={true}
                error={this.state.identificationNumberError !== ""}
                helperText={this.state.identificationNumberError}
                InputProps={this.getIdentificationInput(
                  this.state.identificationType
                )}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.IDENTIFICATION_EXPIRATION_DATE}
                className={classes.textField}
                value={this.state.identificationExpirationDate}
                type="date"
                onChange={this.handleChange("identificationExpirationDate")}
                error={this.state.identificationExpirationDate !== ""}
                helperText={this.state.identificationExpirationDateError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.fullName}
                onChange={this.handleChange("fullName")}
                fullWidth={true}
                error={this.state.fullNameError !== ""}
                helperText={this.state.fullNameError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.NICKNAME}
                className={classes.textField}
                value={this.state.nickName}
                onChange={this.handleChange("nickName")}
                fullWidth={true}
                error={this.state.nickNameError !== ""}
                helperText={this.state.nickNameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.EMAIL}
                className={classes.textField}
                placeholder="mycompany@email.com"
                type="email"
                value={this.state.email}
                onChange={this.handleChange("email")}
                fullWidth={true}
                required={true}
                error={this.state.emailError !== ""}
                helperText={this.state.emailError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="gender" shrink={true}>
                  {translations.GENDER}
                </InputLabel>
                <Select
                  value={this.state.gender}
                  onChange={this.handleSelectChange("gender")}
                  required={true}
                  error={this.state.genderError !== ""}
                  inputProps={{
                    id: "gender",
                    name: "gender",
                  }}
                >
                  {gender.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="civilStatus" shrink={true}>
                  {translations.CIVIL_STATUS}
                </InputLabel>
                <Select
                  value={this.state.civilStatus}
                  onChange={this.handleSelectChange("civilStatus")}
                  required={true}
                  error={this.state.civilStatusError !== ""}
                  inputProps={{
                    id: "civilStatus",
                    name: "civilStatus",
                  }}
                >
                  {civilStatus.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="prefix" shrink={true}>
                  {translations.TITLE}
                </InputLabel>
                <Select
                  value={this.state.prefix}
                  onChange={this.handleSelectChange("prefix")}
                  required={true}
                  error={this.state.prefixError !== ""}
                  inputProps={{
                    id: "prefix",
                    name: "prefix",
                  }}
                >
                  {prefix.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="educationLevel" shrink={true}>
                  {translations.EDUCATION_LEVEL}
                </InputLabel>
                <Select
                  value={this.state.educationLevel}
                  onChange={this.handleSelectChange("educationLevel")}
                  required={true}
                  error={this.state.educationLevelError !== ""}
                  inputProps={{
                    id: "educationLevel",
                    name: "educationLevel",
                  }}
                >
                  {educationLevel.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="affiliateState" shrink={true}>
                  {translations.AFFILIATE_STATE}
                </InputLabel>
                <Select
                  value={this.state.affiliateState}
                  onChange={this.handleSelectChange("affiliateState")}
                  required={true}
                  error={this.state.affiliateStateError !== ""}
                  inputProps={{
                    id: "affiliateState",
                    name: "affiliateState",
                  }}
                >
                  {affiliateState.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="affiliateType" shrink={true}>
                  {translations.AFFILIATE_TYPE}
                </InputLabel>
                <Select
                  value={this.state.affiliateType}
                  onChange={this.handleSelectChange("affiliateType")}
                  required={true}
                  error={this.state.affiliateTypeError !== ""}
                  inputProps={{
                    id: "affiliateType",
                    name: "affiliateType",
                  }}
                >
                  {affiliateType.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <label
                  htmlFor="nationality"
                  style={styles.checkboxLabelReactSelect}
                >
                  {translations.NATIONALITY}
                </label>
                <ReactSelect
                  value={this.state.nationality}
                  onChange={this.handleReactSelectChange}
                  options={this.state.nationalityOptions}
                />
              </FormControl>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.BIRTHDATE}
                  className={classes.textField}
                  value={this.state.birthDate}
                  type="date"
                  onChange={this.handleChange("birthDate")}
                  required={true}
                  error={this.state.birthDateError !== ""}
                  helperText={this.state.birthDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
                <FormControlLabel
                  style={{ verticalAlign: "bottom" }}
                  label={translations.DEBTOR_VEHICLE}
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.state.hasDebtorAVehicle}
                      onChange={this.handleCheckBoxChange("hasDebtorAVehicle")}
                    />
                  }
                />
              </div>
              <div
                style={{
                  display: this.state.hasDebtorAVehicle ? "block" : "none",
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <TextField
                    name="model"
                    label={translations.VEHICLE_MODEL}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.vehicleModel)}
                    onChange={this.handleChange("vehicleModel")}
                    fullWidth={true}
                    required={this.state.hasDebtorAVehicle ? true : false}
                    error={this.state.vehicleModelError !== ""}
                    helperText={this.state.vehicleModelError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  &nbsp;
                  <TextField
                    name="brand"
                    label={translations.VEHICLE_BRAND}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.vehicleBrand)}
                    onChange={this.handleChange("vehicleBrand")}
                    fullWidth={true}
                    required={this.state.hasDebtorAVehicle ? true : false}
                    error={this.state.vehicleBrandError !== ""}
                    helperText={this.state.vehicleBrandError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </div>
                <br />
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <TextField
                    name="year"
                    label={translations.VEHICLE_YEAR}
                    className={classes.textField}
                    value={this.state.vehicleYear}
                    onChange={this.handleChange("vehicleYear")}
                    fullWidth={true}
                    required={this.state.hasDebtorAVehicle ? true : false}
                    error={this.state.vehicleYearError !== ""}
                    helperText={this.state.vehicleYearError}
                    InputProps={{
                      inputComponent: attorneyNumberMaskInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  &nbsp;
                  <TextField
                    name="color"
                    label={translations.VEHICLE_COLOR}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.vehicleColor)}
                    onChange={this.handleChange("vehicleColor")}
                    fullWidth={true}
                    required={this.state.hasDebtorAVehicle ? true : false}
                    error={this.state.vehicleColorError !== ""}
                    helperText={this.state.vehicleColorError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </div>
                <br />
                <FormControl
                  className={classes.formControl}
                  error={this.state.vehicleFinancialStatusError !== ""}
                >
                  <InputLabel
                    style={{ top: -17 }}
                    htmlFor="vehicleFinancialStatus"
                    shrink={true}
                  >
                    {translations.VEHICLE_FINANCIAL_STATUS}
                  </InputLabel>
                  <Select
                    value={this.state.vehicleFinancialStatus}
                    name="fin_status"
                    onChange={this.handleSelectChange("vehicleFinancialStatus")}
                    required={this.state.hasDebtorAVehicle ? true : false}
                    native={true}
                    inputProps={{
                      id: "vehicleFinancialStatus",
                      name: "vehicleFinancialStatus",
                    }}
                  >
                    <option value="financed">{translations.FINANCED}</option>
                    <option value="paid">{translations.PAID}</option>
                    <option value="not own">{translations.NOT_OWN}</option>
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <FormControl
                  className={classes.formControl}
                  style={{ marginRight: 10 }}
                >
                  <InputLabel htmlFor="dwellingType" shrink={true}>
                    {translations.DWELLING_TYPE}
                  </InputLabel>
                  <Select
                    value={this.state.dwellingType}
                    onChange={this.handleSelectChange("dwellingType")}
                    inputProps={{
                      name: "dwellingType",
                    }}
                  >
                    {dwellingTypes.map((opt: any, indx: any) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                &nbsp;
                {/* <TextField
                  label={translations.HOUSE_MONTHLY_PAYMENT}
                  value={this.state.houseMonthlyPayment}
                  onChange={this.handleCurrencyInputChange(
                    "houseMonthlyPayment"
                  )}
                  style={{ width: "28%", marginTop: "20px" }}
                  error={this.state.houseMonthlyPaymentError !== ""}
                  helperText={this.state.houseMonthlyPaymentError}
                  InputProps={{
                    inputComponent: MoneyInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
                &nbsp;
                <FormControl
                  className={classes.formControl}
                  error={this.state.houseMonthlyPaymentCurrencyError !== ""}
                >
                  <Select
                    style={{ width: "101px", marginTop: "16px" }}
                    value={this.state.houseMonthlyPaymentCurrency}
                    onChange={this.handleSelectChange(
                      "houseMonthlyPaymentCurrency"
                    )}
                    inputProps={{
                      id: "houseMonthlyPaymentCurrency",
                      name: "houseMonthlyPaymentCurrency",
                    }}
                  >
                    <MenuItem value="DOP">{translations.DOP}</MenuItem>
                    <MenuItem value="USD">{translations.USD}</MenuItem>
                  </Select>
                </FormControl> */}
              </div>
              <TextField
                label={translations.CELL_PHONE}
                className={classes.textField}
                value={this.state.cellPhone}
                onChange={this.handleChange("cellPhone")}
                type="phone"
                placeholder="+1(809) 000-0000"
                fullWidth={true}
                required={true}
                error={this.state.cellPhoneError !== ""}
                helperText={this.state.cellPhoneError}
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.HOME_PHONE}
                className={classes.textField}
                value={this.state.homePhone}
                onChange={this.handleChange("homePhone")}
                type="phone"
                placeholder="+1(809) 000-0000"
                fullWidth={true}
                error={this.state.homePhoneError !== ""}
                helperText={this.state.homePhoneError}
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />

              <TextField
                label={translations.WORK_PHONE}
                className={classes.textField}
                value={this.state.workPhone}
                onChange={this.handleChange("workPhone")}
                type="phone"
                placeholder="+1(809) 000-0000"
                fullWidth={true}
                required={true}
                error={this.state.workPhoneError !== ""}
                helperText={this.state.workPhoneError}
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.WORK_PHONE_EXTENSION}
                className={classes.textField}
                value={this.state.workPhoneExtension}
                onChange={this.handleChange("workPhoneExtension")}
                fullWidth={true}
                error={this.state.workPhoneExtensionError !== ""}
                helperText={this.state.workPhoneExtensionError}
                InputProps={{
                  inputComponent: attorneyNumberMaskInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.ADDITIONAL_PHONE}
                className={classes.textField}
                value={this.state.additionalPhone}
                onChange={this.handleChange("additionalPhone")}
                type="phone"
                placeholder="+1(809) 000-0000"
                fullWidth={true}
                error={this.state.additionalPhoneError !== ""}
                helperText={this.state.additionalPhoneError}
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <Address
                city={this.state.city}
                cityError={this.state.cityError}
                country={this.state.country}
                countryError={this.state.countryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                state={this.state.state}
                stateError={this.state.stateError}
                title={translations.ADDRESS}
              />
              <ProfessionSelectInput
                selectedProfessionId={this.state.profession}
                addProfession={this.handleAddProfession}
                deleteProfession={this.handleDeleteProfession}
                title={translations.PROFESSION}
              />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  form="AttorneyForm"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createParticipant })(Form)
);
