import PersonalInfoForm from  './personalInfo';
import ContactForm from  './contacts';
import WorkInfoForm from  './workInfo';
import PaymentInstructionsForm from  './paymentInstructions';
import FinancialSituationForm from  './financialSituation';
import PersonalReferenceForm from  './personalReferences';
import FulfillmentForm from  './fulfillment';
import FamilyInCaseDecease from  './familyInCaseDecease';
import FilesSelection from  './files';
import FinancialInfoForm from  './financialInfo';
import Documents from  './documents';
import Comment from  './comment';

export default () => ({
    personalInfo: {
        component: PersonalInfoForm, label: 'Informaciones personales',
        fields: ['fullName', 'identificationType', 'identificationNumber', 'prefix', 'identificationExpirationDate', 'gender', 'birthDate', 'nationality', 'birthPlace', 'profession', 'civilStatus','dependentQuantity', 'houseInfo', 'educationLevel' ],
        rFields: ['fullName', 'identificationType', 'identificationNumber'],
    },
    contact: {
        component: ContactForm, label: 'Informaciones de contacto.',
        fields: [  'country', 'region', 'province', 'city', 'sector', 'address', 'cellPhone', 'homePhone', 'additionalPhone', 'email' ],
        rFields: [],
    },
    workInfo: {
        component: WorkInfoForm, label: 'Informaciones laborales',
        fields: [ 'laboralStatus', 'position', 'department', 'salary', 'paymentType', 'sessionType', 'admissionDate', 'entryDate', 'laboralCode', 'workType', 'workEmail', 'workPhone' ],
        rFields: [],
    },
    financialInfo: {
        component: FinancialInfoForm, label: 'Informaciones financieras',
        fields: [
            'hasDebtorAnotherCitizenship', 'secondCitizenshipCountry', 'secondCitizenshipIdentificationNumber', 'economicActivityInfo', 
            'primaryIncomeMonthlyActivity', 'secondaryIncome', 'secondaryIncomeActivity', 'secondaryIncomeMonthlyActivity', 
            'approximateAmountOfTransactionsOnTheCooperative', 'accountPurpose', 'hasDebtorPossessedPublicPower', 
            'willDebtorReceive15kOrMoreInTheAccount', 'affiliationPurpose', 'amountToSave', 'wayToPay', 'numberOfInstallments', 
            'typeOfInstallments', 'institution', 'financialInstitution', 'accountType', 'bankAccount', 'houseMonthlyPayment', 
            'pastDuedebt', 'pastDuedebtAmount', 'paymentFrequency', 'paymentDay', 'employmentLength', 'inputValue', 'inputAmount', 
            'amount', 'term', 'interestRate', 'transferType', 'savingsAccount'
        ],
        rFields: [],
    },
    fileSelection: {
        component: FilesSelection, label: 'Archivos adjuntos de solicitud',
        fields: ['files'],
        rFields: [],
    },
    documents: {
        component: Documents, label: 'Documentación legal relevante',
        rFields: [],
    },
    commentSection: {
        component: Comment, noWrapper: true, rFields: [],
    },
});