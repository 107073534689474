import { fetchTime } from "../../../utils/axios";
import {createFileAttachment, fetchFileAttachments, fetchFileAttachment} from "src/utils/axios";

export const statusColors = [
    {bgColor: '#4688e3', color: '#FFFFFF', state:'pending_evaluation', name: 'P. Oficial', fullName: 'Pendiente Oficial', order: 0},
    {bgColor: '#ed9e4a', color: '#424242', state:'hr_confirmation_pending', name: 'P. HHRR', fullName: 'Pendiente RRHH', order: 1},
    {bgColor: '#f0c756', color: '#424242', state:'compliance_review_pending', name: 'P. Cumplimiento', fullName: 'Pendiente Cumplimiento', order: 2},
    {bgColor: '#3ac5c7', color: '#FFFFFF', state:'pending_signature', name: 'P. Firma', fullName: 'Pendiente Firma', order: 3},
    {bgColor: '#515cc2', color: '#FFF', state:'signature_completed', name: 'Firma Completada', fullName: 'Firma Completada', order: 4},
    {bgColor: '#17b38c', color: '#FFF', state:'conciliation_pending', name: 'P. Conciliación', fullName: 'Pendiente Conciliación', order: 5},
    {bgColor: '#debb7a', color: '#FFF', state:'insufficient_data', name: 'Info. Insuficiente', fullName: 'Información Insuficiente'},
    {bgColor: '#43a047', color: '#FFFFFF', state:'complete', name: 'Completado', fullName: 'Completado', order: 6},
    {bgColor: '#f24e4e', color: '#FFFFFF', state:'canceled', name: 'Cancelada', fullName: 'Cancelada'},
    {bgColor: '#dedcdc', color: '#424242', state:'default', name: '- - - - -', fullName: ''},
]

export const leadStatusOrders = [
    
    // IF ENTERPRISE TYPE  IS INDEPENDENT / IF CASE IS DEFAULT/FLAGGED
    {value: "pending_evaluation", order: 1, type: 'independent', case: 'both'},
    {value: "compliance_review_pending", order: 2, type: 'independent', case: 'both'},


    // IF ENTERPRISE TYPE  IS AFFILIATED / IF CASE IS DEFAULT/
    {value: "hr_confirmation_pending", order: 1, type: 'affiliate', case: ''},
    // {value: "conciliation_pending", order: 2, type: 'affiliate', case: ''},


    // IF ENTERPRISE TYPE  IS AFFILIATED / IF CASE IS FLAGGED
    {value: "compliance_review_pending", order: 1, type: 'affiliate', case:'flagged',},
    {value: "hr_confirmation_pending", order: 2, type: 'affiliate', case:'flagged',}
]

const checkIfArrayObject = (data) => {
    const check = data.find(item => typeof item === 'object');
    return Boolean(check);
}

const handleInstitutionTotalTime = (entryDate) => {
    const newTime = new Date();
    const newDate = new Date(entryDate);
    const diffTime = Math.abs(newDate - newTime);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return Math.round(diffDays/30)
}

export const handleUploadFileEndpoint = (options) => {
    let formData = new FormData();
    formData.append("file", options?.file);
    formData.append("name", `address-file-${options?.leadUniqueId}`);
    return createFileAttachment(options?.token, formData);
}
// method not used since we pass the file directly to the property.
// keeping it here for later use.
export const getPossibleFileAttachment = async (options) => {
    if(!options?.file) {
        let currSavedFile = null;
        const name = `${options?.field}-file-${options?.leadUniqueId}`;
        const endpoint = fetchFileAttachments({token: options?.token, name});
        await endpoint.then(res => {
            currSavedFile = res.data?.length ? res.data[res.data.length - 1] : null;
        });
        if(currSavedFile) {
            return currSavedFile;
            // onChange('any', options?.field, currSavedFile);
        }
    }
}

export const setFileObj = (file) => typeof file === "string" ? file : (file?.uniqueId || null);

export const formattedFieldsData = (fields, oldFieldData, extraFields) => {
    // format payment frequency
    // let paymentFrequency = fields.paymentFrequency;
    // if(typeof fields.paymentFrequency === "object") {
    //     paymentFrequency = fields.paymentFrequency?.value;
    // }
    const checkEmptyField = (obj) => {
        const result = Boolean(Object.values(obj).includes(''));
        return !result;
    }

    const insitutionTime =  fields.entryDate ? handleInstitutionTotalTime(fields.entryDate) : 0;
    return {
        owner: {
            address: {
                country: fields.country?.name || fields.country || '',
                state: fields.province?.name || fields.province || '',
                city: fields.city?.name || fields.city || '',
                region: fields.region?.name || fields.region || '',
                reference: fields.reference?.value || fields.reference || null,
                sector: fields.sector?.value || fields.sector || '',
            },
            workprofile: {
                uniqueId: oldFieldData?.[`uniqueId_workprofile`],
                institution: extraFields?.institution?.name || '',
                laboralStatus: fields.laboralStatus?.value || fields.laboralStatus || '',
                position: fields.salaried === false ? "NO APLICA" : (fields.position?.name || fields.position || ''),
                department: fields.salaried === false ? "NO APLICA" : (fields.department?.name || fields.department || ''),
                entryDate: fields.entryDate || null,
                salary: fields.salary || 0,
                averageIncome: fields.averageIncome || 0, 
                economicSector: fields.economicSector?.value || fields.economicSector || "",
                commercialActivity: fields.commercialActivity || '', 
                // incomeVoucher: fields.incomeVoucher || null,
                // incomeVoucher:  setFileObj(fields.incomeVoucher),
                laboralCode: fields.laboralCode || '',
                workType: fields.workType?.value || fields.workType || null,
                email: fields.workEmail || '',
                // paymentFrequency: paymentFrequency || '',
                // paymentDay: fields.paymentDay || [],
                paymentType: fields.paymentType?.value || fields.paymentType || null,
                sessionType: fields.sessionType?.value || fields.sessionType || null,
                employmentLength: insitutionTime
            },
            acquaintanceship: {
                uniqueId: oldFieldData?.[`uniqueId_acquaintanceship`],
                accountPurpose: fields.accountPurpose?.length ? fields.accountPurpose.map(item => item.value || item) : [],
                hasDebtorAnotherCitizenship: Boolean(fields.hasDebtorAnotherCitizenship),
                secondCitizenshipCountry: fields.secondCitizenshipCountry?.value || fields.secondCitizenshipCountry || '',
                secondCitizenshipIdentificationNumber: fields.secondCitizenshipIdentificationNumber?.value || fields.secondCitizenshipIdentificationNumber || '',
                economicActivityInfo: fields.economicActivityInfo?.value || fields.economicActivityInfo || '',
                primaryIncomeMonthlyActivity: fields.primaryIncomeMonthlyActivity?.value || fields.primaryIncomeMonthlyActivity || '',
                secondaryIncome: Boolean(fields.secondaryIncome),
                secondaryIncomeMonthlyActivity:fields.secondaryIncomeMonthlyActivity?.value || fields.secondaryIncomeMonthlyActivity || '',
                secondaryIncomeActivity: fields.secondaryIncomeActivity?.value || fields.secondaryIncomeActivity || '',
                approximateAmountOfTransactionsOnTheCooperative: fields.approximateAmountOfTransactionsOnTheCooperative?.value || fields.approximateAmountOfTransactionsOnTheCooperative || '',
                willDebtorReceive15kOrMoreInTheAccount: Boolean(fields.willDebtorReceive15kOrMoreInTheAccount),
                hasDebtorPossessedPublicPower: Boolean(fields.hasDebtorPossessedPublicPower),
                beneficiaryInfo: fields.beneficiaryInfo || '',
                houseInfo: fields.houseInfo || '',
                hasDebtorPossessedPublicPowerDescription: {
                    pepMemberPosition: fields.pepMemberPosition || '',
                    pepMemberEntryDate: fields.pepMemberEntryDate || '',
                    pepMemberInstitution: fields.pepMemberInstitution || '',
                }
            },
            bankaccount: (fields.financialInstitution || fields.bankaccount) ? [
                {
                    uniqueId:oldFieldData?.[`uniqueId_bankaccount`] || null,
                    bank: fields.financialInstitution?.name || (fields.bankaccount && fields.bankaccount[0]?.bank) || '',
                    accountType: fields.accountType?.value || fields.accountType || (fields.bankaccount && fields.bankaccount[0]?.accountType) || '',
                    number: fields.bankAccount || fields.bankaccount[0]?.number || '',
                }
            ] : [],
            nationality: fields.nationality?.name || fields.nationality || '',
            prefix: fields.prefix?.value || fields.prefix || '',
            fullName: fields.fullName || '',
            identificationType: fields.identificationType?.value || fields.identificationType || '',
            identificationNumber: fields.identificationNumber || '',
            gender: fields.gender?.value || fields.gender || '',
            birthDate: fields.birthDate || null,
            // civilStatus: fields.civilStatus?.value || fields.civilStatus || '',
            profession: fields.profession?.name || fields.profession || '',
            // birthplace: fields.birthplace || '',
            cellPhone: fields.cellPhone || '',
            homePhone: fields.homePhone || '',
            workPhone: fields.workPhone ? fields.workPhone.replace(/[()-\s]/g,'') : '',
            additionalPhone: fields.additionalPhone || '',
            email: fields.owner_email || '',
            // houseInfo: fields.houseInfo?.value || fields.houseInfo || '',
            // educationLevel: fields.educationLevel?.value || fields.educationLevel || '',
            educationLevel: "none",
            houseMonthlyPayment: fields.houseMonthlyPayment || '',
            // addressFile: fields.addressFile?.uniqueId ? {uniqueId: fields.addressFile?.uniqueId} : null,
            addressFile: setFileObj(fields.addressFile),
            // identificationFile: fields.identificationFile?.uniqueId ? {uniqueId: fields.identificationFile?.uniqueId} : null,
            identificationFile: setFileObj(fields.identificationFile),
            files: [],
            uniqueId: oldFieldData?.[`uniqueId_owner`],
        },
        savingsAccountForm: [
            {
                quota: fields.amountToSave || 0,
                type: 'in_sight',   
            },
        ].filter(item => item.hasOwnProperty("childInfo") ? checkEmptyField(item.childInfo) : checkEmptyField(item)),
        inputCertificate: {
            inputValue: fields.contributionInitialDeposit ? fields.contributionInitialDeposit/(fields.inputAmount || 1) : 0,
            inputAmount: fields.inputAmount || 1,
        },
        pastDuedebt: Boolean(fields.pastDuedebt) || '',
        pastDuedebtAmount: fields.pastDuedebtAmount || '',
        participationFee: fields.participationFee || '',
        amountToSave: fields.amountToSave || '',
        wayToPay: fields.wayToPay?.value || fields.wayToPay || '',
        numberOfInstallments: fields.numberOfInstallments || 0,
        typeOfInstallments: fields.typeOfInstallments?.value || fields.typeOfInstallments ||'',
        uniqueId: fields.uniqueId || '',
        state: fields._state ? fields._state : (oldFieldData?._state || oldFieldData?.state) || '',
        blacklist: {},
        disbursementWays: fields.disbursementWays?.value || fields.disbursementWays || null,
        comment: fields.comment || '',
        initialDeposit: fields.initialDeposit || '',
        initialSaving: fields.initialAccountSaving || '',
    }
};

export const convertSavingsFormToField = (savingForm, fields) => {
    savingForm.forEach(sf => {
        switch(sf.type){
            case 'in_sight':
                fields.sightSavingFee = sf.quota;
                break;
            default:
                break;
        }
    })
}

export const convertFinancialCertToField = (financialCert, fields) => {
    fields.settlementWays = financialCert.paymentType;
    fields.certificateAmount = financialCert.amount;
    fields.certificateTerm = financialCert.term;
    fields.certificateRate = financialCert.interestRate;
    fields.transferType = financialCert.transferType;
    fields.savingsAccount = financialCert.savingsAccount;
}

export const fieldsTranslations = {
    country: 'País',
    state: 'Estado',
    city: 'Ciudad',
    region: 'Región',
    reference: 'Dirección',
    sector: 'Sector',
    institution: 'Institución',
    laboralStatus: 'Estado laboral',
    position: 'Posición',
    department: 'Departamento',
    entryDate: 'Fecha de entrada',
    salary: 'Salario',
    averageIncome: 'Ingreso promedio', 
    economicSector: 'Sector económico',
    commercialActivity: 'Actividad comercial', 
    incomeVoucher: 'Comprobante de ingreso',
    laboralCode: 'Código laboral',
    workType: 'Tipo de trabajo',
    email: 'Correo',
    paymentType: 'Tipo de pago',
    sessionType: 'Tipo de Jornada',
    accountPurpose: 'Propósito de afiliación',
    hasDebtorAnotherCitizenship: 'Tiene otra nacionalidad',
    secondCitizenshipCountry: 'Segunda Nacionalidad',
    economicActivityInfo: 'Actividad Económica',
    primaryIncomeMonthlyActivity: 'Ingresos Actividad Económica',
    secondaryIncome: 'Tiene ingreso secundario',
    secondaryIncomeMonthlyActivity:'Actividad Económica Secundaria',
    secondaryIncomeActivity: 'Ingresos Actividad Económica Secundaria',
    approximateAmountOfTransactionsOnTheCooperative: 'Monto Transacciones Mensuales que realizará en la Cooperativa',
    willDebtorReceive15kOrMoreInTheAccount: '¿Recibirá el socio mas de $15,000 en la cuenta?',
    hasDebtorPossessedPublicPower: '¿Persona políticamente expuesta y/o figura pública? ',
    pepMemberPosition: 'Cargo, rango o posición',
    pepMemberEntryDate: 'Fecha de ocupación cargo, rango o posición',
    pepMemberInstitution: 'Institución a la que pertenece',
    nationality: 'Nacionalidad',
    prefix: 'Título',
    fullName: 'Nombre completo',
    identificationType: 'Tipo de identificación',
    identificationNumber: 'Identificación',
    gender: 'Sexo',
    birthDate: 'Fecha nacimiento',
    civilStatus: 'Estado civil',
    profession: 'Profesión',
    birthplace: 'Lugar de nacimiento',
    cellPhone: 'Celular',
    homePhone: 'Teléfono casa',
    workPhone: 'Teléfono trabajo',
    additionalPhone: 'Teléfono adicional',
    educationLevel: 'Nivel educación',
    houseMonthlyPayment: 'Pago Mensual Vivienda',
    addressFile: 'Archivo de dirección',
    identificationFile: 'Archivo de identificación',
    savingsAccountForm: 'Formulario de cuentas de ahorro',
    inputCertificate: 'Certificado financiero',
    inputAmount: 'Cuotas de participación',
    pastDuedebt: '¿Deudas Atrasadas?',
    pastDuedebtAmount: 'Monto Deudas Atrasadas',
    amountToSave: 'Monto ahorro',
    wayToPay: 'Forma de pago',
    numberOfInstallments: 'Número de cuotas',
    typeOfInstallments: 'Frecuencia de pago cuotas',
    blacklist: 'Blacklist',
    comment: 'Comentario',
    initialDeposit: 'Depósito inicial',
    initialSaving: 'Ahorro inicial'
}