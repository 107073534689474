import React, {useState, useEffect} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { 
    AutocompleteField, TextFieldComponent, DateField, NumericTextField
} from './../../../components/SharedComponents/Fields';
import { gender, civilStatus, prefix } from './../../../utils/index';
import { IDENTIFICATION_TYPE, dwellingTypes, educationLevel } from '../../../utils/index';
import { handleUploadFileEndpoint, getPossibleFileAttachment } from "../GeneralMethods";
import { FileSelection } from './../../../components/SharedComponents/FileSelection';

export default function PersonalInfoForm({
    rFields, fields, onChange, errorFields, options, section, getFiltersOptions, 
    handleSelectValue, token, setLoading, handleSimpleDialog, leadUniqueId, disabled
}){
    const [file, setFile] = useState('');
    const [savedFile, setSavedFile] = useState(fields.identificationFile || null);

    const getCurrentFile = async () => {
        const options = {
            token,
            file: fields.identificationFile,
            field: 'identificationFile',
            leadUniqueId
        }
        const currFile = await getPossibleFileAttachment(options);
        if(currFile) {
            onChange('any', 'identificationFile', currFile);
        }
    }
    const idProps = {
        xs: 4,
        fullWidth: true,
        fieldLabel:  `Identificación ${rFields.includes("identificationNumber") ? "(*)" : ""}`,
        placeholder: "Número único del documento de identifación",
        fieldID: `leads-field-personal-identificationNumber`,
        decimalScale: 0,
        value: fields.identificationNumber || '',
        onChange: onChange.bind(this, 'numeric', 'identificationNumber'),
        error: Boolean(errorFields[section]?.identificationNumber),
        helperText: errorFields[section]?.identificationNumber ? errorFields[section]?.identificationNumber : null,
        disabled
    }
    useEffect(() => {
        getCurrentFile();
    }, []);
    useEffect(() => {
        if(savedFile?.uniqueId !== fields.identificationFile?.uniqueId) {
            // onChange('any', 'identificationFile', savedFile);
            onChange('any', 'identificationFile', savedFile);
        }
    }, [savedFile]);
    return(
        <Grid container spacing={3} className="form-wrapper-fields-container">
            <AutocompleteField
                xs={4}
                fieldLabel= {`Título ${rFields.includes("prefix") ? "(*)" : ""}`}
                fieldID="autocomplete-field-prefix"
                fieldInnerLabel="Seleccione título"
                value={
                    // fields.prefix || null
                    handleSelectValue(fields.prefix, prefix, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'prefix')}
                options={getFiltersOptions(prefix)}
                getOptionLabel={(option) => option.label }
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.prefix)}
                helperText={errorFields[section]?.prefix ? errorFields[section]?.prefix : null}
                multiple={false}
                disabled={disabled}
            />
            <TextFieldComponent 
                xs={8}
                fullWidth
                fieldLabel= {`Nombre Completo ${rFields.includes("fullName") ? "(*)" : ""}`}
                fieldID="payment-field-fullName"
                placeholder="Ej: María Matos Gómez"
                value={fields.fullName || ''}
                onChange={onChange.bind(this, 'text', 'fullName')}
                error={Boolean(errorFields[section]?.fullName)}
                helperText={errorFields[section]?.fullName ? errorFields[section]?.fullName : null}
                disabled={disabled}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Tipo de Identificación ${rFields.includes("identificationType") ? "(*)" : ""}`}
                fieldID={`leads-field-personal-identificationType`}
                fieldInnerLabel="Seleccione tipo identificación"
                value={
                    // fields.identificationType || null
                    handleSelectValue(fields.identificationType, IDENTIFICATION_TYPE, 'value')
                    || null
                }
                handleChange={
                    (e, val) => {
                        onChange('autocomplete', 'identificationNumber', e, "");
                        onChange('autocomplete', 'identificationType', e, val);
                    }
                }
                options={getFiltersOptions(IDENTIFICATION_TYPE)}
                getOptionLabel={(option) => option.label }
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.identificationType)}
                helperText={errorFields[section]?.identificationType ? errorFields[section]?.identificationType : null}
                multiple={false}
                disabled={disabled}
            />
            {
                fields.identificationType?.value === 'national' ?
                    <NumericTextField
                        {...idProps}
                        format="###########"
                    />
                :
                    <TextFieldComponent 
                        {...idProps}
                    />
            }
            {/* <DateField 
                xs={4}
                fullWidth
                fieldLabel={`Fecha Expiración Identificación ${rFields.includes("identificationExpirationDate") ? "(*)" : ""}`}
                dateFieldID="payment-receipt-date-id-due-date"
                dateFieldLabel={`Ej: 20/09/${new Date().getFullYear()+5}`}
                value={fields.identificationExpirationDate || null}
                handleChange={onChange.bind(this, 'date', 'identificationExpirationDate')}
                error={Boolean(errorFields[section]?.identificationExpirationDate)}
                helperText={errorFields[section]?.identificationExpirationDate ? errorFields[section]?.identificationExpirationDate : null}
            /> */}
            <AutocompleteField
                xs={4}
                fieldLabel= {`Sexo ${rFields.includes("gender") ? "(*)" : ""}`}
                fieldID="autocomplete-field-gender"
                fieldInnerLabel="Seleccione el sexo"
                value={
                    // fields.gender || null
                    handleSelectValue(fields.gender, gender, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'gender')}
                options={getFiltersOptions(gender)}
                getOptionLabel={(option) => option.label }
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.gender)}
                helperText={errorFields[section]?.gender ? errorFields[section]?.gender : null}
                multiple={false}
                disabled={disabled}
            />
            <DateField 
                xs={4}
                fullWidth
                fieldLabel={`Fecha Nacimiento ${rFields.includes("birthDate") ? "(*)" : ""}`}
                dateFieldID="payment-receipt-date-born"
                dateFieldLabel="Ej: 18/03/1981"
                value={fields.birthDate ? fields.birthDate + " " : null}
                handleChange={onChange.bind(this, 'date', 'birthDate')}
                error={Boolean(errorFields[section]?.birthDate)}
                helperText={errorFields[section]?.birthDate ? errorFields[section]?.birthDate : null}
                disabled={disabled}
            />
            <AutocompleteField
                xs={4}
                fieldLabel= {`Nacionalidad ${rFields.includes("nationality") ? "(*)" : ""}`}
                fieldID="autocomplete-field-nationality"
                fieldInnerLabel="Seleccione su nacionalidad"
                value={
                    // fields.nationality || null
                    handleSelectValue(fields.nationality, options.nationalities, 'name')
                    || null
                }
                handleChange={
                    onChange.bind(this, 'autocomplete', 'nationality')
                }
                options={getFiltersOptions(options.nationalities || [])}
                getOptionLabel={(option) => (option.denomynForMale || "") + "/" + (option.denomynForFemale || "") }
                getOptionSelected={(option, value) => option.id === value.id}
                error={Boolean(errorFields[section]?.nationality)}
                helperText={errorFields[section]?.nationality ? errorFields[section]?.nationality : null}
                multiple={false}
                disabled={disabled}
            />
            {/* <TextFieldComponent 
                xs={4}
                fullWidth
                fieldLabel= {`Lugar de nacimiento ${rFields.includes("birthplace") ? "(*)" : ""}`}
                fieldID="autocomplete-field-born-place"
                placeholder="Ej: Santo Domingo"
                value={fields.birthplace || ''}
                onChange={onChange.bind(this, 'text', 'birthplace')}
                error={Boolean(errorFields[section]?.birthplace)}
                helperText={errorFields[section]?.birthplace ? errorFields[section]?.birthplace : null}
                disabled={disabled}
            /> */}
            <AutocompleteField
                xs={4}
                fieldLabel= {`Profesión ${rFields.includes("profession") ? "(*)" : ""}`}
                fieldID="autocomplete-field-profession"
                fieldInnerLabel="Seleccione una profesión"
                value={
                    // fields.nationality || null
                    handleSelectValue(fields.profession, options.professions, 'name')
                    || null
                }
                handleChange={
                    onChange.bind(this, 'autocomplete', 'profession')
                }
                options={getFiltersOptions(options.professions || [])}
                getOptionLabel={(option) => option.name }
                getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                error={Boolean(errorFields[section]?.profession)}
                helperText={errorFields[section]?.profession ? errorFields[section]?.profession : null}
                multiple={false}
                disabled={disabled}
            />
            {/* <TextFieldComponent 
                xs={4}
                fullWidth
                fieldLabel= {`Profesión ${rFields.includes("profession") ? "(*)" : ""}`}
                fieldID="autocomplete-field-profession"
                placeholder="Ej: Bombero"
                value={fields.profession || ''}
                onChange={onChange.bind(this, 'text', 'profession')}
                error={Boolean(errorFields[section]?.profession)}
                helperText={errorFields[section]?.profession ? errorFields[section]?.profession : null}
            /> */}
            {/* <AutocompleteField
                xs={4}
                fieldLabel= {`Profesión ${rFields.includes("profession") ? "(*)" : ""}`}
                fieldID="autocomplete-field-profession"
                fieldInnerLabel="Seleccione su profesión"
                value={fields.profession || null}
                handleChange={onChange.bind(this, 'autocomplete', 'profession')}
                options={getFiltersOptions(options.professions || [])}
                getOptionLabel={(option) => option.name }
                getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                error={Boolean(errorFields[section]?.profession)}
                helperText={errorFields[section]?.profession ? errorFields[section]?.profession : null}
                multiple={false}
            /> */}
            {/* <AutocompleteField
                xs={4}
                fieldLabel= {`Estado civil ${rFields.includes("civilStatus") ? "(*)" : ""}`}
                fieldID="autocomplete-field-marital-status"
                fieldInnerLabel="Seleccione una opción"
                value={
                    // fields.civilStatus || null
                    handleSelectValue(fields.civilStatus, civilStatus, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'civilStatus')}
                options={getFiltersOptions(civilStatus)}
                getOptionLabel={(option) => option.label }
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.civilStatus)}
                helperText={errorFields[section]?.civilStatus ? errorFields[section]?.civilStatus : null}
                multiple={false}
                disabled={disabled}
            /> */}
            {/* <NumericTextField
                xs={4}
                fullWidth
                fieldLabel= {`Cantidad Dependientes ${rFields.includes("dependentQuantity") ? "(*)" : ""}`}
                placeholder="Ej: 3"
                fieldID="leads-field-dependentQuantity"
                decimalScale={0}
                value={fields.dependentQuantity || ''}
                onChange={onChange.bind(this, 'numeric', 'dependentQuantity')}
                error={Boolean(errorFields[section]?.dependentQuantity)}
                helperText={errorFields[section]?.dependentQuantity ? errorFields[section]?.dependentQuantity : null}
            /> */}
            {/* <AutocompleteField
                xs={4}
                fieldLabel= {`Tipo de vivienda ${rFields.includes("houseInfo") ? "(*)" : ""}`}
                fieldID="leads-field-houseInfo"
                fieldInnerLabel="Seleccione tipo de vivienda"
                value={
                    // fields.houseInfo || null
                    handleSelectValue(fields.houseInfo, dwellingTypes, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'houseInfo')}
                options={getFiltersOptions(dwellingTypes)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.houseInfo)}
                helperText={errorFields[section]?.houseInfo ? errorFields[section]?.houseInfo : null}
                multiple={false}
                disabled={disabled}
            /> */}
            {/* <AutocompleteField
                xs={4}
                fieldLabel= {`Nivel de edudación ${rFields.includes("educationLevel") ? "(*)" : ""}`}
                fieldID="leads-field-educationLevel"
                fieldInnerLabel="Seleccione un nivel"
                value={
                    // fields.educationLevel || null
                    handleSelectValue(fields.educationLevel, educationLevel, 'value')
                    || null
                }
                handleChange={onChange.bind(this, 'autocomplete', 'educationLevel')}
                options={getFiltersOptions(educationLevel)}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                error={Boolean(errorFields[section]?.educationLevel)}
                helperText={errorFields[section]?.educationLevel ? errorFields[section]?.educationLevel : null}
                multiple={false}
            /> */}
            <Grid item container direction="row" xs={8} spacing={2} alignItems='flex-end' style={{padding:'0 16px', marginTop:'1rem', flexWrap:'nowrap'}}>
                <div style={{paddingLeft:8}}>
                    <Typography
                        variant="subtitle1"
                        className="field-label"
                    >Archivo Identificación</Typography>
                    <FileSelection
                        xs={12}
                        file={file}
                        setFile={setFile}
                        inputId={"input-file-identification"}
                        savedFile={
                            typeof fields.identificationFile === "string" ? {uniqueId: fields.identificationFile} : fields.identificationFile
                        }
                        setSavedFile={setSavedFile}
                        showSavedFile={true}
                        uploadFileEndpoint={() => 
                            handleUploadFileEndpoint({token, file, leadUniqueId})
                        }
                        fileExtensions=".pdf,.png,.jpg,.jpeg"
                        token={token}
                        setLoading={setLoading}
                        handleSimpleDialog={handleSimpleDialog}
                        disabled={disabled}
                    />
                </div>
            </Grid>
        </Grid>
    )
}