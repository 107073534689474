import * as React from "react";
import { connect } from "react-redux";

import {
  AppBar,
  Button,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
  createStyles,
  withStyles,
  MenuItem,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import { CloudUpload } from "@material-ui/icons/";
import green from "@material-ui/core/colors/green";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { GeneralTable } from "../SharedComponents/Table";

import {
  http,
  fetchFileAttachments,
  createFileAttachment,
  getFileAttachment,
} from "../../utils/axios";
import {
  debtorsValidation,
  loanContractStatus,
  notarialPayStatus,
  payrollDeductionStatus,
  catchError,
  checkStatus,
  inputCertificationStatus,
  translations,
  filesGeneration,
} from "../../utils";
import { Close, Tune, ClearAll } from "@material-ui/icons";

import $axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Loading from "../Loading";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { DateField } from "../SharedComponents/Fields";

import LoanContractView from "../../containers/LoanContract/LoanContractView";
import NotarialPayContractView from "../../containers/NotarialPayContract/NotarialPayContractView";
import AddendumContractView from "../../containers/AddendumContract/AddendumContractView";
import DebitAuthorizationContractView from "../../containers/DebitAuthorizationContract/DebitAuthorizationContractView";
import AcquaintanceshipCompanyContract from "../../containers/AcquaintanceshipCompanyContract/View";
import AcquaintanceshipPersonContract from "../../containers/AcquaintanceshipPersonContract/View";
import PayrollDeduction from "../../containers/PayrollDeduction/View";
import PledgeContractWithoutDivestment from "../../containers/PledgeContractWithoutDivestment/View";
import CreditReviewAuthorizationView from "../../containers/CreditReviewAuthorization/CreditReviewAuthorizationView";
import CancellationMembership from "../../containers/CancellationMembership/View";
import AutomaticDebitAuthorizationContractView from "../../containers/AutomaticDebitAuthorizationContract/AutomaticDebitAuthorizationContractView";
import AutomaticWithdrawalAuthorizationContract from "../../containers/AutomaticWithdrawalAuthorizationContract/View";
import DebitCardOrPassbookAccountContract from "../../containers/DebitCardOrPassbookAccountContract/View";
import SavingsAccountContractView from "../../containers/SavingsAccount/View";
import FinancialCertificates from "../../containers/FinancialCertificates/View";
import InputCertification from "../../containers/InputCertification/View";
import AffidavitOfFunds from "../../containers/AffidavitOfFunds/View";
import ClientAffidavitOfFunds from "../../containers/ClientAffidavitOfFunds/View";
import ComplianceRegistrationForm from "../../containers/ComplianceRegistrationForm/View";
import MembershipAcquaintanceshipView from "../../containers/MembershipAdmission/View";
import ElectronicMeansServiceContract from "../../containers/ElectronicMeansServiceContract/View";
import AlertDialog from "../AlertDialog";
import { checkPropTypes } from "prop-types";
import { filter, forEach, result } from "lodash";

const styles = createStyles({
  table: {
    minWidth: 650,
  },
  appBar: {
    position: "relative",
    // background: green[200]
  },
  toolBar: {
    background: green[500],
  },
  title: {
    marginLeft: 2,
    flex: 1,
  },
  checkboxLabel: {
    color: green[500],
  },
  textField: {
    marginLeft: 2,
    marginRight: 2,
  },
});

const fieldStyles = {
  root: {
    color: green[500],
    "& label.Mui-focused": {
      color: green[500],
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        color: green[500],
        borderColor: green[500],
      },
      "&.Mui-focused fieldset": {
        borderColor: green[500],
      },
    },
  },
};

const StyledTextField = withStyles(fieldStyles)(TextField);
const StyledAutocomplete = withStyles(fieldStyles)(Autocomplete);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class ClientDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // dwellingType: "", 'eADo2S3hQ3kMkHPt'
      documents: [],
      debtorId: null,
      documentID: null,
      endpoint: [],
      users: [],
      open_dialog: false,
      open_filter: false,
      view: null,
      filters: {
        search: "",
        name: "",
        title: "",
        date: "",
        status: "",
        fecha_inicio: "",
        fecha_final: "",
      },
      dialog: false,
      errorMessage: "",
      dialogTitle: "",
      documentFiles: [],
    };

    this.initialState = { ...this.state };
    this.getDocuments = this.getDocuments.bind(this);
    this.handleCloseb = this.handleCloseb.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
  }

  getDocuments() {
    if (this.props.debtorId.length < 1) {
      return false;
    }
    this.setState({ loading: true });

    const ep = [
      {
        url: "/lending/loan-contracts/",
        value: "loancontracts",
        name: translations.LOAN_CONTRACT,
        component: (documentID) => (
          <LoanContractView loanContractId={documentID} />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/notarial-pay-contracts/",
        value: "notarialpaycontracts",
        name: translations.NOTARIAL_PAY_CONTRACT,
        component: (documentID) => (
          <NotarialPayContractView notarialPayContractId={documentID} />
        ),
        status: notarialPayStatus,
      },
      {
        url: "/lending/addendum-contracts/",
        value: "addendumcontracts",
        name: translations.ADDENDUM,
        component: (documentID) => (
          <AddendumContractView addendumContractId={documentID} />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/debit-authorization-contracts/",
        value: "debitauthorizationcontract",
        name: translations.DEBIT_AUTHORIZATION,
        component: (documentID) => (
          <DebitAuthorizationContractView
            debitAuthorizationContractId={documentID}
          />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/acquaintanceship-person-contracts/",
        value: "acquaintanceshippersoncontracts",
        name: translations.APF,
        component: (documentID) => (
          <AcquaintanceshipPersonContract id={documentID} />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/acquaintanceship-company-contracts/",
        value: "acquaintanceshipcompanyform",
        name: translations.ACF,
        component: (documentID) => (
          <AcquaintanceshipCompanyContract id={documentID} />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/payroll-deduction-form/",
        value: "payrolldeductionform",
        name: translations.PAYROLL_DEDUCTION_CONTRACT,
        component: (documentID) => <PayrollDeduction id={documentID} />,
        status: payrollDeductionStatus,
      },
      {
        url: "/lending/pledge-contract-without-divestment/",
        value: "pledgecontractwithoutdivestment",
        name: "Contrato de Prenda sin Desapoderamiento",
        component: (documentID) => (
          <PledgeContractWithoutDivestment id={documentID} />
        ),
        status: checkStatus,
      },
      {
        url: "/lending/credit-review-authorization/",
        value: "creditreviewauthorization",
        name: translations.CREDIT_REVIEW_AUTHORIZATION,
        component: (documentID) => (
          <CreditReviewAuthorizationView
            creditReviewAuthorizationId={documentID}
          />
        ),
        status: checkStatus,
      },
      {
        url: "/lending/cancellation-membership-form/",
        value: "cancellationmembershipform",
        name: translations.CANCELLATION_MEMBERSHIP,
        component: (documentID) => <CancellationMembership id={documentID} />,
        status: loanContractStatus,
      },
      {
        url: "/lending/automatic-withdrawal-authorization/",
        value: "automaticwithdrawalauthorizationform",
        name: translations.AUTOMATIC_DEBIT_AUTHORIZATION,
        component: (documentID) => (
          <AutomaticWithdrawalAuthorizationContract id={documentID} />
        ),
        status: null,
      },
      {
        url: "/lending/debit-authorization-contracts-extended/",
        value: "debitauthorizationcontractextended",
        name: translations.AUTOMATIC_DEBIT_AUTHORIZATION,
        component: (documentID) => (
          <AutomaticDebitAuthorizationContractView
            automaticDebitAuthorizationContractId={documentID}
          />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/debit-card-or-passbook-account/",
        value: "debitcardorpassbookaccountform",
        name: translations.ACCOUNT_MANAGEMENT_CONTRACT,
        component: (documentID) => (
          <DebitCardOrPassbookAccountContract id={documentID} />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/savings-account-form/",
        value: "savingsaccountform",
        name: translations.SAVINGS_ACCOUNT_CONTRACTS,
        component: (documentID) => (
          <SavingsAccountContractView savingsAccountContractId={documentID} />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/financial-certificate-form/",
        value: "financialcertificateform",
        name: translations.FINANCIAL_CERTIFICATE,
        component: (documentID) => <FinancialCertificates id={documentID} />,
        status: inputCertificationStatus,
      },
      {
        url: "/lending/input-certification-form/",
        value: "inputcertificationform",
        name: "Solicitud de Certificado de Aportación",
        component: (documentID) => <InputCertification id={documentID} />,
        status: inputCertificationStatus,
      },
      {
        url: "/lending/affidavit-of-funds",
        value: "affidavitoffunds",
        name: translations.AFFIDAVIT_FUNDS,
        component: (documentID) => <AffidavitOfFunds id={documentID} />,
        status: loanContractStatus,
      },
      {
        url: "/lending/client-affidavit-of-funds/",
        value: "clientaffidavitoffunds",
        name: translations.CLINET_AFFIDAVIT_FUNDS,
        component: (documentID) => <ClientAffidavitOfFunds id={documentID} />,
        status: loanContractStatus,
      },
      {
        url: "/lending/compliance-registration-form/",
        value: "complianceregistrationform",
        name: "Registro de Cumplimiento",
        component: (documentID) => (
          <ComplianceRegistrationForm id={documentID} />
        ),
        status: null,
      },
      {
        url: "/lending/acquaintanceship-affiliate-contracts/",
        value: "acquaintanceshipaffiliateform",
        name: translations.MA,
        component: (documentID) => (
          <MembershipAcquaintanceshipView id={documentID} />
        ),
        status: loanContractStatus,
      },
      {
        url: "/lending/electronic-means-service-contract/",
        value: "electronicmeansservicecontract",
        name: "Contrato de Servicios Electrónicos",
        component: (documentID) => (
          <ElectronicMeansServiceContract id={documentID} />
        ),
        status: loanContractStatus,
      },
    ]

    http
      .get(`/tu_nomina/participants/${this.props.debtorId}/documents`, {
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
        params: {
          filter: this.props.documents,
        }
      })
      .then((response) => {
        const backendData = response.data;

        let doc = [];

        Object.keys(backendData).forEach((typeOfDocument) => {
          const typeInfo = ep.find((item) => item.value === typeOfDocument);

          if (typeInfo) {
            backendData[typeOfDocument].forEach((document) => {
              const date = document.date;
              const title = document.title;
              const status = getStatus(typeInfo.status, document.status);

              doc.push({
                title: title.toCapitalize(),
                date: date.toCapitalize(),
                status: status.toCapitalize(),
                uniqueID: document.uniqueId,
                url: typeInfo.url,
                name: typeInfo.name.toCapitalize(),
                view: typeInfo.component,
              });
            });
          }
        });

        if (this.props.setDocuments) {
          this.props.setDocuments(
            doc.filter((item) => item.status === "Firma Pendiente")
          );
        }

        this.setState({ documents: doc, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });

    const getStatus = (epStatus, documentStatus) => {
      return epStatus && documentStatus
        ? typeof epStatus === "object"
          ? epStatus.find(
              (e) => e.value.toUpperCase() === documentStatus.toUpperCase()
            )?.label || "N/A"
          : epStatus.toUpperCase() === documentStatus.toUpperCase() && epStatus
        : "N/A";
    };
  }

  async generateFile(uniqueId, url) {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        [this.props.debtorId],
        false
      )
    ) {
      const FullUrl = `${url}${uniqueId}/print/`;
      await filesGeneration(FullUrl, this.props.user.token);
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    String.prototype.toCapitalize = function(str) {
      if (!str) {
        str = this;
      }
      return str.replace(/\w\S*/g, (w) =>
        w.replace(/^\w/, (c) => c.toUpperCase())
      );
    };
    this.getDocuments();
    this.getDocumentFiles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.debtorId !== this.props.debtorId) {
      this.getDocuments();
      this.getDocumentFiles();
    }
  }

  handleCloseb(e) {
    if (this.props.documentOpen) {
      this.props.documentOpen(false);
    }
    this.setState({ open_dialog: false });
  }

  handleChangeFilter(event, key) {
    if (event === null) {
      return false;
    }
    let filt = this.state.filters;
    filt[key] = event.target.value;
    this.setState({
      filters: filt,
      [key + "Error"]: "",
    });
  }

  handleChangeAutocomplete(value, key) {
    let filt = this.state.filters;
    filt[key] = value;
    this.setState({
      filters: filt,
      [key + "Error"]: "",
    });
  }

  handleChange(value, key) {
    this.setState({
      [key]: event.target.value,
      [key + "Error"]: "",
    });
  }

  findDocumentFile = (row) => {
    let file = null;
    const name = `leads-${this.props.leadsUniqueId}-${row.uniqueID}-${row.title}`;
    if (this.state.documentFiles?.length) {
      file = this.state.documentFiles.filter((item) =>
        item.name.includes(name)
      );
      file = file.length && file[this.state.documentFiles.length - 1];
    }
    return file;
  };

  viewDocumentFiles = (row) => {
    const file = this.findDocumentFile(row);
    if (file?.uniqueId) {
      this.props.setLoading(true);
      const endpoint = getFileAttachment(this.props.user.token, file.uniqueId);
      endpoint
        .then((res) => {
          const dbuff = new Uint8Array(res.data.file).buffer;
          const data = new Blob([dbuff], { type: res.data.type });
          const fileURL = URL.createObjectURL(data);
          const NewWindow = window.open();
          NewWindow.location.href = fileURL;
          this.props.setLoading(false);
        })
        .catch((errors) => {
          console.log(errors);
          this.props.setLoading(false);
        });
    } else {
      this.props.handleToggleSimpleDialog(
        "simple-dialog",
        `No hay un archivo disponible.`
      );
    }
  };

  getDocumentFiles = () => {
    if (this.props.leadsUniqueId) {
      const name = `leads-${this.props.leadsUniqueId}`;
      const endpoint = fetchFileAttachments({
        token: this.props.user.token,
        name,
      });
      endpoint
        .then((response) => {
          this.setState({
            documentFiles: response.data || [],
          });
          this.props.setLoading(false);
        })
        .catch((err) => {
          catchError(err, this);
          this.props.setLoading(false);
        });
    }
  };

  uploadDocumentFile = (row, file) => {
    this.props.setLoading(true);
    let formData = new FormData();
    const name = `leads-${this.props.leadsUniqueId}-${row.uniqueID}-${row.title}`;
    formData.append("file", file);
    formData.append("name", name);
    const endpoint = createFileAttachment(this.props.user.token, formData);
    endpoint
      .then(() => {
        this.getDocumentFiles();
        this.props.setLoading(false);
      })
      .catch((err) => {
        catchError(err, this);
        this.props.setLoading(false);
      });
  };

  handleDialogClose() {
    this.setState({ dialog: false });
  }

  render() {
    const { classes } = this.props;
    const { open_dialog } = this.state;
    const actions = (row) => {
      return (
        <>
          <IconButton
            aria-label="print"
            onClick={() => this.generateFile(row.uniqueID, row.url)}
          >
            <PrintIcon />
          </IconButton>

          <IconButton
            aria-label="url-edit"
            onClick={() => {
              handleClickOpen(row.uniqueID, row.view);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </>
      );
    };
    const documentActions = (row) => {
      return (
        <Grid container spacing={3} direction="row" justifyContent="center">
          <input
            type="file"
            accept={"*"}
            id={"documents-input-file-selection"}
            onChange={(event) =>
              this.uploadDocumentFile(row, event.target.files[0])
            }
            style={{ display: "none" }}
            multiple={false}
          />
          <label
            htmlFor={"documents-input-file-selection"}
            style={{ zIndex: 10 }}
          >
            {/* <IconButton aria-label="upload-document">
                  <CloudUpload />
                </IconButton> */}
            <Button
              variant="text"
              component="span"
              aria-label="upload-document-btn"
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
              disableElevation
            >
              <CloudUpload />
            </Button>
          </label>

          <Button
            variant="text"
            component="span"
            aria-label="url-edit"
            onClick={() => this.viewDocumentFiles(row)}
            style={{
              color: Boolean(this.findDocumentFile(row))
                ? "#43a047"
                : "rgba(0, 0, 0, 0.54)",
            }}
            disableElevation
            disabled={!Boolean(this.findDocumentFile(row))}
          >
            <VisibilityIcon />
          </Button>
        </Grid>
      );
    };

    const headers = [
      { id: "name", label: "TIPO", minWidth: 120 },
      { id: "title", label: "TÍTULO", minWidth: 120 },
      { id: "status", label: "ESTADO", minWidth: 150 },
      { id: "date", label: "FECHA", minWidth: 150 },
      {
        id: "action",
        label: "ACCIONES",
        align: "center",
        minWidth: 150,
        element: actions,
      },
      {
        id: "documentAction",
        label: "ACCIONES DOCUMENTO",
        align: "center",
        minWidth: 180,
        element: documentActions,
      },
    ].filter((item) =>
      this.props.leadsUniqueId ? item : item.id !== "documentAction"
    );

    const handleClickOpen = (uniqueID, component) => {
      if (this.props.documentOpen) {
        this.props.documentOpen(true);
      }
      this.setState({ documentID: uniqueID });
      this.setState({ view: component, view: component(uniqueID) });
      this.setState({ open_dialog: true });
    };
    const filter1 = (val) => {
      let query = [];
      let i = 0;
      let tbl = [];
      let valid = true;
      for (const key in this.state.filters) {
        if (
          key === "search" ||
          this.state.filters[key] === undefined ||
          this.state.filters[key].length < 1
        ) {
          ++i;
          continue;
        }
        if (key === "fecha_inicio" || key === "fecha_final") {
          if (
            this.state.filters["fecha_inicio"].length > 0 &&
            this.state.filters["fecha_final"].length > 0
          ) {
            tbl.push(
              new Date(val["date"]) >
                new Date(this.state.filters["fecha_inicio"]) &&
                new Date(val["date"]) <
                  new Date(this.state.filters["fecha_final"])
            );
          } else if (this.state.filters["fecha_inicio"].length > 0) {
            tbl.push(
              new Date(val["date"]) >
                new Date(this.state.filters["fecha_inicio"])
            );
          } else {
            tbl.push(
              new Date(val["date"]) <
                new Date(this.state.filters["fecha_final"])
            );
          }
          continue;
        }
        if (
          val[key].trim().toUpperCase() ===
          this.state.filters[key].trim().toUpperCase()
        ) {
          query.push(val);
          tbl.push(true);
        } else {
          tbl.push(false);
        }
      }

      for (let i = 0; i < tbl.length; i++) {
        if (!tbl[i]) {
          valid = false;
          break;
        }
      }

      if (query.length < 1 && i === Object.keys(this.state.filters).length) {
        return true;
      }

      return valid ? true : false;
    };
    const filter2 = (e) => {
      for (const key in this.state.filters) {
        if (
          key !== "search" &&
          key !== undefined &&
          e[key] !== undefined &&
          e[key]
            .trim()
            .toUpperCase()
            .includes(this.state.filters.search.trim().toUpperCase())
        ) {
          return true;
        }
      }
    };
    const ClearFilters = () => {
      let filt = this.state.filters;
      for (const key in filt) {
        if (key === "search") {
          continue;
        }
        filt[key] = "";
      }
      this.setState({ filters: filt });
    };
    const toggleFilter = () => {
      if (this.state.open_filter) {
        ClearFilters();
      }
      this.setState({ open_filter: !this.state.open_filter });
    };

    return (
      <>
        <Dialog
          fullScreen
          onClose={this.handleCloseb}
          open={open_dialog}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                onClick={this.handleCloseb}
                edge="start"
                color="inherit"
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Cerrar
              </Typography>
            </Toolbar>
          </AppBar>
          {this.state.view}
        </Dialog>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        {this.state.loading ? (
          <Loading message={translations.LOADING} />
        ) : (
          <>
            {this.props.title || (
              <Typography variant="h3">Documentos</Typography>
            )}
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="search"
                  label={translations.SEARCH}
                  value={this.state.filters.search}
                  onChange={(event) => this.handleChangeFilter(event, "search")}
                  InputLabelProps={{
                    style: styles.checkboxLabel,
                  }}
                />
              </Grid>
              <IconButton className={"filter-btn-close"} onClick={toggleFilter}>
                {this.state.open_filter ? (
                  <Close className={"filter-btn-close"} />
                ) : (
                  <Tune className={"filter-btn-close"} />
                )}
              </IconButton>
            </Grid>
            <br />
            <div
              className={
                this.state.open_filter
                  ? "filter-content"
                  : "filter-content collided"
              }
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Fecha inicio"
                    value={this.state.filters.fecha_inicio}
                    type="date"
                    onChange={(event) =>
                      this.handleChangeFilter(event, "fecha_inicio")
                    }
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Fecha final"
                    value={this.state.filters.fecha_final}
                    type="date"
                    onChange={(event) =>
                      this.handleChangeFilter(event, "fecha_final")
                    }
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    freeSolo
                    size="small"
                    onInputChange={(event, value) =>
                      this.handleChangeAutocomplete(value, "status")
                    }
                    options={[
                      ...new Set(this.state.documents.map((val) => val.status)),
                    ].sort()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        margin="normal"
                        InputLabelProps={{
                          style: styles.checkboxLabel,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <br />
            </div>

            <GeneralTable
              data={this.state.documents.filter(filter1).filter(filter2)}
              columns={headers}
            />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(ClientDocuments));
